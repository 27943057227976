import UpgradeDialog from "../UpgradeDialog";
import UpgradeProvider from "../UpgradeProvider";
import { UseUpgradeProps } from "../useUpgrade";
import UpgradeOpenButton, { UpgradeOpenButtonProps } from "./UpgradeOpenButton";

export type UpgradeButtonProps = UseUpgradeProps & UpgradeOpenButtonProps;

const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  afterUpgrade,
  booking,
  buttonType,
  ...iconButtonProps
}) => {
  return (
    <UpgradeProvider booking={booking} afterUpgrade={afterUpgrade}>
      <UpgradeOpenButton
        buttonType={buttonType}
        {...(iconButtonProps as any)}
      />
      <UpgradeDialog />
    </UpgradeProvider>
  );
};

export default UpgradeButton;
