import Refresh from "@mui/icons-material/Refresh";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import { Alert, Box, IconButton, LinearProgress } from "@mui/material";
import { Button, PageHeader, Spacer } from "common";
import I18n from "i18n";
import moment from "moment";
import { Outlet } from "react-router-dom";
import KitchenFilter from "./KitchenFilter";
import KitchenList from "./KitchenList";
import useKitchenList from "./useKitchenList";

export default function KitchenPage() {
  const {
    kitchenData,
    loading,
    load,
    handleFilter,
    setRange,
    range,
    loaded,
    isEmpty,
    setSettings,
  } = useKitchenList();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        //position: "relative",
      }}
    >
      <PageHeader
        avatar={<RestaurantMenuIcon />}
        title={I18n.t("Kitchen.title")}
      >
        <IconButton onClick={() => load()}>
          <Refresh />
        </IconButton>
      </PageHeader>
      <KitchenFilter setRange={setRange} range={range} />
      <Spacer h={1} />
      <KitchenList kitchenData={kitchenData} />
      {loading && (
        <LinearProgress sx={{ position: "fixed", left: 0, top: 0, right: 0 }} />
      )}
      {isEmpty && (
        <Alert
          severity="info"
          action={
            <Button
              variant="text"
              title="Reset"
              size="small"
              onClick={() => {
                setRange({
                  start: moment().startOf("day"),
                  end: moment().endOf("day"),
                });
                setSettings({ programIds: undefined, boatIds: undefined });
              }}
            />
          }
        >
          A jelenelgi feltételekkel nem található adat.
        </Alert>
      )}
      <Outlet />
    </Box>
  );
}
