import { Box, List, ListItem, ListSubheader, Typography } from "@mui/material";

import { Toolbar } from "common";
import { getLabel } from "helpers/dateHelper";
import useHeaderHeight from "helpers/useHeaderHeight";
import moment from "moment";
import StreetSalesListItem from "./StreetSalesListItem";
import { StreetSalesData } from "./streetSalesApi";

interface StreetSalesListProps {
  streetSalesData: StreetSalesData;
}

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

export default function StreetSalesList({
  streetSalesData,
}: StreetSalesListProps) {
  const headerHeight = useHeaderHeight();
  return (
    <Box>
      {Object.keys(streetSalesData).map(day => {
        const times = streetSalesData[day];
        return (
          <List key={day} disablePadding>
            <ListSubheader sx={{ top: headerHeight, fontSize: "1rem" }}>
              <Toolbar sx={{ gap: 2 }}>
                {moment(day).format("L")}
                <Typography variant="body1">
                  {getLabel({ start: moment(day), end: moment(day) }).niceLabel}
                </Typography>
              </Toolbar>
            </ListSubheader>

            {Object.keys(times).map(time => {
              const items = times[time];
              return (
                <ListItem
                  divider
                  key={time}
                  sx={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 1,
                  }}
                >
                  <Toolbar sx={{ gap: 1 }}>
                    <Typography>{time}</Typography>
                    <Toolbar sx={{ gap: 1, flexWrap: "wrap" }}>
                      {items?.map((item, index) => {
                        return <StreetSalesListItem item={item} key={index} />;
                      })}
                    </Toolbar>
                  </Toolbar>
                </ListItem>
              );
            })}
          </List>
        );
      })}
    </Box>
  );
}
