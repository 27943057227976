// FILECONTENT_START src/common/components/Inputs/Price/Price.tsx
import { Euro } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { TextField, TextFieldProps } from "common";

export interface PriceProps extends TextFieldProps {
  count?: number;
  mode?: "normal" | "total";
}

export default function Price({
  size,
  sx,
  count = 1,
  mode = "normal",
  value,
  onChange,
  ...props
}: PriceProps) {
  const displayValue =
    mode === "total" && typeof value === "number" ? value * count : value;

  const handleChange = (event: {
    target: { value?: string | number | null };
  }) => {
    let newValue = event.target.value;
    if (mode === "total" && typeof newValue === "number") {
      newValue = newValue / count;
    }
    if (onChange) {
      onChange({ target: { value: newValue } });
    }
  };

  return (
    <TextField
      mask={Number}
      unmask
      size="small"
      value={displayValue}
      onChange={handleChange}
      sx={{
        ...(size === "small"
          ? {
              minHeight: "27px",
              "& input": {
                padding: "3px",
                paddingLeft: "8px",
                paddingRight: "8px",
              },
            }
          : {}),
        ...sx,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Euro
              fontSize="small"
              color={props.disabled ? "disabled" : undefined}
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
// FILECONTENT_END src/common/components/Inputs/Price/Price.tsx
