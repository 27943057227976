import LayersClearIcon from "@mui/icons-material/LayersClear";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import api from "app/api";
import { globalMessageHandler } from "common";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ScheduleContext } from "../ScheduleTabs";

export default function DeleteEventsButton() {
  const context = useContext(ScheduleContext);
  const params = useParams<"schedule_id">();
  const [loading, setLoading] = useState(false);
  async function deleteEvents() {
    globalMessageHandler.confirm(
      {
        message: "Biztosan tölröd a legenerált az eseményeket?",
      },
      async () => {
        setLoading(true);
        const { success, data, error } = await api.post(
          `/schedules/${params?.schedule_id}/delete-events`
        );
        setLoading(false);
        if (success) {
          context?.load();
          globalMessageHandler.snack({
            severity: "success",
            message: `Sikeresen törölve. ${data.count} esemény.`,
          });
        } else {
          globalMessageHandler.snack({
            severity: "error",
            message: error?.message,
          });
        }
      }
    );
  }
  return (
    <Tooltip title={"Események törlése"}>
      <Box>
        <IconButton
          color="error"
          onClick={deleteEvents}
          disabled={
            !!(context?.isCreate || context?.loading || context?.isDirty) ||
            !context?.item?.has_event
          }
        >
          {loading ? <CircularProgress size={16} /> : <LayersClearIcon />}
        </IconButton>
      </Box>
    </Tooltip>
  );
}
