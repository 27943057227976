import { BookingOptionItem } from "features/booking-options/bookingOptionApi";
import { BookingItem } from "features/bookings/bookingApi";

export function getPrice(item: BookingItem) {
  const adult = (item?.number_of_adults || 0) * (item?.adult_price || 0);
  const kid = (item?.number_of_kids || 0) * (item?.kid_price || 0);
  const upgrade = item?.upgrade_price || 0;

  const options: BookingOptionItem[] = (item as any).booking_options || [];

  let optionsPrice = 0;
  options?.forEach(option => {
    optionsPrice += (option.qty || 0) * (option.price || 0);
  });
  const sum = adult + kid + optionsPrice + upgrade;

  return {
    adult,
    kid,
    options: optionsPrice,
    upgrade,
    sum,
  };
}

export function formatPrice(
  price?: number,
  currency: string | null = "EUR",
  short_prices: boolean = false, // Only reduce values and add suffixes when true
  precision: number = 0
) {
  if (!price) return ""; // Handle cases where price is undefined or null

  let formattedPrice = price;
  let suffix = "";

  // If short_prices is true, reduce price and add suffix (e.g., 'e' for thousands, 'm' for millions)
  if (short_prices) {
    if (Math.abs(price) >= 1000000) {
      formattedPrice = price / 1000000; // Convert to millions
      suffix = "m"; // Use 'm' for millions
      precision = 2;
    } else if (Math.abs(price) >= 1000) {
      formattedPrice = price / 1000; // Convert to thousands
      precision = currency === "HUF" ? 0 : 1;
      suffix = "e"; // Use 'e' for thousands
    }
  }

  // Format the price using toLocaleString
  const formattedNumber = formattedPrice.toLocaleString("hu-HU", {
    style: "decimal",
    //currency: currency || "HUF", // Default to HUF if currency is null
    minimumFractionDigits: 0,
    maximumFractionDigits: precision,
  });

  // Extract the currency symbol based on the locale
  let currencySymbol = currency;
  if (currency === "HUF") {
    currencySymbol = "Ft";
  }

  // Insert the suffix before the currency symbol when short_prices is true
  return short_prices
    ? `${formattedNumber}${suffix} ${currencySymbol}`
    : `${formattedNumber} ${currencySymbol}`; // When short_prices is false, return the full price without reduction
}
export function formatNumber(price?: number, precision: number = 0) {
  return (
    price?.toLocaleString("hu-HU", {
      style: "decimal",
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }) || ""
  );
}
