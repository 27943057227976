import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import React, { useCallback } from "react";

export interface CheckboxGroupProps {
  error?: string;
  label?: string;
  helperText?: string;
  isDirty?: boolean;
  required?: boolean;
  editable?: boolean;
  disabled?: boolean;
  onChange?: Function;
  value?: (number | string)[];
  options?: any[];
  color?: any;
}

export default function CheckboxGroup({
  error,
  options,
  label: _label,
  helperText,
  onChange,
  isDirty,
  editable,
  value = [],
  required,
  disabled,
  color,
  ...rest
}: CheckboxGroupProps) {
  const label =
    required && _label ? (
      <span>
        {_label} <span style={{ color: "red" }}>*</span>
      </span>
    ) : (
      _label
    );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const optionValue = event.target.value;
      const newValue = checked
        ? [...value, optionValue]
        : value.filter(v => v !== optionValue);
      onChange && onChange({ target: { value: newValue } });
    },
    [onChange, value]
  );

  return (
    <FormControl disabled={disabled}>
      {!!label && <FormLabel>{label}</FormLabel>}
      <div {...rest}>
        {options?.map((option: any) => {
          const isChecked = value.includes(option.value);
          return (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
                <Checkbox
                  color={color}
                  checked={isChecked}
                  onChange={handleChange}
                  disabled={option?.disabled}
                />
              }
              label={option.label}
            />
          );
        })}
      </div>
      {(!!error || !!helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
