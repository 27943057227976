//import { useGrid } from "common";
import { globalMessageHandler } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { useFilter } from "components/FilterProvider";
import { useMemo, useState } from "react";
import {
  BookingItem,
  cancelBookingsByIds,
  checkIn,
  checkOut,
  getBookingService,
} from "./bookingApi";
import { BOOKING_STATUSES } from "./bookingConstants";

export type BookingFilter = {
  programIds?: number[];
  boatIds?: number[];
  status?: BOOKING_STATUSES[];
  types?: Array<"scheduled" | "planned">;
  range?: { start?: string; end?: string };
};
export default function useBookingList(id: string) {
  const params = useParams<
    | "event_id"
    | "boat_id"
    | "program_id"
    | "schedule_id"
    | "program_variant_id"
    | "partner_id"
    | "partner_group_id"
  >();
  const [filter, onChange] = useFilter<BookingFilter>();
  const [showMore, setShowMore] = useState<boolean>();
  const listId = `bookings_${id}`;

  const scope = {
    events: params.event_id,
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
    "program-variants": params.program_variant_id,
    partners: params.partner_id,
    "partner-groups": params.partner_group_id,
  };

  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0 ? "bookings/" : ""
  }`;
  const service = getBookingService({
    events: params.event_id,
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
    "program-variants": params.program_variant_id,
    partners: params.partner_id,
    "partner-groups": params.partner_group_id,
  });

  async function checkInAsync(booking: BookingItem) {
    //setCheckInLoading(true);
    const { data, success } = await checkIn(booking.id);
    //setCheckInLoading(false);
    if (success) {
      globalMessageHandler.snack({
        message: "Sikeres vendég érkeztetés.",
      });
    }
  }
  async function checkOutAsync(booking: BookingItem) {
    //setCheckOutLoading(true);
    const { data, success } = await checkOut(booking.id);
    //setCheckOutLoading(false);
    if (success) {
      globalMessageHandler.snack({
        message: "Sikeresen törölted a vendég érkeztetést.",
      });
    }
  }
  const gridFilter = useMemo(() => {
    let f: any = [
      {
        columnField: "booking.status",
        operatorValue: "in",
        value: filter?.status,
      },
    ];
    if (filter?.types) {
      if (filter?.types.length === 1) {
        if (filter?.types[0] === "planned") {
          f.push({
            columnField: "booking.event_id",
            operatorValue: "empty",
          });
        } else {
          f.push({
            columnField: "booking.event_id",
            operatorValue: "notEmpty",
          });
        }
      }
    }

    if (filter?.range?.start && filter?.range?.end) {
      f.push({
        columnField: "event.start_time",
        operatorValue: "between",
        value: [filter?.range?.start, `${filter?.range?.end} 23:59:59`],
      });
    }

    if (filter?.programIds && filter?.programIds.length > 0) {
      f.push({
        columnField: "event.program_id",
        operatorValue: "in",
        value: filter?.programIds,
      });
    }
    if (filter?.boatIds && filter?.boatIds.length > 0) {
      f.push({
        columnField: "event.boat_id",
        operatorValue: "in",
        value: filter?.boatIds,
      });
    }
    return f;
  }, [filter]);

  async function cancelBookingsByIdsAsync(bookingIds: any[], load: () => void) {
    let event_id: any = 1;
    // setGenerating(true);
    globalMessageHandler.confirm(
      {
        message: `Biztosan visszavosz ${bookingIds?.length || 0} rendelést?`,
      },
      async () => {
        const { success, data } = await cancelBookingsByIds(bookingIds);

        //setGenerating(false);
        if (success) {
          globalMessageHandler.snack({
            severity: "success",
            message: `Sikeresen visszavonva ${data.count} foglalás`,
          });
          load();
        }
      }
    );
  }

  return {
    ...service,
    params,
    scope,
    editUrl,
    listId,
    checkInAsync,
    checkOutAsync,
    filter,
    gridFilter,
    onChange,
    showMore,
    setShowMore,
    cancelBookingsByIdsAsync,
  };
}
