import api from "app/api";
import { Options } from "common";
import { Table } from "components/TableEditor/TableEditor";

export interface SeatingLayoutItem {
  id: number;
  name: string;
  layout: Table[];
}

export type SeatingLayoutOptions = Options;

export function getSeatingLayoutService(scope?: any) {
  return api.getService<
    SeatingLayoutItem,
    SeatingLayoutItem,
    SeatingLayoutOptions
  >("seating-layouts", {
    scope,
    //params goes here
  });
}
