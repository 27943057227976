import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  Alert,
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  LinearProgress,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import { Button, globalMessageHandler, Spacer, Toolbar } from "common";
import CalendarPro, { VIEWS } from "common/components/CalendarPro";
import useDictionaries from "features/dictionaries/useDictionaries";
import CalendarToolbar from "features/events/Calendar/CalendarToolbar";
import CalendarCellHeaderSmall from "features/events/Calendar/cells/CalendarCellHeaderSmall";
import StartBoatCell from "features/events/Calendar/cells/StartBoatCell";
import useEventCalendar from "features/events/Calendar/useEventCalendar";
import {
  CalendarEventItem,
  checkBookings,
  moveBookings,
  MoveBookingsState,
} from "features/events/eventApi";
import { useEventCalculator } from "features/events/useEventCalculator";
import { ProgramItem } from "features/programs/programApi";
import { size } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BookingListItem from "./BookingListItem";

export interface MoveBookingListModalProps extends DialogProps {
  eventId?: number;
  bookingIds?: number[];
  programId?: number;
  afterMove?: () => void;
}

function MoveBookingListModal({
  eventId: _eventId,
  bookingIds,
  programId,
  afterMove,
  ...props
}: MoveBookingListModalProps) {
  const [state, setState] = useState<MoveBookingsState>({
    movable: [],
    overlimit: [],
  });
  const loaded = useRef(false);
  const { resolveOption } = useDictionaries();
  const program = resolveOption<ProgramItem>(
    "programs",
    state.event?.program_id
  );
  const [selectedEvent, setSelectedEvent] = useState<CalendarEventItem>();
  const boat = resolveOption<ProgramItem>("boats", state.event?.boat_id);
  const startTime = state.event?.start_time
    ? moment(state.event?.start_time).format("L LT")
    : "";
  const [eventId, setEventId] = useState<number | undefined>(_eventId);
  const [checkBookingLoading, setCheckBookingLoading] = useState(false);
  const [moveBookingLoading, setMoveBookingLoading] = useState<
    "force" | "normal"
  >();
  const programIds = useMemo(() => {
    return [programId];
  }, [programId]);

  const { getEventData, getBoatData } = useEventCalculator();
  const eventData = state?.event ? getEventData(state?.event) : undefined;

  const { items, loadEvents, setItems, cal } = useEventCalendar({
    programIds: programIds,
    filterKey: "movebooking",
    filterStorageType: "none",
  });
  function moveBookingsAsync(force?: boolean) {
    globalMessageHandler.confirm(
      {
        title: `Átregisztrálás megerősítése`,
        message: programLimit?.closed
          ? `Biztosan átregisztrálod a foglalásokat a jelenlegi a(z) ${program.name} eseményre amely ${startTime}-kor indul, annak ellenére, hogy le van zárva?`
          : `Biztosan átregisztrálod a foglalásokat a jelenlegi a(z) ${program.name} eseményre, amely ${startTime}-kor indul?`,
        severity: programLimit?.closed ? "warning" : undefined,
      },
      async () => {
        setMoveBookingLoading(force ? "force" : "normal");
        const { data, success, error } = await moveBookings(
          eventId,
          bookingIds,
          force
        );

        setMoveBookingLoading(undefined);
        if (success) {
          //afterChange && afterChange(data as any);
          setState(data);

          globalMessageHandler.snack({
            message: "Sikeres átregisztricó.",
          });
          afterMove && afterMove();
        } else {
          setState(error);
          /*globalMessageHandler.alert({
            message: `Az alábbi foglalások nem lettek rögzítve: ${error.overlimit
              .map((b: any) => b.name)
              .join(", ")}`,
          });*/
        }
      }
    );
  }
  async function checkBookingsAsync() {
    setCheckBookingLoading(true);
    const { data, success, error } = await checkBookings(eventId, bookingIds);
    loaded.current = true;
    setCheckBookingLoading(false);
    if (success) {
      //afterChange && afterChange(data as any);
      setState(data);
    }
  }

  //const selectedEvariantClosed = isVariantClosed(, selectedEvent)
  const programLimit: any = eventData?.programlimits.find(
    p => p.program_id === programId && p.type === "program"
  );

  const moveIsDisabled = size(state.movable) === 0 && !programLimit?.closed;

  const date = useMemo(() => {
    return moment();
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.open && eventId) {
      checkBookingsAsync();
    }
  }, [eventId, bookingIds, props.open]);
  useEffect(() => {
    if (_eventId !== eventId) {
      setEventId(_eventId);
    }
  }, [_eventId]);

  useEffect(() => {
    if (programIds?.length > 0 && props.open && !_eventId) {
      loadEvents({
        start: moment().startOf("week"),
        end: moment().endOf("week"),
      });
    }
  }, [programIds, props.open]);

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Typography variant="h6">
            Foglalások áthelyezése: {program?.name || "válassz esmenéyt"}
          </Typography>
          {/*renderPrograms()*/}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ flex: 1 }} />
            <IconButton
              color="inherit"
              onClick={() => checkBookingsAsync()}
              aria-label="refresh"
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() =>
                props.onClose && props.onClose({}, "backdropClick")
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {!!state.event && (
          <Toolbar sx={{ gap: 2 }}>
            <Avatar
              sx={{
                backgroundColor: programLimit?.closed
                  ? theme => theme.palette.grey[500]
                  : program?.color,
              }}
            >
              <Typography
                sx={{
                  color: program?.color
                    ? theme => theme.palette.getContrastText(program?.color)
                    : undefined,
                }}
              >
                {program?.code}
              </Typography>
            </Avatar>
            <Box>
              <Typography variant="h6">{startTime}</Typography>
              <Typography>{boat?.name}</Typography>
            </Box>
          </Toolbar>
        )}
        {checkBookingLoading && (
          <LinearProgress
            sx={{ position: "absolute", left: 0, bottom: 0, right: 0 }}
          />
        )}
      </DialogTitle>
      {!!programLimit?.closed && (
        <Alert square severity="warning">
          A program le van zárva
        </Alert>
      )}
      {!!state.event && (
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          {size(state.movable) === 0 && loaded.current && (
            <Alert severity="error" sx={{ flex: 1 }}>
              Egyetlen foglalás sem helyezhető át
            </Alert>
          )}
          <List disablePadding dense>
            {size(state.movable) > 0 && (
              <>
                <ListSubheader>
                  Áthelyezhető foglalások ({size(state.movable)})
                </ListSubheader>
                {state.movable?.map(booking => (
                  <BookingListItem key={booking.id} booking={booking} />
                ))}
              </>
            )}
            {size(state.overlimit) > 0 && (
              <>
                <ListSubheader>
                  Limiten túli foglalások ({size(state.overlimit)})
                </ListSubheader>
                {state.overlimit?.map(booking => (
                  <BookingListItem
                    key={booking.id}
                    booking={booking}
                    overlimit
                  />
                ))}
              </>
            )}
          </List>
        </DialogContent>
      )}
      {!state.event && (
        <DialogContent>
          <CalendarPro
            //calRef={cal}
            //key={eventId}
            navigateDelay={300}
            loadEvents={loadEvents}
            ToolbarComponent={CalendarToolbar}
            autoLoad={false}
            /*toolbarStyle={{
          position: "sticky",
          zIndex: 1000,
          top: 16,
          marginTop: "-8px",
        }}*/

            view={VIEWS.WEEK}
            events={items}
            //date={date}
            containerStyle={{
              flexDirection: "column",
            }}
            CellComponent={StartBoatCell}
            CellHeaderComponent={CalendarCellHeaderSmall}
            isSelectedFunc={cell => cell.id === selectedEvent?.id}
            onEventPress={cell => {
              //setSelectedEvent(s);
              setEventId(cell.id);
            }}
          />
        </DialogContent>
      )}
      <DialogActions>
        {!!state.event && !_eventId && (
          <Button
            color="inherit"
            title="Vissza"
            variant="outlined"
            onClick={() => {
              setEventId(undefined);
              setState({ event: undefined, movable: [], overlimit: [] });
            }}
          />
        )}
        {!!state.event && (
          <>
            <Button
              color="error"
              title="Foglalások áthelyezése a limitek figyelmenkívül hagyásával"
              variant="outlined"
              onClick={() => moveBookingsAsync(true)}
              loading={moveBookingLoading === "force"}
            />
            <Spacer right />
            <Button
              title="Foglalások áthelyezése"
              onClick={() => moveBookingsAsync()}
              loading={moveBookingLoading === "normal"}
              disabled={moveIsDisabled}
            />
          </>
        )}
        {!state.event && (
          <Button
            title="Mégse"
            onClick={() => props.onClose && props.onClose({}, "backdropClick")}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default MoveBookingListModal;
