import { Box, useTheme } from "@mui/material";
import { Toolbar } from "common";
import BoatCheckboxSelector from "components/BoatCheckboxSelector/BoatCheckboxSelector";
import DateRangeSelector2 from "components/DateRangeSeletor/DateRangeSelector2";
import ProgramCheckboxSelector from "components/ProgramCheckboxSelector/ProgramCheckboxSelector";
import useSettings from "features/settings/useSettings";
import { Moment } from "moment";

interface CateringFilterProps {
  setRange: (range: { start?: Moment; end?: Moment } | undefined) => void;
  range?: { start?: Moment; end?: Moment };
}

export default function CateringFilter({
  range,
  setRange,
}: CateringFilterProps) {
  const [settings, setSettings] = useSettings({ key: "catering" });

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Toolbar sx={{ flex: 1, alignSelf: "stretch", justifyContent: "center" }}>
        <DateRangeSelector2
          numberOfCalendars={1}
          numberOfYears={0}
          value={range}
          onChange={(start, end) => {
            setRange({ start, end });
          }}
          dateButtonMinWidth={240}
        />
      </Toolbar>
      <BoatCheckboxSelector
        color={theme.palette.primary.main}
        value={settings.boatIds || []}
        onChange={evt =>
          setSettings({
            boatIds: (evt.target?.value ? evt.target?.value : []) as any,
          })
        }
      />
      <ProgramCheckboxSelector
        value={settings.programIds || []}
        onChange={evt =>
          setSettings({
            programIds: (evt.target?.value ? evt.target?.value : []) as any,
          })
        }
        showMore={settings.show_all_programs}
        setShowMore={show_all => setSettings({ show_all_programs: show_all })}
      />
    </Box>
  );
}
