import Chair from "@mui/icons-material/Chair";
import CommentIcon from "@mui/icons-material/Comment";
import DiamondIcon from "@mui/icons-material/Diamond";
import DoubleArrow from "@mui/icons-material/DoubleArrow";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import {
  alpha,
  Box,
  CircularProgress,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Permissions } from "app/constants";
import { Button, globalMessageHandler, Toolbar } from "common";
import TableSelectorModal from "components/TableSelector/TableSelectorModal";
import {
  checkIn,
  checkOut,
  setTableNumbers,
} from "features/bookings/bookingApi";
import MoveBookingListModal from "features/bookings/MoveBookings/MoveBookingListModal";
import useDictionaries from "features/dictionaries/useDictionaries";
import { moveBookings } from "features/events/eventApi";
import useAccess from "features/profile/useAccess";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { ProgramItem } from "features/programs/programApi";
import { SeatingLayoutItem } from "features/seating-layouts/seatingLayoutApi";
import UpgradeButton from "features/upgrades/UpgradeButton/UpgradeButton";
import { size } from "lodash";
import moment from "moment";
import { useState } from "react";
import { Booking, CateringItem } from "./cateringApi";
import OrderItem from "./OrderItem";

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

function BookingListItem({
  booking,
  other,
  reservedTables,
  afterChange,
  cateringItem,
}: {
  booking: Booking;
  other?: boolean;
  reservedTables: string[];
  cateringItem?: CateringItem;
  afterChange?: (booking: Booking) => void;
}) {
  const { hasPermissions } = useAccess();
  const { resolveOption, resolveOptions } = useDictionaries();
  const program = resolveOption<ProgramItem>(
    "programs",
    booking?.program_variant?.program_id || booking?.program_id
  );
  const programVariant = resolveOption<ProgramVariantItem>(
    "variants",
    booking?.program_variant?.id || booking?.program_variant_id
  );

  const seating_layouts = resolveOptions<SeatingLayoutItem>(
    "layouts",
    booking.seating_layout_ids
  );

  const qty: number =
    (booking.number_of_adults || 0) + (booking.number_of_kids || 0);
  const qty_babies: number = booking.number_of_babies || 0;

  const eventId = cateringItem?.event_ids[booking.program_id];

  const [open, setOpen] = useState(false);
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [checkOutLoading, setCheckOutLoading] = useState(false);
  const [moveBookingLoading, setMoveBookingLoading] = useState(false);
  const [openMove, setOpenMove] = useState(false);

  async function setTablesAsync(tables: string[] | null) {
    const { data, success } = await setTableNumbers(booking.id, tables);
    if (success) {
      afterChange && afterChange(data as any);
      globalMessageHandler.snack({
        message: "Sikeres vendég érkeztetés.",
      });
    }
  }
  function moveBookingAsync() {
    globalMessageHandler.confirm(
      {
        title: `${booking.name} átregisztrálás?`,
        message: `Biztosan átregisztrálod a foglalást a jelenlegi járatra?`,
      },
      async () => {
        setMoveBookingLoading(true);
        const { data, success, error } = await moveBookings(eventId, [
          booking.id,
        ]);

        setMoveBookingLoading(false);
        if (success) {
          afterChange && afterChange(data as any);
          globalMessageHandler.snack({
            message: "Sikeres átregisztricó.",
          });
        } else {
          setTimeout(() => {
            globalMessageHandler.alert({
              severity: "error",
              message: `Nem sikerült átregisztrálni a foglalást: ${error.overlimit
                .map((b: any) => `${b.name} (${b.smallestLimit?.name})`)
                .join(", ")}`,
            });
          }, 300);
        }
      }
    );
  }
  async function checkInAsync() {
    setCheckInLoading(true);
    const { data, success } = await checkIn(booking.id);
    setCheckInLoading(false);
    if (success) {
      afterChange && afterChange(data as any);
      globalMessageHandler.snack({
        message: "Sikeres vendég érkeztetés.",
      });
    }
  }
  async function checkOutAsync() {
    setCheckOutLoading(true);
    const { data, success } = await checkOut(booking.id);
    setCheckOutLoading(false);
    if (success) {
      afterChange && afterChange(data as any);
      globalMessageHandler.snack({
        message: "Sikeresen törölted a vendég érkeztetést.",
      });
    }
  }

  return (
    <>
      <ListItem
        divider
        sx={{
          //position: "relative",
          backgroundColor: !!other
            ? theme => alpha(theme.palette.warning.main, 0.1)
            : undefined,
          "&.MuiListItem-secondaryAction": {
            pr: other ? 19 : programVariant?.upgradable ? 15 : 12,
          },
        }}
      >
        {!!other && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              px: 0.5,
              backgroundColor: theme => theme.palette.warning.main,
              borderBottomLeftRadius: "4px",
              fontSize: "0.65rem",
              fontWeight: 500,
              color: theme =>
                theme.palette.getContrastText(theme.palette.warning.main),
            }}
          >
            Más időpont
          </Box>
        )}
        <ListItemText
          disableTypography
          primary={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
              gap={1}
            >
              <Box display="flex" flexDirection="column">
                <Toolbar sx={{ gap: 1 }}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                    {booking.name}
                  </Typography>
                  {!!booking.check_in_at && (
                    <Box
                      sx={{
                        backgroundColor:
                          booking.seating_layout_ids &&
                          size(booking.table_numbers) === 0
                            ? "warning.dark"
                            : "success.dark",
                        fontSize: "0.7rem",
                        height: "18px",
                        pl: "3px",
                        pr: "3px",
                        borderRadius: "3px",
                        color: theme =>
                          theme.palette.getContrastText(
                            booking.seating_layout_ids &&
                              size(booking.table_numbers) === 0
                              ? theme.palette.warning.dark
                              : theme.palette.success.dark
                          ),
                      }}
                    >
                      megérkezett
                    </Box>
                  )}
                  {!booking.paid && (
                    <Box
                      sx={{
                        backgroundColor: "error.dark",
                        fontSize: "0.7rem",
                        pl: "3px",
                        pr: "3px",
                        borderRadius: "3px",
                        height: "18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: theme =>
                          theme.palette.getContrastText(
                            theme.palette.error.dark
                          ),
                      }}
                    >
                      fizetetlen
                    </Box>
                  )}
                </Toolbar>
                <Typography variant="body2">{booking.email}</Typography>
                {booking.description && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                      color: theme => theme.palette.info.main,
                    }}
                  >
                    <CommentIcon fontSize="small" />
                    <Typography variant="body2">
                      {booking.description}
                    </Typography>
                  </Box>
                )}
                <Toolbar sx={{ gap: 1 }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: "5px",
                      backgroundColor: program?.color,
                    }}
                  />
                  <Typography>{booking.program_variant_name}</Typography>
                  <Typography>
                    <b>{qty}</b> fő
                    {qty_babies > 0 ? ` + ${qty_babies} baba` : ""}
                  </Typography>

                  {booking.seating_layout_ids && booking.table_numbers && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <EventSeatIcon fontSize="small" />
                      <Typography variant="body1" fontWeight={500}>
                        {booking.table_numbers?.join(", ")}
                      </Typography>
                    </Box>
                  )}
                </Toolbar>
                <Toolbar>
                  <Typography variant="body2" color="text.secondary">
                    {booking.booking_number}
                  </Typography>
                </Toolbar>
              </Box>
              <Box display="flex" flexDirection="row" gap={1} flexWrap="wrap">
                {booking.variant_name && (
                  <OrderItem
                    name={booking.variant_name}
                    qty={qty}
                    type="variant"
                  />
                )}
                {booking.booking_options?.map((option, idx) => (
                  <OrderItem
                    key={idx}
                    name={option.name}
                    qty={option.qty}
                    type="option"
                  />
                ))}
              </Box>
            </Box>
          }
        />
        {!!other && (
          <ListItemSecondaryAction>
            <Box>
              <Typography sx={{ fontWeight: 500 }}>
                {moment(booking.start_time).format("L LT")}
              </Typography>
              {!!eventId && hasPermissions(Permissions.BookingMove) && (
                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  //loading={moveBookingLoading}
                  title="Átregisztrál"
                  onClick={() => moveBookingAsync()}
                  //onClick={() => setOpenMove(true)}
                  disabled={moveBookingLoading}
                  endIcon={
                    moveBookingLoading ? (
                      <CircularProgress size={18} color="inherit" />
                    ) : (
                      <DoubleArrow />
                    )
                  }
                />
              )}
            </Box>
          </ListItemSecondaryAction>
        )}
        {!other && (
          <ListItemSecondaryAction>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {hasPermissions(Permissions.BookingUpgrade) &&
                !!programVariant?.upgradable && (
                  <UpgradeButton
                    startIcon={<DiamondIcon />}
                    booking={booking as any}
                    buttonType="button"
                    key="upgrade"
                    title="Upgr."
                    size="small"
                    afterUpgrade={item => {
                      afterChange && afterChange(item as any);
                    }}
                  />
                )}
              {booking.seating_layout_ids &&
              hasPermissions(Permissions.BookingSeating) ? (
                <Button
                  title="Reg."
                  color={
                    size(booking.table_numbers) > 0 && !!booking.check_in_at
                      ? "success"
                      : "secondary"
                  }
                  size="small"
                  startIcon={<Chair />}
                  onClick={evt => {
                    //evt.preventDefault();
                    evt.stopPropagation();
                    console.log("Asztalok kiválasztása", booking);
                    setOpen(true);
                  }}
                ></Button>
              ) : hasPermissions(Permissions.BookingRegister) ? (
                <Button
                  loading={checkInLoading}
                  title={booking.check_in_at ? "Kireg." : "Reg."}
                  size="small"
                  color={booking.check_in_at ? "success" : "secondary"}
                  onClick={evt => {
                    //evt.preventDefault();
                    evt.stopPropagation();
                    globalMessageHandler.confirm(
                      {
                        message: booking.check_in_at
                          ? `Biztosan törlöd az alábbi foglalás vendég érkeztetésját? ${booking.name}`
                          : `Biztosan regisztrálod az alábbi foglalást? ${booking.name}`,
                      },
                      () => {
                        if (booking.check_in_at) {
                          checkOutAsync();
                        } else {
                          checkInAsync();
                        }
                      }
                    );
                  }}
                />
              ) : null}
            </Box>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <TableSelectorModal
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xl"
        guestCount={booking.number_of_guests}
        layouts={seating_layouts || ([] as any)}
        value={booking.table_numbers}
        reserved={reservedTables}
        onChange={evt => {
          setTablesAsync(evt);
        }}
        onRemove={close => {
          globalMessageHandler.confirm(
            {
              message: "Biztosan törlöd a vendég érkeztetést?",
            },
            async () => {
              checkOutAsync();
              close();
            }
          );
        }}
      />
      <MoveBookingListModal
        eventId={eventId}
        bookingIds={[booking.id]}
        open={openMove}
        onClose={() => setOpenMove(false)}
      />
    </>
  );
}

export default BookingListItem;
