import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button, DialogTitle } from "common";
import useHeaderHeight from "helpers/useHeaderHeight";
import I18n from "i18n";
import UpgradeCard from "./UpgradeCard";
import { useUpgradeContext } from "./UpgradeProvider";
import UpgradesFilter from "./UpgradesFilter";
import UpgradesList from "./UpgradesList";

const UpgradeDialog: React.FC = () => {
  const {
    item,
    save,
    isDirty,
    open,
    loading,
    handleClose,
    selectedProgramVariant,
    booking,
  } = useUpgradeContext();
  const headerHeight = useHeaderHeight();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle onClose={handleClose}>
        {I18n.t("Booking.upgradeTitle")}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <UpgradeCard
            booking={booking}
            event={booking?.event}
            programVariantId={booking?.program_variant_id}
          />
          <UpgradeCard
            booking={booking}
            event={item?.event}
            programVariantId={item?.program_variant_id}
          />
        </Box>
        <Box sx={{ mt: 2, gap: 2, display: "flex", flexDirection: "column" }}>
          <UpgradesFilter />
          <UpgradesList />
          {loading && (
            <LinearProgress
              sx={{ position: "fixed", left: 0, top: headerHeight, right: 0 }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => handleClose()}>
          {I18n.t("App.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeDialog;
