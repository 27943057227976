import usePersistentState, { StorageType } from "helpers/usePersistentState";
import { createContext, ReactNode, useCallback, useContext } from "react";
type FilterContextType<T = any> = [filter: T, onChange: (filter: T) => void];
const FilterContext = createContext<FilterContextType>({} as FilterContextType);
export interface FilterProviderProps {
  children?: ReactNode;
  id: string;
  storageType: StorageType;
}
export default function FilterProvider({
  children,
  id,
  storageType = "none",
}: FilterProviderProps) {
  const [filter, setFilter] = usePersistentState<any>({}, id, storageType);
  const onChange = useCallback(
    (newFilter: any) => {
      if (filter === null || filter === undefined) {
        setFilter({});
      } else {
        setFilter((prevFilter: any) => ({ ...prevFilter, ...newFilter }));
      }
    },
    [filter]
  );
  return (
    <FilterContext.Provider value={[filter, onChange]}>
      {children}
    </FilterContext.Provider>
  );
}

export function useFilter<T = any>(): FilterContextType<T> {
  return useContext(FilterContext);
}
