import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Fade,
  GlobalStyles,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import I18n from "i18n";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import Spacer from "./../../Spacer";
export type PickerState = {
  from?: string | null;
  to?: string | null;
};

export type DateRanges =
  | "today"
  | "yesterday"
  | "thisWeek"
  | "thisMonth"
  | "thisYear"
  | "lastWeek"
  | "lastMonth"
  | "lastYear"
  | "last7Days"
  | "last30Days"
  | "last90Days"
  | "last12Mths";

function getRange(range: DateRanges): DateRange {
  const today = moment().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const startOfWeek = moment().startOf("isoWeek");
  const startOfMonth = moment().startOf("month");
  const startOfYear = moment().startOf("year");
  const endOfWeek = moment().endOf("isoWeek").set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const endOfMonth = moment().endOf("month").set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const endOfYear = moment().endOf("year").set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  //startOfWeek.add(7, "days")
  switch (range) {
    case "today":
      return { from: today.toDate(), to: today.toDate() };
    case "yesterday":
      return {
        from: today.clone().subtract(1, "days").toDate(),
        to: today.clone().subtract(1, "days").toDate(),
      };
    case "thisWeek":
      return { from: startOfWeek.toDate(), to: endOfWeek.toDate() };
    case "thisMonth":
      return { from: startOfMonth.toDate(), to: endOfMonth.toDate() };
    case "thisYear":
      return { from: startOfYear.toDate(), to: endOfYear.toDate() };
    case "lastWeek":
      return {
        from: startOfWeek.clone().subtract(7, "days").toDate(),
        to: endOfWeek.clone().subtract(7, "days").toDate(),
      };
    case "lastMonth":
      return {
        from: startOfMonth.clone().subtract(1, "months").toDate(),
        to: startOfMonth.clone().subtract(1, "days").toDate(),
      };
    case "lastYear":
      return {
        from: startOfYear.clone().subtract(1, "years").toDate(),
        to: startOfYear.clone().subtract(1, "days").toDate(),
      };
    case "last7Days":
      return {
        from: today.clone().subtract(6, "days").toDate(),
        to: today.toDate(),
      };
    case "last30Days":
      return {
        from: today.clone().subtract(29, "days").toDate(),
        to: today.toDate(),
      };
    case "last90Days":
      return {
        from: today.clone().subtract(89, "days").toDate(),
        to: today.toDate(),
      };
    case "last12Mths":
      return {
        from: today.clone().add(1, "day").subtract(12, "months").toDate(),
        to: today.toDate(),
      };
    default:
      return { from: today.toDate(), to: today.toDate() };
  }
}
export function getRangeFilter(
  range: DateRanges,
  keys: { from: string; to: string } = { from: "from", to: "to" }
): any {
  const r = getRange(range);
  if (r) {
    return {
      [keys.from]: moment(r.from).format("YYYY-MM-DD"),
      [keys.to]: moment(r.to).format("YYYY-MM-DD"),
    };
  }
}
function getRangeId(range?: DateRange): DateRanges | undefined {
  if (!range) {
    return;
  }
  const ranges: DateRanges[] = [
    "today",
    "yesterday",
    "thisWeek",
    "thisMonth",
    "thisYear",
    "lastWeek",
    "lastMonth",
    "lastYear",
    "last7Days",
    "last30Days",
    "last90Days",
    "last12Mths",
  ];

  for (let index = 0; index < ranges.length; index++) {
    const rangeId = ranges[index];
    const dateRange = getRange(rangeId);
    if (
      dateRange.from?.getTime() === range.from?.getTime() &&
      dateRange.to?.getTime() === range.to?.getTime()
    ) {
      return rangeId;
    }
  }
}

export type DateRangePickerProps = {
  id?: string;
  value?: PickerState;
  onChange?: (value: PickerState) => void;
  defaultRangeId?: DateRanges;
  changeOnClickAway?: boolean;
  onSetDefault?: (rangeId: DateRanges) => void;
};
export default function DateRangePicker({
  id,
  value,
  onChange,
  defaultRangeId = "lastWeek",
  changeOnClickAway = true,
  onSetDefault,
}: DateRangePickerProps) {
  const [open, setOpen] = useState<boolean>(false);
  const defaultSelected: DateRange = getRange(defaultRangeId);
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const [month, setMonth] = useState<Date>(range?.from || new Date());
  const rangeId = getRangeId(range);

  const textFieldRef = useRef<null | HTMLButtonElement>(null);
  const theme = useTheme();

  function setPredefinedRange(rangeName: DateRanges) {
    const newRange = getRange(rangeName);
    if (newRange.from) {
      setMonth(newRange.from);
      setRange(newRange);
    }
  }

  function getRangeName(): string {
    if (rangeId) {
      return I18n.t(`App.dateRanges.${rangeId}`, {
        defaultValue: rangeId,
      });
    }
    const diff = moment(range?.to).diff(range?.from, "days");
    if (diff < 0 || (!range?.to && !range?.from)) {
      return "Not Set";
    }
    if (diff === 0) {
      return "1 Day";
    }

    if (diff === 6 && moment(range?.from).weekday() === 0) {
      return `Week ${moment(range?.from).week()}`;
    }
    return `${diff + 1} Days`;
  }

  function formatDateRange() {
    const from = range?.from ? moment(range?.from) : undefined;
    const to = range?.to ? moment(range?.to) : undefined;
    if (from?.year() === moment().year() && to?.year() === moment().year()) {
      if (from.month() === to?.month() && from.date() === to?.date()) {
        return from.format("DD MMM");
      }
      if (from.month() === to?.month()) {
        return `${from ? from.format("DD") : ""} - ${
          to ? to.format("DD MMM") : ""
        }`;
      }
      return `${from ? from.format("DD MMM") : ""} - ${
        to ? to?.format("DD MMM") : ""
      }`;
    } else if (from?.year() === to?.year()) {
      if (from?.month() === to?.month() && from?.date() === to?.date()) {
        return from?.format("DD MMM YYYY");
      }
      if (from?.month() === to?.month()) {
        return `${from ? from.format("DD") : ""} - ${
          to ? to.format("DD MMM YYYY") : ""
        }`;
      }
      return `${from ? from.format("DD MMM") : ""} - ${
        to ? to?.format("DD MMM YYYY") : ""
      }`;
    } else {
      return `${from ? from.format("DD MMM YYYY") : ""} - ${
        to ? to.format("DD MMM YYYY") : ""
      }`;
      //return `${from ? from.format("L") : ""} - ${to ? to.format("L") : ""}`;
    }
  }

  function prevInterval() {
    if (range?.from && range.to) {
      const days = moment(range.to).diff(moment(range.from), "days") + 1;
      let from = moment(range.from).subtract(days, "days");
      let to = moment(range.to).subtract(days, "days");

      const yearFrom = moment(range.from).year();
      const monthFrom = moment(range.from).month();
      const dayFrom = moment(range.from).date();
      const yearTo = moment(range.to).year();
      const monthTo = moment(range.to).month();
      const dayTo = moment(range.to).date();
      const lastMonthDay = moment(range.from).endOf("month").date();

      if (
        yearFrom === yearTo &&
        monthFrom === monthTo &&
        dayFrom === 1 &&
        dayTo === lastMonthDay
      ) {
        from = moment(range.from).subtract(1, "month").startOf("month");
        to = moment(range.from).subtract(1, "month").endOf("month").set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      } else if (
        yearFrom === yearTo &&
        monthFrom === 0 &&
        dayFrom === 1 &&
        monthTo === 11 &&
        dayTo === 31
      ) {
        from = moment(range.from).subtract(1, "year").startOf("year");
        to = moment(range.from).subtract(1, "year").endOf("year").set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      if (open) {
        setMonth(from.toDate());
        setRange({
          from: from.toDate(),
          to: to.toDate(),
        });
      } else {
        onChange &&
          onChange({
            from: from.format("YYYY-MM-DD"),
            to: to.format("YYYY-MM-DD"),
          });
      }
    }
  }

  function nextInterval() {
    if (range?.from && range.to) {
      const days = moment(range.to).diff(moment(range.from), "days") + 1;
      const months = moment(range.to).diff(moment(range.from), "months", true);
      let from = moment(range.from).add(days, "days");
      let to = moment(range.to).add(days, "days");

      const yearFrom = moment(range.from).year();
      const monthFrom = moment(range.from).month();
      const dayFrom = moment(range.from).date();
      const yearTo = moment(range.to).year();
      const monthTo = moment(range.to).month();
      const dayTo = moment(range.to).date();
      const lastMonthDay = moment(range.from).endOf("month").date();

      if (
        yearFrom === yearTo &&
        monthFrom === monthTo &&
        dayFrom === 1 &&
        dayTo === lastMonthDay
      ) {
        from = moment(range.from).add(1, "month").startOf("month");
        to = moment(range.from).add(1, "month").endOf("month").set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      } else if (
        yearFrom === yearTo &&
        monthFrom === 0 &&
        dayFrom === 1 &&
        monthTo === 11 &&
        dayTo === 31
      ) {
        from = moment(range.from).add(1, "year").startOf("year");
        to = moment(range.from).add(1, "year").endOf("year").set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      if (open) {
        setMonth(from.toDate());
        setRange({
          from: from.toDate(),
          to: to.toDate(),
        });
      } else {
        onChange &&
          onChange({
            from: from.format("YYYY-MM-DD"),
            to: to.format("YYYY-MM-DD"),
          });
      }
    }
  }

  function getRangeFromValue(v?: PickerState) {
    return {
      from: v?.from ? moment(v.from).toDate() : undefined,
      to: v?.to ? moment(v.to).toDate() : undefined,
    };
  }

  function getValueFromRange(v?: DateRange) {
    return {
      from: v?.from ? moment(v.from).format("YYYY-MM-DD") : undefined,
      to: v?.to ? moment(v.to).format("YYYY-MM-DD") : undefined,
    };
  }
  useEffect(() => {
    const r = getRangeFromValue(value);
    setRange(r);
    if (r?.from) {
      setMonth(r.from);
    }
  }, [value]);

  function RangeListButton({ id }: { id: DateRanges }) {
    const isDefault: boolean = defaultRangeId === id;
    return (
      <ListItemButton
        selected={rangeId === id}
        onClick={() => setPredefinedRange(id)}
        sx={{
          ".setDefaultButton": {
            display: "none",
          },
          "&:hover": {
            ".setDefaultButton": {
              display: "block",
            },
          },
        }}
      >
        <ListItemText
          primary={I18n.t(`App.dateRanges.${id}`, {
            defaultValue: id,
          })}
        />
        {isDefault ? (
          <ListItemSecondaryAction sx={{ pointerEvents: "none" }}>
            <Chip size="small" label={I18n.t("App.default")} />
          </ListItemSecondaryAction>
        ) : (
          <ListItemSecondaryAction className="setDefaultButton">
            <Button
              size="small"
              variant="text"
              onClick={evt => {
                evt.stopPropagation();
                onSetDefault && onSetDefault(id);
              }}
            >
              {I18n.t("App.setDefault")}
            </Button>
          </ListItemSecondaryAction>
        )}
      </ListItemButton>
    );
  }

  function resetRangeFromValue() {
    const r = getRangeFromValue(value);
    setRange(r);
    if (r?.from) {
      setMonth(r.from);
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <GlobalStyles
        styles={{
          ".rdp": {
            fontSize: "0.875rem",
            "--rdp-cell-size": "40px",
            "--rdp-accent-color": theme.palette.primary.main,
            "--rdp-background-color": theme.palette.primary.main,
          },
        }}
      />
      <Box
        sx={{
          flexGrow: 0,
          "& button": {
            border: `solid 1px ${theme.palette.divider}!important`,
          },
        }}
        ref={textFieldRef}
      >
        <ButtonGroup
          color="inherit"
          //direction="row"
          //spacing={1}
          //ref={textFieldRef}
          //sx={{ alignItems: "center" }}
        >
          <Button
            onClick={evt => {
              evt.stopPropagation();
              prevInterval();
            }}
          >
            <ChevronLeftIcon color="action" />
          </Button>
          <Button
            onClick={evt => {
              evt.stopPropagation();
              setOpen(true);
            }}
            sx={{ display: "flex" }}
            //startIcon={<EventIcon fontSize="small" />}
          >
            <Box>
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <EventIcon fontSize="small" color="action" />
                <Box>
                  <Typography>{formatDateRange()}</Typography>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: "0.7rem",
                      color: "text.secondary",
                      marginTop: "-4px",
                      marginBottom: "-4px",
                      alignSelf: "flex-end",
                    }}
                  >
                    {getRangeName()}
                  </Typography>
                </Box>

                {defaultRangeId !== rangeId && (
                  <IconButton
                    component="div"
                    size="small"
                    onClick={evt => {
                      evt.stopPropagation();
                      if (open) {
                        setPredefinedRange(defaultRangeId);
                      } else {
                        onChange &&
                          onChange(getValueFromRange(defaultSelected));
                      }
                    }}
                  >
                    <ClearIcon fontSize="small" color="action" />
                  </IconButton>
                )}
              </Stack>
            </Box>
          </Button>
          <Button
            onClick={evt => {
              evt.stopPropagation();
              nextInterval();
            }}
          >
            <ChevronRightIcon color="action" />
          </Button>
        </ButtonGroup>
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={textFieldRef.current}
        transition
        placement="bottom-start"
        sx={{ zIndex: 1300 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper
              elevation={5}
              sx={{
                p: 1,
              }}
            >
              <ClickAwayListener
                onClickAway={() => {
                  if (changeOnClickAway) {
                    if (range?.from && range?.to) {
                      const newValue = getValueFromRange(range);
                      if (JSON.stringify(newValue) !== JSON.stringify(value)) {
                        onChange && onChange(newValue);
                      }
                    } else {
                      resetRangeFromValue();
                    }
                  }
                  setOpen(false);
                }}
              >
                <Box>
                  <Stack direction="row">
                    <List
                      dense
                      sx={{
                        minWidth: "190px",
                        borderRight: theme =>
                          `solid 1px ${theme.palette.divider}`,
                      }}
                      disablePadding
                    >
                      <RangeListButton id="today" />
                      <RangeListButton id="yesterday" />
                      <RangeListButton id="thisWeek" />
                      <RangeListButton id="lastWeek" />
                      <RangeListButton id="thisMonth" />
                      <RangeListButton id="lastMonth" />
                      <RangeListButton id="last30Days" />
                      <RangeListButton id="last90Days" />
                      <RangeListButton id="last12Mths" />
                      <RangeListButton id="thisYear" />
                      <RangeListButton id="lastYear" />
                    </List>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <DayPicker
                        numberOfMonths={2}
                        mode="range"
                        selected={range}
                        onSelect={rng => {
                          setRange({
                            from: rng?.from
                              ? moment(
                                  moment(rng?.from).local().format("YYYY-MM-DD")
                                ).toDate()
                              : undefined,
                            to: rng?.to
                              ? moment(
                                  moment(rng?.to).local().format("YYYY-MM-DD")
                                ).toDate()
                              : undefined,
                          });
                        }}
                        //defaultMonth={range?.from}
                        month={month}
                        onMonthChange={setMonth}
                        weekStartsOn={1}
                      />
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          //backgroundColor: "red",
                          //alignSelf: "flex-end",
                          //justifySelf: "flex-end",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setOpen(false);
                            resetRangeFromValue();
                          }}
                        >
                          {I18n.t("App.cancel")}
                        </Button>
                        <Spacer w={2} />
                        <Button
                          onClick={() => {
                            setOpen(false);
                            if (range?.from && range?.to) {
                              const newValue = getValueFromRange(range);
                              if (
                                JSON.stringify(newValue) !==
                                JSON.stringify(value)
                              ) {
                                onChange && onChange(newValue);
                              }
                            } else {
                              resetRangeFromValue();
                            }
                          }}
                        >
                          {I18n.t("App.ok")}
                        </Button>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
