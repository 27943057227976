import { ChartData, ChartDataset } from "chart.js";
import useSettings from "features/settings/useSettings";
import { createContext, useContext, useMemo } from "react";
import groupReportData from "./groupReportData";
import transformChartData from "./transformChartData";
import {
  AdvancedReportDataContextType,
  useAdvancedReportDataContext,
} from "./useAdvancedReportData";

export type AdvancedReportParamsType = {
  chartType: "line" | "bar" | "pie";
  value:
    | "amount"
    | "count"
    | "guests"
    | "canceled_amount"
    | "canceled_count"
    | "canceled_guests";
  groupBy: "program" | "program_variant" | "boat" | "partner" | "partner_group";
};

export type GroupedReportData = {
  label: string; // The group label (e.g., partner name, boat name, etc.)
  amount: number; // The summed 'amount' field
  canceled_amount: number; // The summed 'canceled_amount' field
  canceled_count: number; // The summed 'canceled_count' field
  canceled_guests: number; // The summed 'canceled_guests' field
  count: number; // The summed 'count' field
  guests: number; // The summed 'guests' field
  color: string; // The assigned color for the group
};

// Correct the spelling of 'backgroundColor' in your properties
interface ExtendedChartDataset<T = number[]> extends ChartDataset<"bar", T> {
  total: number;
  totalAmount: number;
  totalGuests: number;
  backgroundColor: string; // Ensure the spelling is correct
}

export interface AdvancedChartData extends ChartData<"bar", number[], string> {
  datasets: ExtendedChartDataset[];
}

export type AdvancedReportContextType = {
  data: AdvancedChartData;
  pivot: AdvancedChartData;
  params: AdvancedReportParamsType;
  setParams: (filter: AdvancedReportParamsType) => void;
  load: () => Promise<void>;
  loading: boolean;
} & Omit<AdvancedReportDataContextType, "data">;

export const AdvancedReportContext =
  createContext<AdvancedReportContextType | null>(null);

export interface UseAdvancedReportProps
  extends Partial<AdvancedReportParamsType> {}
export default function useAdvancedReport(props?: UseAdvancedReportProps) {
  const { data, filter, setFilter, load, loading } =
    useAdvancedReportDataContext();
  /*const [params, setParams] = useState<AdvancedReportParamsType>({
    chartType: "bar",
    value: "amount",
    groupBy: "program",
    ...props,
  });*/
  const [params, setParams] = useSettings({ key: "advancedReport" });

  const transforms = useMemo(() => {
    return {
      data: transformChartData(
        data as any,
        params.groupBy,
        params.chartType,
        filter.from,
        filter.to,
        params.value,
        params.show_canceled
      ),
      pivot: groupReportData(
        data as any,
        params.groupBy,
        filter.from,
        filter.to,
        params.value,
        params.show_canceled
      ),
    };
  }, [
    params.value,
    params.chartType,
    params.groupBy,
    params.show_canceled,
    data,
  ]);

  return {
    data: transforms.data as any,
    pivot: transforms.pivot as any,
    filter,
    setFilter,
    params,
    setParams,
    load,
    loading,
  };
}

export function useAdvancedReportContext() {
  const context = useContext(AdvancedReportContext);
  if (!context) {
    throw Error(
      "useAdvancedReportContext cannot be used outside of AdvancedReportProvider."
    );
  }
  return context;
}
