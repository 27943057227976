import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import Save from "@mui/icons-material/Save";
import { CircularProgress } from "@mui/material";
import {
  FormBlock,
  NavigationTab,
  PageHeaderBreadcrumbs,
  Spacer,
  TabNavigation,
  Toolbar,
  useSelectedTab,
} from "common";
import FabSaveButton from "components/FabSaveButton/FabSaveButton";
import { EventDefaultsButton } from "features/events/EventDefaultsButton";
import GenerateButton from "features/schedules/actions/GenerateButton";
import I18n from "i18n";
import { createContext } from "react";
import { Outlet } from "react-router-dom";
import CancelEventsButton from "./actions/CancelEventsButton";
import CloneButton from "./actions/CloneButton";
import DeleteEventsButton from "./actions/DeleteEventsButton";
import useScheduleForm, {
  UseScheduleFormProps,
  UseScheduleFormReturnType,
} from "./useScheduleForm";

export const ScheduleContext = createContext<
  UseScheduleFormReturnType | undefined
>(undefined);
export interface ScheduleFormProps extends UseScheduleFormProps {}

export default function ScheduleTabs() {
  const form = useScheduleForm({});
  const { item, loading, isDirty, isCreate, save, saving, isUpdate } = form;
  const title = isCreate
    ? I18n.t("Schedule.addTitle")
    : `${item?.name} ${item?.date_from}-${item?.date_to}`;
  const subtitle = isCreate ? undefined : `${item?.boat?.name}`;
  const selectedTab = useSelectedTab({ pattern: "schedules/:schedule_id" });
  return (
    <ScheduleContext.Provider value={form as any}>
      <PageHeaderBreadcrumbs
        avatar={<EditCalendarIcon />}
        loading={loading}
        title={title}
        subtitle={subtitle}
        backUrl=".."
      >
        <EventDefaultsButton
          disabled={!item.boat_id || !item.program_ids || item.has_event}
          variant="outlined"
          onUpdate={values => {
            form.setAttributes(values);
          }}
          program_ids={item.program_ids}
          boat_id={item.boat_id}
        />
        <CancelEventsButton />
        <DeleteEventsButton />
        <GenerateButton />
        <CloneButton />
      </PageHeaderBreadcrumbs>
      <FormBlock>
        <Toolbar>
          <TabNavigation value={selectedTab || ""}>
            <NavigationTab
              label={`${I18n.t("Schedule.titleSingular")} ${I18n.t(
                "App.mainData"
              )}`}
              value=""
              replace
            />
            <NavigationTab
              label={I18n.t("Event.title")}
              value="events"
              replace
              disabled={!isUpdate || !item.has_event}
            />
          </TabNavigation>
          <Spacer right />
          <FabSaveButton
            color="primary"
            sx={{ position: "fixed", right: 50, bottom: 50 }}
            title={I18n.t("App.save")}
            visible={isDirty}
            disabled={!isDirty}
            onClick={() => save()}
            //loading={saving}
          >
            {saving ? <CircularProgress size={24} /> : <Save />}
          </FabSaveButton>
        </Toolbar>
        <Spacer h={3} />
        <Outlet />
      </FormBlock>
    </ScheduleContext.Provider>
  );
}
