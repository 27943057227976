import { alpha, Box, List, Typography } from "@mui/material";
import { KitchenItem, Summary } from "./kitchenApi";
import KitchenListItem from "./KitchenListItem";
import OrderItem from "./OrderItem";

interface KitchenListProps {
  kitchenData: KitchenItem[];
}

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

export default function KitchenList({ kitchenData }: KitchenListProps) {
  // Group and sum the summaries by name
  const groupedSummaries = kitchenData
    .flatMap(item => item.summary)
    .reduce((acc, summary) => {
      const existing = acc.find(s => s.name === summary.name);
      if (existing) {
        existing.qty += summary.qty;
      } else {
        acc.push({ ...summary });
      }
      return acc;
    }, [] as Summary[]);
  groupedSummaries.sort((a, b) => b.qty - a.qty);
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {kitchenData.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 1,
            flexWrap: "wrap",
            p: 1,
            borderRadius: "6px",
            border: theme => `solid 1px ${alpha(theme.palette.info.main, 0.4)}`,
            backgroundColor: theme => alpha(theme.palette.info.main, 0.1),
          }}
        >
          <Typography variant="body1" align="center">
            {groupedSummaries.length > 0
              ? "Időszak összes rendelése"
              : "Nincs rendelés az időszakra"}
          </Typography>
          {groupedSummaries.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {groupedSummaries.map((summary, index) => (
                <OrderItem
                  key={index}
                  name={summary.name}
                  qty={summary.qty}
                  type={summary.type}
                />
              ))}
            </Box>
          )}
        </Box>
      )}
      <List disablePadding dense>
        {kitchenData.map(item => (
          <KitchenListItem
            key={`${item.boat_id}-${item.start_time}`}
            item={item}
          />
        ))}
      </List>
    </Box>
  );
}
