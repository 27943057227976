import WorkspacesIcon from "@mui/icons-material/Workspaces";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridModalEditColumn,
  enumToOptions,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { OptionGroupItem } from "./optionGroupApi";
import { OPTIONGROUP_TYPES } from "./optionGroupConstants";
import OptionGroupForm from "./OptionGroupForm";
import useOptionGroupList from "./useOptionGroupList";
export interface OptionGroupListProps
  extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function OptionGroupList({
  showHeader = true,
  ...props
}: OptionGroupListProps) {
  const { params, scope, editUrl, ...service } = useOptionGroupList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<OptionGroupItem> = [
    {
      field: "name",
      headerName: I18n.t("OptionGroup.attributes.name"),
      renderCell: params => (
        <DataGridModalEditColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
      flex: 1,
    },
    {
      field: "type",
      headerName: I18n.t("OptionGroup.attributes.type"),
      type: "singleSelect",
      valueOptions: enumToOptions(
        OPTIONGROUP_TYPES,
        "OptionGroup.options.type"
      ),
    },
    {
      field: "has_price",
      headerName: I18n.t("OptionGroup.attributes.has_price"),
      type: "boolean",
    },
    {
      field: "has_description",
      headerName: I18n.t("OptionGroup.attributes.has_description"),
      type: "boolean",
    },
    {
      field: "required",
      headerName: I18n.t("OptionGroup.attributes.required"),
      type: "boolean",
    },
    {
      field: "kitchen",
      headerName: I18n.t("OptionGroup.attributes.kitchen"),
      type: "boolean",
    },
    {
      field: "active",
      headerName: I18n.t("OptionGroup.attributes.active"),
      type: "boolean",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<WorkspacesIcon />}
          title={I18n.t("OptionGroup.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="option-groups"
        renderModalForm={props => <OptionGroupForm {...props} />}
        modalProps={{ maxWidth: "sm" }}
        {...props}
        //getHeaderName={field => I18n.t(`OptionGroup.attributes.${field.replaceAll(".", "_")}`)}
        canOrderRows
        canCreate={hasPermissions("option_group.create")}
        canDelete={hasPermissions([
          "option_group.editany",
          "option_group.editown",
        ])}
        canEdit={hasPermissions([
          "option_group.editany",
          "option_group.editown",
        ])}
        canDeleteRow={params => canEditItem("option_group", params.row)}
        canEditRow={params => canEditItem("option_group", params.row)}
        canBatchActions={hasPermissions([
          "option_group.editany",
          "option_group.editown",
        ])}
        canHandleTrash={hasPermissions([
          "option_group.editany",
          "option_group.editown",
        ])}
      />
    </>
  );
}
