import { size } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { getUpgradesData, groupByStartTime, UpgradesData } from "./upgradesApi";
type Range = { start?: Moment; end?: Moment };
export type UpgradesLisContextType = {
  upgradesData: UpgradesData;
  loading: boolean;
  load: () => void;
  handleFilter: (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null
  ) => Promise<void>;
  setRange: (range: { start?: Moment; end?: Moment } | undefined) => void;
  range: Range | undefined;
  loaded: React.MutableRefObject<boolean>;
  isEmpty: boolean;
  setSettings: (settings: any) => void;
  settings: any;
};

export default function useUpgradesList(
  open: boolean,
  defaultRange?: Range
): UpgradesLisContextType {
  const [range, setRange] = useState<Range | undefined>(
    defaultRange || {
      start: moment().startOf("day"),
      end: moment().endOf("day"),
    }
  );

  const [settings, _setSettings] = useState<any>({});
  function setSettings(s: any) {
    _setSettings({ ...settings, ...s });
  }
  const [loading, setLoading] = useState<boolean>(true);
  const loaded = useRef(false);
  const [upgradesData, setUpgradesData] = useState<UpgradesData>({});

  const handleFilter = async (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null
  ) => {
    setLoading(true);
    const { success, data } = await getUpgradesData(
      startDate,
      endDate,
      boatIds,
      programIds
    );
    loaded.current = true;
    if (success) {
      setUpgradesData(groupByStartTime(data) || {});
    }
    setLoading(false);
  };

  function load() {
    return handleFilter(
      range?.start?.format("YYYY-MM-DD"),
      range?.end?.format("YYYY-MM-DD"),
      settings.boatIds,
      settings.programIds
    );
  }

  const isEmpty = loaded.current && size(upgradesData) === 0;

  useEffect(() => {
    if (open) {
      load();
    }
  }, [range, settings.boatIds, settings.programIds, open]);

  return {
    upgradesData,
    loading,
    load,
    handleFilter,
    setRange,
    range,
    loaded,
    isEmpty,
    setSettings,
    settings,
  };
}
