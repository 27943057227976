import useSettings from "features/settings/useSettings";
import { size } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { CateringItem, getCateringData } from "./cateringApi";

export default function useCateringList() {
  const [range, setRange] = useState<
    { start?: Moment; end?: Moment } | undefined
  >({
    start: moment().startOf("day"),
    end: moment().endOf("day"),
  });

  const [settings, setSettings] = useSettings({ key: "catering" });
  const loaded = useRef(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [cateringData, setCateringData] = useState<CateringItem[]>([]);

  const handleFilter = async (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null
  ) => {
    setLoading(true);
    const { success, data } = await getCateringData(
      startDate,
      endDate,
      boatIds,
      programIds
    );
    loaded.current = true;
    if (success) {
      setCateringData(data || []);
    }
    setLoading(false);
  };
  function load() {
    return handleFilter(
      range?.start?.format("YYYY-MM-DD"),
      range?.end?.format("YYYY-MM-DD"),
      settings.boatIds,
      settings.programIds
    );
  }
  const isEmpty = loaded.current && size(cateringData) === 0;

  useEffect(() => {
    load();
  }, [range, settings.boatIds, settings.programIds]);

  return {
    cateringData,
    loading,
    load,
    handleFilter,
    setRange,
    range,
    loaded,
    isEmpty,
    setSettings,
  };
}
