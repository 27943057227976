import {
  GridModalFormProps,
  Option,
  UseFormReturnType,
  Validator,
  enumToOptions,
  globalMessageHandler,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { Dictionary } from "features/dictionaries/dictionaryApi";
import useDictionaries from "features/dictionaries/useDictionaries";
import { EventItem, getEventService } from "features/events/eventApi";
import { EVENT_STATUSES } from "features/events/eventConstants";
import { OptionItem } from "features/options/optionApi";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { ProgramItem } from "features/programs/programApi";
import { LIMIT_TYPES } from "features/schedules/scheduleConstants";
import { validateEvent } from "helpers/validationHelper";

import I18n from "i18n";
import { useMemo, useState } from "react";
import { Params } from "react-router-dom";

export interface UseEventFormProps extends GridModalFormProps {}

type RouteParams = {
  event_id?: string;
};

export interface UseEventFormReturnType
  extends Omit<UseFormReturnType<EventItem>, "options"> {
  params?: Readonly<
    Params<
      | "event_id"
      | "boat_id"
      | "program_id"
      | "schedule_id"
      | "booking_id"
      | "booking_option_id"
    >
  >;
  setOnlineClosed: (varient_id: number, is_closed: boolean) => void;
  setClosed: (varient_id: number, is_closed: boolean) => void;
  onlineCloseLoading: boolean;
  closeLoading: boolean;
  program?: ProgramItem;
  programs?: ProgramItem[];
  options: Dictionary;
  type: Option[];
}

export default function useEventForm({
  id,
  afterSave,
  defaultValues,
}: UseEventFormProps): UseEventFormReturnType {
  const params = useParams<
    | "event_id"
    | "boat_id"
    | "program_id"
    | "schedule_id"
    | "booking_id"
    | "booking_option_id"
  >();
  const scope = {
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
  };
  const service = getEventService(scope);

  const form = useForm<EventItem>({
    id: params.event_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "Event",
    rules: {
      start_time: Validator.rule.any().required(),
      boat_id: params.boat_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      program_id: params.program_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      duration: Validator.rule.any().required(),
      status: Validator.rule.any().required(),
      variants: Validator.rule.any().required(),
      limits: Validator.rule.any().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    afterChange: (newItem, id, option) => {},
    ...service,
    //loadItem: undefined,
    loadOptions: undefined,
    customValidation(newItem) {
      return customValidation(newItem);
    },
  });
  const { dictionaries, resolveOptions, filterOptions, resolveOption } =
    useDictionaries();

  const { options, program, programs, type, hasOptions } = useMemo(() => {
    const program = resolveOption<ProgramItem>(
      "programs",
      form.item.program_id
    );
    const schedulePrograms = resolveOptions<ProgramItem>(
      "programs",
      form.item?.schedule?.program_ids
    );
    const programs =
      schedulePrograms && schedulePrograms.length > 0
        ? schedulePrograms
        : program
        ? [program]
        : [];

    const opts = filterOptions<OptionItem>(
      "options",
      item =>
        form.item?.schedule?.program_ids?.includes(item.program_id) ||
        form.item?.program_id === item.program_id
    ).map(o => ({ ...o, label: `${o.option_group?.name}: ${o.name}` }));

    opts.sort((a, b) => {
      const sortByA = a?.option_group?.sort_by ?? 0;
      const sortByB = b?.option_group?.sort_by ?? 0;

      // Primary sort by sort_by
      if (sortByA !== sortByB) {
        return sortByA - sortByB;
      }

      // Secondary sort by name
      const nameA = a?.name?.toLowerCase() ?? "";
      const nameB = b?.name?.toLowerCase() ?? "";

      return nameA.localeCompare(nameB);
    });

    return {
      options: {
        ...dictionaries,
        boat_id: dictionaries.boats,
        layouts: dictionaries.layouts,
        variants: form.item?.schedule
          ? filterOptions<ProgramVariantItem>("variants", item =>
              form.item?.schedule?.program_ids?.includes(item.program_id)
            )
          : filterOptions<ProgramVariantItem>(
              "variants",
              item => form.item?.program_id === item.program_id
            ),
        options: opts,
        program_id: dictionaries.programs,
        status: enumToOptions(EVENT_STATUSES, "Event.options.status"),
      } as any,
      hasOptions: !!programs?.find(p => p.has_options),
      program,
      programs,
      type: enumToOptions(LIMIT_TYPES, "Limit.options.type"),
    };
  }, [form.item.schedule, form.item.program_id]);

  const [onlineCloseLoading, setOnlineCloseLoading] = useState(false);

  function setOnlineClosed(variant_id: number, is_closed: boolean) {
    const text = is_closed
      ? "Biztosan lezárod az online foglalást?"
      : "Biztosan megnyitod az online foglalást?";
    globalMessageHandler.confirm({ message: text }, async () => {
      setOnlineCloseLoading(true);
      let online_closed = form.item.online_closed
        ? form.item.online_closed.slice()
        : [];
      if (is_closed) {
        online_closed = [
          ...online_closed.filter(v => v !== variant_id),
          variant_id,
        ];
      } else {
        online_closed = online_closed.filter(v => v !== variant_id);
      }
      const { success, data } = await service.updateItem(form.item.id, {
        online_closed,
      });
      setOnlineCloseLoading(false);
      if (success) {
        setTimeout(() => {
          if (is_closed) {
            globalMessageHandler.snack({
              severity: "success",
              message: "Sikeresen lezártad az online foglalást.",
            });
          } else {
            globalMessageHandler.snack({
              severity: "success",
              message: "Sikeresen megnyitottad az online foglalást.",
            });
          }
        }, 300);
        console.log(data);
        form.setAttributes(data as any, true);
      }
      return !!success;
    });
  }
  const [closeLoading, setCloseLoading] = useState(false);
  function setClosed(variant_id: number, is_closed: boolean) {
    const text = is_closed
      ? "Biztosan lezárod a foglalást?"
      : "Biztosan megnyitod a foglalást?";
    globalMessageHandler.confirm({ message: text }, async () => {
      setCloseLoading(true);
      let closed = form.item.closed ? form.item.closed.slice() : [];
      if (is_closed) {
        closed = [...closed.filter(v => v !== variant_id), variant_id];
      } else {
        closed = closed.filter(v => v !== variant_id);
      }
      const { success, data } = await service.updateItem(form.item.id, {
        closed,
      });
      setCloseLoading(false);
      if (success) {
        form.setAttributes(data as any, true);

        setTimeout(() => {
          if (is_closed) {
            globalMessageHandler.snack({
              severity: "success",
              message: "Sikeresen lezártad a foglalást.",
            });
          } else {
            globalMessageHandler.snack({
              severity: "success",
              message: "Sikeresen megnyitottad a foglalást.",
            });
          }
        }, 300);
      }
      return !!success;
    });
  }

  function customValidation(newItem: EventItem) {
    const customErrors = validateEvent(
      newItem.limits,
      newItem.variants,
      newItem.options,
      programs,
      options?.variants,
      hasOptions
    );

    let errors: any = {};
    if (customErrors.limits) errors.limits = customErrors.limits;
    if (customErrors.variants) errors.variants = customErrors.variants;
    if (customErrors.options) errors.options = customErrors.options;

    return Object.keys(errors).length > 0 ? errors : undefined;
  }

  let rules: any = {};

  if (hasOptions) {
    rules.options = Validator.rule.any().required();
  }
  form.addRules(rules);

  /*useEffect(() => {
    if (defaultValues) {
      console.log("HAS DEFAULT VALUES", defaultValues);
      form.setItem(defaultValues as any);
    }
  }, [defaultValues]);*/

  return {
    ...form,
    options,
    program,
    programs,
    params,
    closeLoading,
    onlineCloseLoading,
    setClosed,
    setOnlineClosed,
    type,
  };
}
