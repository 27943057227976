import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Button,
  Field,
  FieldsContainer,
  Form,
  TextField,
  Toolbar,
} from "common";
import Price from "common/components/Inputs/Price/Price";
import { BookingItem } from "features/bookings/bookingApi";
import { BookingContext } from "features/bookings/BookingForm";
import BookingOptionSelector from "features/bookings/BookingOptionSelector";
import useDictionaries from "features/dictionaries/useDictionaries";
import { EventItem } from "features/events/eventApi";
import { getPrice } from "helpers/priceHelper";
import I18n from "i18n";
import moment from "moment";
import { useUpgradeContext } from "./UpgradeProvider";

const inputSx = {};
const inputSx2 = {
  "& .MuiOutlinedInput-root": {
    fontSize: "1.2rem", // Make text size 1.5 times bigger
    height: "47px", // Increase the height for a bigger textfield (1.5 times the default ~38px)
    "& fieldset": {
      borderColor: "info.main", // Info color for the border
      borderWidth: "2px!important", // Thicker border
    },
    "& input": {
      padding: "13px 12px 9.5px!important",
    },
    "&:not(.Mui-error):hover fieldset": {
      borderColor: "info.dark", // Darker info color on hover
    },
    "&.Mui-focused:not(.Mui-error) fieldset": {
      borderColor: "info.main", // Maintain info color when focused
    },
  },

  "& fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: "info.main", // Maintain info color when focused
    top: 0,
  },
  "& .MuiInputLabel-root": {
    fontSize: "1.2rem", // Make label text bigger as well
    "&.Mui-focused:not(.Mui-error)": {
      color: (theme: any) => theme.palette.info.main,
    },
  },
};

export interface UpgradeCardProps {
  booking: BookingItem;
  event?: EventItem;
  programVariantId?: number;
}

const UpgradeCard: React.FC<UpgradeCardProps> = ({
  booking,
  event,
  programVariantId,
}) => {
  const theme = useTheme();
  const { resolveOption } = useDictionaries();
  const {
    optionValidator,
    optionGroups,
    item,
    onChange,
    errors,
    getRequiredFields,
    save,
    saving,
    isDirty,
  } = useUpgradeContext();
  const programVariant = resolveOption("variants", programVariantId);
  const numberOfGuests =
    booking.number_of_adults +
    booking.number_of_kids +
    booking.number_of_babies;

  const isCurrent =
    booking?.event_id === event?.id &&
    booking.program_variant_id === programVariantId;
  const isSameEvent = booking?.event_id === event?.id;
  const isSameVariant = booking.program_variant_id === programVariantId;
  const price = getPrice(booking).sum;

  const eventVariant = event?.variants?.find(
    v => v.variant_id === programVariantId
  );
  //const adultPrice = booking?.adult_price || 0;
  //const kidPrice = booking?.kid_price || 0;
  const numberOfAdults = booking?.number_of_adults || 0;
  const numberOfKids = booking?.number_of_kids || 0;
  const hasEnoughPlaces =
    (eventVariant?.remaining_places || 0) > numberOfAdults + numberOfKids;
  const color = hasEnoughPlaces
    ? programVariant?.program?.color
    : theme.palette.grey[700];
  //const variant = resolveOption("variants", eventVariant?.variant_id);

  //Prices

  //const bookingAmount = adultPrice * numberOfAdults + kidPrice * numberOfKids;
  const variantAmount =
    (eventVariant?.adult_price || 0) * numberOfAdults +
    (eventVariant?.kid_price || 0) * numberOfKids;
  //const additionalAmount = Math.round((variantAmount - bookingAmount) * 100) / 100;
  const hasSelected = isCurrent || !!item.program_variant_id;
  return (
    <Card style={{ padding: "8px" }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              backgroundColor: programVariant?.program?.color,
              color: theme =>
                programVariant?.program?.color
                  ? theme.palette.getContrastText(
                      programVariant?.program?.color
                    )
                  : undefined,
            }}
          >
            {programVariant?.program?.code || " "}
          </Avatar>
        }
        title={hasSelected ? programVariant?.name : "Válassz egy programot."}
        titleTypographyProps={{ variant: "subtitle1" }}
        subheaderTypographyProps={{ variant: "subtitle2" }}
        subheader={
          hasSelected
            ? moment(event?.start_time).format("L LT")
            : "Az űrlap alatti listából."
        }
        action={
          <Typography
            sx={{
              borderRadius: "4px",
              px: 1,
              py: 0.5,
              backgroundColor: isCurrent
                ? "info.dark"
                : price > variantAmount
                ? "error.dark"
                : "success.dark",
            }}
            variant="button"
            //color={!isCurrent && price > variantAmount ? "error" : undefined}
            color={"white"}
          >
            {isCurrent ? "Jelenlegi" : "Upgrade"}
          </Typography>
        }
      />
      <CardContent>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box>
            <Typography variant="body2">
              {booking.name}{" "}
              <Box
                component="span"
                sx={{ fontWeight: 500, color: "info.main" }}
              >
                ({numberOfGuests} fő)
              </Box>
            </Typography>
            <Typography variant="body2">{booking.email}</Typography>
          </Box>
          <Typography
            variant="h5"
            color={!isCurrent && price > variantAmount ? "error" : undefined}
          >
            {I18n.numberToCurrency(isCurrent ? price : variantAmount, {
              precision: 2,
              unit: "€",
              format: "%u %n",
              separator: ",",
              delimiter: " ",
              stripInsignificantZeros: true,
            })}
          </Typography>
        </Toolbar>
        {isCurrent && (
          <List disablePadding dense>
            {booking?.booking_options?.map(option => {
              return (
                <ListItem disableGutters key={option.id} divider>
                  <ListItemText
                    primary={option.option?.name}
                    secondary={I18n.numberToCurrency(option.price || 0, {
                      precision: 2,
                      unit: "€",
                      format: "%u %n",
                      separator: ",",
                      delimiter: " ",
                      stripInsignificantZeros: true,
                    })}
                  />
                  <ListItemSecondaryAction>
                    <Typography>{option.qty} db</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        )}
        {!isCurrent && (
          <BookingContext.Provider value={{ optionValidator } as any}>
            <Form
              item={item}
              onChange={onChange}
              errors={errors}
              translationCategory="Booking.attributes"
              requiredFields={getRequiredFields()}
              disabled={!hasSelected}
            >
              <FieldsContainer sx={{ mt: 0 }}>
                {optionGroups?.length > 0 && (
                  <Field fullWidth>
                    <BookingOptionSelector
                      id="booking_options"
                      booking={booking}
                      options={optionGroups as any}
                    />
                  </Field>
                )}
                <Field xs={12} sm={6}>
                  <TextField id="voucher" sx={inputSx} />
                </Field>
                <Field xs={12} sm={6}>
                  <Price id="upgrade_price" sx={inputSx} />
                </Field>
                <Field fullWidth>
                  <TextField
                    id="description"
                    //sx={inputSx}
                    multiline
                    minRows={3}
                  />
                </Field>
              </FieldsContainer>
            </Form>
          </BookingContext.Provider>
        )}
      </CardContent>
      {!isCurrent && (
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            loading={saving}
            title="Upgrade"
            disabled={!hasSelected || !isDirty}
            onClick={() => save()}
          />
        </CardActions>
      )}
    </Card>
  );
};

export default UpgradeCard;
