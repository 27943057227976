import FiberNewIcon from "@mui/icons-material/FiberNew";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import {
  Alert,
  Avatar,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  alpha,
} from "@mui/material";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Checkbox,
  CreatedUpdatedBy,
  DateTimePicker,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  Spacer,
  TextField,
  Toolbar,
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import CounterButton from "common/components/Inputs/CounterButton/CounterButton";
import Price from "common/components/Inputs/Price/Price";
import AttachBookingButton from "components/AttachBookingButton/AttachBookingButton";
import { BOOKING_STATUSES } from "features/bookings/bookingConstants";
import HistoryListDialog from "features/histories/HistoryListDialog";
import useAccess from "features/profile/useAccess";
import { getPrice } from "helpers/priceHelper";
import I18n from "i18n";
import { size } from "lodash";
import moment from "moment";
import { createContext } from "react";
import BookingOptionSelector from "./BookingOptionSelector";
import useBookingForm, {
  UseBookingFormProps,
  UseBookingFormReturnType,
} from "./useBookingForm";
export const BookingContext = createContext<
  UseBookingFormReturnType | undefined
>(undefined);
export interface BookingFormProps extends UseBookingFormProps {}

export default function BookingForm({
  id,
  afterSave,
  onClose,
  defaultValues,
  listItem,
}: BookingFormProps) {
  const { hasPermissions, canEditItem } = useAccess();
  const form = useBookingForm({
    id,
    afterSave,
    defaultValues,
    listItem,
  });
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
    activateBooking,
    cancelBooking,
    setAttributes,
    statusChanging,
    capitalizeWords,
    setCapitalizeWords,
    event,
    priceMode,
    setPriceMode,
  } = form;
  const start_time = item?.planned ? item?.planned_datetime : event?.start_time;
  const title = isCreate
    ? `${event?.program?.name} ${moment(start_time).format("L LT")}`
    : `${event?.program?.name} ${moment(start_time).format("L LT")}`;
  const subtitle = isCreate
    ? `${event?.boat?.name}`
    : `${item?.booking_number} ${event?.boat?.name}`;

  const disabled =
    item.status === BOOKING_STATUSES.CANCELED || !canEditItem("booking", item);
  return (
    <ParamsContext.Provider
      value={{
        booking_id: id,
        event_id: item.event_id,
        program_variant_id: item.program_variant_id,
        partner_id: item.partner_id,
      }}
    >
      <BookingContext.Provider value={form}>
        <PageHeader
          avatar={
            <Avatar
              sx={{
                backgroundColor: event?.program?.color,
                color: theme =>
                  event?.program?.color
                    ? theme.palette.getContrastText(event?.program?.color)
                    : undefined,
              }}
            >
              {event?.program?.code}
            </Avatar>
          }
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          //loading={loading}
        >
          {isCreate && <FiberNewIcon color="info" fontSize="large" />}
        </PageHeader>
        {!!item.planned && (
          <Alert
            severity="warning"
            sx={{ borderRadius: 0 }}
            action={
              isUpdate ? (
                <AttachBookingButton
                  buttonType="button"
                  title="Csatolás"
                  variant="text"
                  color="info"
                  size="small"
                  booking={item}
                  onSelect={event => {
                    setAttributes({
                      event_id: event?.id,
                      event: event as any,
                      planned_datetime: null as any,
                      planned_event: null as any,
                      //event: event as any,
                      planned_event_id: null as any,
                      planned: false,
                      //status: EVENT_STATUSE.ACTIVE,
                    });
                  }}
                />
              ) : undefined
            }
          >
            {I18n.t("Booking.plannedNotification", {
              date: moment(event?.start_time).format("L LT"),
            })}
          </Alert>
        )}
        <Form
          loading={loading}
          item={item}
          options={options}
          errors={errors}
          onChange={onChange}
          translationCategory="Booking.attributes"
          translationPlaceholdersCategory="Booking.placeholders"
          translationHelpersCategory="Booking.helpers"
          isDirty={getIsDirty}
          requiredFields={getRequiredFields()}
          disabled={disabled}
        >
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sm={12} md={event?.program?.has_options ? 6 : 12}>
                <FieldsContainer>
                  {size((options as any)?.program_variant_id) > 1 && (
                    <Field fullWidth>
                      <ButtonGroup
                        id="program_variant_id"
                        //disabled={!!params?.program_variant_id}
                      />
                    </Field>
                  )}
                  {!!item.planned && (
                    <Field fullWidth>
                      <DateTimePicker id="planned_datetime" />
                    </Field>
                  )}
                  {!params?.partner_id && (
                    <Field fullWidth>
                      {!!item.custom_partner ? (
                        <TextField
                          useDelayedKeystrokes={false}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              pl: 1,
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Button
                                  variant="text"
                                  size="small"
                                  title={
                                    item.custom_partner ? "Egyedi" : "Meglévő"
                                  }
                                  onClick={() => {
                                    form.setAttributes({
                                      custom_partner: !item.custom_partner,
                                    });
                                  }}
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  color={
                                    capitalizeWords?.partner_name
                                      ? "info"
                                      : "default"
                                  }
                                  size="small"
                                  title={
                                    capitalizeWords?.partner_name
                                      ? "Nagybetűs szavak bekapcsolva"
                                      : "Nagybetűs szavak kikapcsolva"
                                  }
                                  onClick={() => {
                                    setCapitalizeWords({
                                      ...capitalizeWords,
                                      partner_name:
                                        !capitalizeWords?.partner_name,
                                    });
                                  }}
                                >
                                  <TextFieldsIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          id="partner_name"
                        />
                      ) : (
                        <Autocomplete
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Button
                                  variant="text"
                                  size="small"
                                  title={
                                    item.custom_partner ? "Egyedi" : "Meglévő"
                                  }
                                  onClick={() => {
                                    form.setAttributes({
                                      custom_partner: !item.custom_partner,
                                    });
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          id="partner_id"
                        />
                      )}
                    </Field>
                  )}
                  {/*!params?.event_id &&
                    !item.planned &&
                    item.status === BOOKING_STATUSES.ACTIVE && (
                      <Field>
                        <Autocomplete id="event_id" />
                      </Field>
                    )*/}
                  <Field fullWidth>
                    <TextField
                      useDelayedKeystrokes={false}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          pl: 1,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color={capitalizeWords?.name ? "info" : "default"}
                              size="small"
                              title={
                                capitalizeWords?.name
                                  ? "Nagybetűs szavak bekapcsolva"
                                  : "Nagybetűs szavak kikapcsolva"
                              }
                              onClick={() => {
                                setCapitalizeWords({
                                  ...capitalizeWords,
                                  name: !capitalizeWords?.name,
                                });
                              }}
                            >
                              <TextFieldsIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      id="name"
                    />
                  </Field>
                  <Field xs={12}>
                    <TextField id="external_booking_number" />
                  </Field>
                  <Field half>
                    <TextField id="voucher" />
                  </Field>
                  <Field half>
                    <Autocomplete id="pay_location" />
                  </Field>
                  {/*<Field third>
                    <TextField id="table_numbers" />
                  </Field>*/}
                  <Field half>
                    <TextField id="email" required={false} />
                  </Field>
                  <Field half>
                    <TextField id="phone" />
                  </Field>
                  <Field third>
                    <CounterButton id="number_of_adults" fullWidth />
                  </Field>
                  <Field third>
                    <CounterButton id="number_of_kids" fullWidth />
                  </Field>
                  <Field third>
                    <CounterButton id="number_of_babies" fullWidth />
                  </Field>
                  <Field third>
                    <Price
                      mode={priceMode}
                      count={item.number_of_adults}
                      id="adult_price"
                      disabled={
                        !hasPermissions("booking.editprice") || disabled
                      }
                      helperText={
                        priceMode === "total"
                          ? "Össz árat kell megadni!"
                          : undefined
                      }
                    />
                  </Field>
                  <Field third>
                    <Price
                      mode={priceMode}
                      id="kid_price"
                      fullWidth
                      disabled={
                        !hasPermissions("booking.editprice") || disabled
                      }
                      helperText={
                        priceMode === "total"
                          ? "Össz árat kell megadni!"
                          : undefined
                      }
                    />
                  </Field>
                  {!!item.upgrade && (
                    <Field third>
                      <Price
                        id="upgrade_price"
                        fullWidth
                        disabled={
                          !hasPermissions("booking.editprice") || disabled
                        }
                      />
                    </Field>
                  )}
                  {/*<Field third>
                    <DateTimePicker id="check_in_at" />
                  </Field>*/}
                  <Field fullWidth>
                    <TextField
                      id="description"
                      multiline
                      sx={{ "& textarea": { paddingTop: 0, paddingBottom: 0 } }}
                    />
                  </Field>
                </FieldsContainer>
              </Grid>
              {!!event?.program?.has_options && (
                <Grid item sm={12} md={6}>
                  <FieldsContainer>
                    {event?.program?.has_options && (
                      <Field fullWidth>
                        <BookingOptionSelector
                          booking={item}
                          id="booking_options"
                        />
                      </Field>
                    )}
                  </FieldsContainer>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              pl: 2,
              pr: 4,
              gap: 2,
              borderTop: theme => `solid 1px ${theme.palette.divider}`,
              borderBottom: theme => `solid 1px ${theme.palette.divider}`,
              backgroundColor: theme =>
                item.paid
                  ? alpha(theme.palette.success.main, 0.1)
                  : alpha(theme.palette.warning.main, 0.1),
            }}
          >
            <Toolbar>
              <Checkbox id="paid" />
              {!item.paid && <MoneyOffIcon color="error" fontSize="small" />}
            </Toolbar>
            <Spacer right />
            <Typography variant="h6">
              {(item.number_of_adults || 0) +
                (item.number_of_kids || 0) +
                (item.number_of_babies || 0)}{" "}
              Fő
            </Typography>
            {!!item.number_of_babies && (
              <Typography variant="h6" color="success.main">
                {item.number_of_babies || 0} Baba
              </Typography>
            )}
            <Typography variant="h6">
              {I18n.numberToCurrency(getPrice(item).sum, {
                precision: 2,
                unit: "€",
                format: "%u %n",
                separator: ",",
                delimiter: " ",
                stripInsignificantZeros: true,
              })}
            </Typography>
          </DialogActions>
        </Form>
        <DialogActions sx={{ gap: 1 }}>
          {isUpdate && (
            <>
              {item.status === BOOKING_STATUSES.ACTIVE && (
                <Button
                  variant="outlined"
                  title="Visszamondás"
                  disabled={isDirty}
                  onClick={() => cancelBooking()}
                  loading={statusChanging}
                  color="error"
                />
              )}
              {item.status === BOOKING_STATUSES.CANCELED && (
                <Button
                  variant="outlined"
                  title="Újra aktiválás"
                  disabled={isDirty}
                  onClick={() => activateBooking()}
                  loading={statusChanging}
                  //color="error"
                />
              )}
              {!item.planned && (
                <AttachBookingButton
                  buttonType="button"
                  variant="outlined"
                  title="Áthelyezés"
                  booking={item}
                  onSelect={event => {
                    setAttributes({
                      event_id: event?.id,
                      event: event as any,
                      planned_datetime: null as any,
                      planned_event: null as any,
                      //event: event as any,
                      planned_event_id: null as any,
                      planned: false,
                      //status: EVENT_STATUSE.ACTIVE,
                    });
                  }}
                />
              )}
            </>
          )}
          {isUpdate && (
            <CreatedUpdatedBy
              item={item}
              sx={{
                p: 1,
                pl: 0,
                paddingTop: 0,
                paddingBottom: 0.5,
                marginRight: "auto",
              }}
              renderDialog={
                hasPermissions(["history.viewany", "history.viewown"])
                  ? props => <HistoryListDialog {...props} subtitle={title} />
                  : undefined
              }
            />
          )}
          <Button
            title={I18n.t("App.save")}
            disabled={!isDirty}
            onClick={() => save()}
            loading={saving}
          />
        </DialogActions>
      </BookingContext.Provider>
    </ParamsContext.Provider>
  );
}
