import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { VIEWS } from "common/components/CalendarPro";

export enum CalendarFontSizes {
  SMALL = "calFontSizeSmall",
  NORMAL = "calFontSizeNormal",
  LARGE = "calFontSizeLarge",
}

export type CalendarSettings = {
  view: VIEWS;
  show_indicators: boolean;
  show_full_events: boolean;
  show_closed_events: boolean;
  show_all_programs: boolean;
  always_show_remaining_spaces: boolean;
  font_size: CalendarFontSizes;
};
export type CateringSettings = {
  boatIds: number[];
  programIds: number[];
  detailsOpen?: boolean;
  show_all_programs: boolean;
};
export type AdvancedReportSettings = {
  boatIds: number[];
  programIds: number[];
  show_all_programs: boolean;
  show_filter: boolean;
  show_canceled: boolean;
  short_prices: boolean;
  chartType: "line" | "bar" | "pie";
  currency?: null | "HUF" | "USD";
  value:
    | "amount"
    | "count"
    | "guests"
    | "canceled_amount"
    | "canceled_count"
    | "canceled_guests";
  groupBy: "program" | "program_variant" | "boat" | "partner" | "partner_group";
};
export type StreetSalesSettings = {
  boatIds: number[];
  programIds: number[];
  show_all_programs: boolean;
};
export type KitchenSettings = {
  boatIds: number[];
  programIds: number[];
  show_all_programs: boolean;
};
export type SettingsState = {
  calendar: CalendarSettings;
  catering: CateringSettings;
  streetSales: StreetSalesSettings;
  kitchen: KitchenSettings;
  advancedReport: AdvancedReportSettings;
  // Add more settings keys here as needed
};
const initialState: SettingsState = {
  calendar: {
    view: VIEWS.WEEK,
    show_closed_events: true,
    show_full_events: true,
    show_indicators: true,
    show_all_programs: false,
    always_show_remaining_spaces: false,
    font_size: CalendarFontSizes.SMALL,
  },
  catering: {
    boatIds: [],
    programIds: [],
    show_all_programs: false,
  },
  kitchen: {
    boatIds: [],
    programIds: [],
    show_all_programs: false,
  },
  streetSales: {
    boatIds: [],
    programIds: [],
    show_all_programs: false,
  },
  advancedReport: {
    boatIds: [],
    programIds: [],
    show_all_programs: false,
    show_filter: false,
    chartType: "bar",
    value: "amount",
    groupBy: "program",
    show_canceled: true,
    short_prices: true,
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSettings: (
      state,
      action: PayloadAction<{ key: keyof SettingsState; settings: any }>
    ) => {
      state[action.payload.key] = action.payload.settings;
    },
  },
});

export const { updateSettings } = settingsSlice.actions;

export const selectSettingsByKey = (
  state: RootState,
  key: keyof SettingsState
) => state.settings[key];

export default settingsSlice.reducer;
