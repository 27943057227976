import {
  alpha,
  Box,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from "@mui/material";

import { Toolbar } from "common";
import { getLabel } from "helpers/dateHelper";
import useHeaderHeight from "helpers/useHeaderHeight";
import moment from "moment";
import { useUpgradeContext } from "./UpgradeProvider";
import UpgradesListItem from "./UpgradesListItem";

export default function UpgradesList() {
  const headerHeight = useHeaderHeight();
  const { upgradesData, booking } = useUpgradeContext();
  return (
    <Box>
      {Object.keys(upgradesData).map(day => {
        const times = upgradesData[day];
        return (
          <List key={day} disablePadding>
            <ListSubheader sx={{ top: 0, fontSize: "1rem" }}>
              <Toolbar sx={{ gap: 2 }}>
                {moment(day).format("L")}
                <Typography variant="body1">
                  {getLabel({ start: moment(day), end: moment(day) }).niceLabel}
                </Typography>
              </Toolbar>
            </ListSubheader>

            {Object.keys(times).map(time => {
              const items = times[time];
              const isSameTime =
                items[0].start_time === booking?.event?.start_time;
              return (
                <ListItem
                  divider
                  key={time}
                  sx={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 1,
                    backgroundColor: theme =>
                      isSameTime
                        ? alpha(theme.palette.info.main, 0.2)
                        : undefined,
                  }}
                >
                  <Toolbar sx={{ gap: 1 }}>
                    <Typography>{time}</Typography>
                    <Toolbar sx={{ gap: 2, flexWrap: "wrap" }}>
                      {items?.map((item, index) => {
                        return <UpgradesListItem item={item} key={index} />;
                      })}
                    </Toolbar>
                  </Toolbar>
                </ListItem>
              );
            })}
          </List>
        );
      })}
    </Box>
  );
}
