import {
  UseFormReturnType,
  Validator,
  globalMessageHandler,
  loadMultipleOptions,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { getSeatingLayoutService } from "features/seating-layouts/seatingLayoutApi";
import I18n from "i18n";
import { Params } from "react-router-dom";
import { ProgramItem, ProgramOptions, getProgramService } from "./programApi";

type RouteParams = {
  program_id?: string;
};

export interface UseProgramFormReturnType
  extends UseFormReturnType<ProgramItem, ProgramOptions> {
  params?: Readonly<
    Params<
      | "program_id"
      | "program_variant_id"
      | "booking_id"
      | "booking_option_id"
      | "program_option_id"
      | "event_id"
    >
  >;
}

export interface UseProgramFormProps {
  defaultValues?: Partial<ProgramItem>;
}

export default function useProgramForm({
  defaultValues,
}: UseProgramFormProps): UseProgramFormReturnType {
  const params = useParams<
    | "program_id"
    | "program_variant_id"
    | "booking_id"
    | "booking_option_id"
    | "program_option_id"
    | "event_id"
  >();
  const scope = undefined;
  const service = getProgramService(scope);

  const form = useForm<ProgramItem, ProgramOptions>({
    id: params.program_id,
    translationCategory: "Program",
    defaultValues,
    rules: {
      name: Validator.rule.string().required(),
      color: Validator.rule.string().required(),
      code: Validator.rule.string().required(),
      sku: Validator.rule.string().required(),
      duration: Validator.rule.any().required(),
    },
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: loadMultipleOptions({
      seating_layout_ids: () =>
        getSeatingLayoutService(undefined).loadAutocomplete(),
    }),
  });

  return { ...form, params };
}
