import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { PaletteMode } from "@mui/material";
import { LinkProps } from "@mui/material/Link";
import {
  ThemeOptions,
  createTheme,
  darken,
  keyframes,
  lighten,
} from "@mui/material/styles";
import { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
//@ts-ignore
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  /*if (href.toString().includes("://")) {
    return (
      <Link ref={ref} href={href.toString()} {...omit(other, "component")} />
    );
  }*/
  return <RouterLink ref={ref} to={href} {...other} />;
});

declare module "@mui/material/styles" {
  interface TypeBackground {
    default: string;
    paper: string;
    input: string;
    sheet: string;
  }
  interface Palette {
    logo: { primary: string; secondary: string };
  }

  interface PaletteOptions {
    logo: { primary: string; secondary: string };
    //customColor: SimplePaletteColorOptions;
  }
}

// Define keyframes for custom animations
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const blurEffect = keyframes`
  0% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0);
  }
`;

//logo colors
//brown #BD8F57
//black #1B223C
const getTheme = (mode: PaletteMode) =>
  ({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            primary: { main: "#5e2a50" },
            secondary: { main: "#FE6B40" },
            warning: { main: "#fbc02d" },
            logo: { primary: "#2d0b36", secondary: "#888888" },
            background: {
              input: "#EEEEF0",
              paper: "#FFFFFF",
              default: "#F7F8FB",
              sheet: "#fff9db",
            },
          }
        : {
            primary: { main: lighten("#5e2a50", 0.25) },
            secondary: { main: "#FE6B40" },
            warning: { main: "#fbc02d" },
            logo: { primary: "#2d0b36", secondary: "#d2d1d3" },
            background: {
              input: "#0f202a",
              paper: darken("#0f202a", 0.7),
              default: "#0f202a",
              sheet: "#2a2f1e",
            },
          }),
    },
    typography: {
      fontFamily,
      //lineHeight: 20,
      fontSize: 12,
      //htmlFontSize: 14,
    },
    shape: {
      borderRadius: 8,
    },
  } as ThemeOptions);

const fontFamily = ["Roboto", "Helvetica", "Arial", "sans-serif"].join(",");

export default function createAppTheme(mode: "light" | "dark") {
  const theme = createTheme(getTheme(mode));

  const themeWithComponents = createTheme({
    ...theme,
    components: {
      MuiCssBaseline: {
        styleOverrides: theme => `
        div.routeAnimation {
          flex:1;
          display: flex;
          flex-direction: column;
          max-width:100%;
        }
      `,
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          //InputLabelProps: { shrink: true },
          //size: "small",
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: "translate(14px, 10px) scale(1)",
          },
          shrink: {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: "8.5px 14px!important",
            "&::placeholder": {
              fontStyle: "italic",
            },
          },
          inputSizeSmall: {
            //fontSize: "0.875rem",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
      },
      /*MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& legend": {
              display: "none!important",
            },
            "& fieldset": {
              top: 0,
            },
            //backgroundColor: theme.palette.background.input,
          },
          input: {
            paddingTop: "10px",
            paddingBottom: "10px",
            minHeight: "20px",
            "&::placeholder": {
              fontStyle: "italic",
            },
          },
          inputSizeSmall: {
            //fontSize: "0.875rem",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              paddingTop: "2.5px",
              paddingBottom: "2.5px",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "0.86rem",
            position: "unset",
            transform: "unset",
          },
        },
      },*/
      MuiButton: {
        styleOverrides: {
          sizeMedium: {
            textTransform: "none",
            minHeight: 40,
            fontSize: "0.85rem",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: theme.palette?.info.main,
            "&:visited": {
              color: theme.palette?.info.main,
            },
          },
        },
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiDialog: {
        styleOverrides: {
          scrollPaper: {
            alignItems: "baseline",
          },
        },
        defaultProps: {
          disableEnforceFocus: true,
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          //@ts-ignore
          root: {
            //overflowY: "auto", // Enable vertical scrollbar
            overflowY: "scroll", // Enable vertical scrollbar overlay
            paddingRight: "17px", // Add padding to account for the scrollbar width
            boxSizing: "content-box", // Prevent padding from being included in the element's width
            "&::-webkit-scrollbar": {
              width: "10px", // Set the width of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888", // Set the color of the scrollbar thumb
              borderRadius: "10px", // Make the scrollbar thumb rounded
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555", // Set the color of the scrollbar thumb on hover
            },
            scrollbarWidth: "thin", // Firefox scrollbar width
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            animation: `${fadeIn} 1s ease-in-out`,
          },
        },
      },
    },
  });
  console.log(themeWithComponents);
  return themeWithComponents;
}
