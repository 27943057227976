import { Close as CloseIcon } from "@mui/icons-material";
import Refresh from "@mui/icons-material/Refresh";
import {
  alpha,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Toolbar } from "common";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingListItem from "./BookingListItem";
import { getKitchenData, KitchenItem } from "./kitchenApi";
import OrderItem from "./OrderItem";

type State = {
  item?: KitchenItem;
};
function KitchenBookingList() {
  const { start, boat_id } = useParams<"start" | "boat_id">();
  const location = useLocation();
  const _state = (location.state || {
    item: { bookings: [], program_ids: [], summary: [] },
  }) as State;
  const [state, setState] = useState<State>(_state);
  const navigate = useNavigate();
  const { resolveOption } = useDictionaries();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  async function loadKitchenData() {
    const { success, data } = await getKitchenData(
      start,
      start,
      boat_id ? [boat_id as any] : null,
      state.item?.program_ids || null
    );
    if (success) {
      setState({ item: data[0] });
    }
  }

  useEffect(() => {
    //if (!location.state) {
    loadKitchenData();
    //}
  }, [location.state]);

  const renderPrograms = useCallback(() => {
    return (
      <>
        {state?.item?.program_ids.map(program_id => {
          const program = resolveOption<ProgramItem>("programs", program_id);
          return (
            <Toolbar key={program_id} sx={{ gap: 1 }}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: "5px",
                  backgroundColor: program?.color,
                }}
              />
              <Typography>{program?.name}</Typography>
            </Toolbar>
          );
        })}
      </>
    );
  }, [state?.item?.program_ids]);

  const summary = state?.item?.summary;
  summary?.sort((a, b) => b.qty - a.qty);
  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
      open
      onClose={() => navigate("..")}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="h6">{`${moment(state?.item?.start_time).format(
            "L LT"
          )} - ${state?.item?.boat_name}`}</Typography>
          {renderPrograms()}
          <Box sx={{ flex: 1 }} />
          <IconButton
            color="inherit"
            onClick={() => loadKitchenData()}
            aria-label="refresh"
          >
            <Refresh />
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => navigate("..")}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 1,
            flexWrap: "wrap",
            p: 1,
            borderRadius: "6px",
            border: theme => `solid 1px ${alpha(theme.palette.info.main, 0.4)}`,
            backgroundColor: theme => alpha(theme.palette.info.main, 0.1),
          }}
        >
          <Typography variant="body1" align="center">
            Járat összes rendelése
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            {summary?.map((summary, index) => (
              <OrderItem
                key={index}
                name={summary.name}
                qty={summary.qty}
                type={summary.type}
              />
            ))}
          </Box>
        </Box>
        <List disablePadding dense>
          {state?.item?.bookings.map(booking => (
            <BookingListItem key={booking.id} booking={booking} />
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default KitchenBookingList;
