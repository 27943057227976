import { useState } from "react";

export type StorageType = "none" | "local" | "session";

function usePersistentState<T>(
  initialValue: T,
  key: string,
  storageType: StorageType = "none"
): [T, (value: T | ((val: T) => T)) => void] {
  // Function to parse stored value (returns initial value if parsing fails)
  const parseStoredValue = (storedValue: string | null, fallback: T): T => {
    if (storedValue === null || storedValue === undefined) {
      return fallback;
    }

    try {
      const parsed = JSON.parse(storedValue);
      return parsed;
    } catch {
      // If it's a scalar or invalid JSON, return the stored value as-is
      return storedValue as unknown as T;
    }
  };

  // Function to get the initial value from the storage
  const getStoredValue = (): T => {
    if (storageType === "local") {
      const storedValue = localStorage.getItem(key);
      const sv = parseStoredValue(storedValue, initialValue);
      return sv;
    } else if (storageType === "session") {
      const storedValue = sessionStorage.getItem(key);
      const sv = parseStoredValue(storedValue, initialValue);
      return sv;
    }
    return initialValue;
  };

  // Initialize the state with the stored value or fallback to the initialValue
  const [state, setState] = useState<T>(getStoredValue());
  // Custom setState function that also stores the value
  const customSetState = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(state) : value;

    setState(valueToStore);

    if (valueToStore === null || valueToStore === undefined) {
      // Remove the item from storage if the value is null or undefined
      if (storageType === "local") {
        localStorage.removeItem(key);
      } else if (storageType === "session") {
        sessionStorage.removeItem(key);
      }
    } else {
      // Store the value in the appropriate storage
      if (storageType === "local") {
        storeValue(localStorage, key, valueToStore);
      } else if (storageType === "session") {
        storeValue(sessionStorage, key, valueToStore);
      }
    }
  };

  // Helper function to store values, handling scalars and objects
  const storeValue = (storage: Storage, key: string, value: T) => {
    if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    ) {
      storage.setItem(key, String(value));
    } else {
      storage.setItem(key, JSON.stringify(value));
    }
  };

  return [state, customSetState];
}

export default usePersistentState;
