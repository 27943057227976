import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { Button, ButtonProps } from "common";
import I18n from "i18n";
import UpgradeDialog from "../UpgradeDialog";
import { useUpgradeContext } from "../UpgradeProvider";

type BookingIconButtonProps = {
  buttonType: "icon";
} & Omit<IconButtonProps, "onSelect">;

type UpgradeOpenButtonButtonProps = {
  buttonType: "button";
} & Omit<ButtonProps, "onSelect">;

export type UpgradeOpenButtonProps =
  | BookingIconButtonProps
  | UpgradeOpenButtonButtonProps;

const UpgradeOpenButton: React.FC<UpgradeOpenButtonProps> = ({
  buttonType,
  ...iconButtonProps
}) => {
  const { handleOpen } = useUpgradeContext();
  return (
    <>
      {buttonType === "icon" && (
        <Tooltip title={I18n.t("Booking.upgradeTitle")}>
          <IconButton onClick={handleOpen} {...iconButtonProps} />
        </Tooltip>
      )}
      {buttonType === "button" && (
        <Button onClick={handleOpen} {...(iconButtonProps as ButtonProps)} />
      )}
      <UpgradeDialog />
    </>
  );
};

export default UpgradeOpenButton;
