import WorkspacesIcon from "@mui/icons-material/Workspaces";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridModalEditColumn,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { SeatingLayoutItem } from "./seatingLayoutApi";
import SeatingLayoutForm from "./SeatingLayoutForm";
import useSeatingLayoutList from "./useSeatingLayoutList";
export interface SeatingLayoutListProps
  extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function SeatingLayoutList({
  showHeader = true,
  ...props
}: SeatingLayoutListProps) {
  const { params, scope, editUrl, ...service } = useSeatingLayoutList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<SeatingLayoutItem> = [
    {
      field: "name",
      headerName: I18n.t("SeatingLayout.attributes.name"),
      renderCell: params => (
        <DataGridModalEditColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
      flex: 1,
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<WorkspacesIcon />}
          title={I18n.t("SeatingLayout.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="seating-layouts"
        renderModalForm={props => <SeatingLayoutForm {...props} />}
        modalProps={{ maxWidth: "xl" }}
        {...props}
        //getHeaderName={field => I18n.t(`SeatingLayout.attributes.${field.replaceAll(".", "_")}`)}
        canCreate={hasPermissions("partner_group.create")}
        canDelete={hasPermissions([
          "partner_group.editany",
          "partner_group.editown",
        ])}
        canEdit={hasPermissions([
          "partner_group.editany",
          "partner_group.editown",
        ])}
        canDeleteRow={params => canEditItem("partner_group", params.row)}
        canEditRow={params => canEditItem("partner_group", params.row)}
        canBatchActions={hasPermissions([
          "partner_group.editany",
          "partner_group.editown",
        ])}
        canHandleTrash={hasPermissions([
          "partner_group.editany",
          "partner_group.editown",
        ])}
      />
    </>
  );
}
