//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  SeatingLayoutItem,
  getSeatingLayoutService,
} from "./seatingLayoutApi";

export default function useSeatingLayoutList() {
  const params = useParams();
  const scope = undefined;
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "seating-layouts/"
      : ""
  }`
  const service = getSeatingLayoutService();

  return { ...service, params, scope, editUrl };
}

