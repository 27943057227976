import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import api from "app/api";
import { globalMessageHandler } from "common";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ScheduleContext } from "../ScheduleTabs";

export default function GenerateButton() {
  const context = useContext(ScheduleContext);
  const params = useParams<"schedule_id">();
  const [loading, setLoading] = useState(false);
  async function generate() {
    globalMessageHandler.confirm(
      {
        message: context?.item?.has_event
          ? "Biztosan frissíted az eseményeket?"
          : "Biztosan legenerálod az eseményeket?",
      },
      async () => {
        setLoading(true);
        const { success, data } = await api.post(
          `/schedules/${params?.schedule_id}/generate`
        );
        setLoading(false);
        if (success) {
          context?.load();
          globalMessageHandler.snack({
            severity: "success",
            message: context?.item?.has_event
              ? `Sikeresen frissítve. ${data.count} esemény.`
              : `Sikeresen legenerálva. ${data.count} esemény.`,
          });
        }
      }
    );
  }
  return (
    <Tooltip
      title={
        context?.item?.has_event
          ? "Kapcsolódó események frissítése"
          : "Események generálása"
      }
    >
      <Box>
        <IconButton
          color="primary"
          onClick={generate}
          disabled={
            !!(
              (!context?.item?.is_modified && context?.item?.has_event) ||
              context?.isCreate ||
              context?.loading ||
              context?.isDirty
            )
          }
        >
          <Badge
            badgeContent={context?.item?.is_modified ? 1 : 0}
            color="warning"
            variant="dot"
          >
            {loading ? <CircularProgress size={16} /> : <EventRepeatIcon />}
          </Badge>
        </IconButton>
      </Box>
    </Tooltip>
  );
}
