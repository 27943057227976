import {
  globalMessageHandler,
  GridModalFormProps,
  useForm,
  UseFormReturnType,
  Validator,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import I18n from "i18n";
import { Params } from "react-router-dom";
import {
  getSeatingLayoutService,
  SeatingLayoutItem,
  SeatingLayoutOptions,
} from "./seatingLayoutApi";

export interface UseSeatingLayoutFormProps extends GridModalFormProps {}

type RouteParams = {
  partner_group_id?: string;
};

export interface UseSeatingLayoutFormReturnType
  extends UseFormReturnType<SeatingLayoutItem, SeatingLayoutOptions> {
  params?: Readonly<
    Params<
      "partner_group_id" | "partner_id" | "booking_id" | "booking_option_id"
    >
  >;
}

export default function useSeatingLayoutForm({
  id,
  afterSave,
  defaultValues,
}: UseSeatingLayoutFormProps): UseSeatingLayoutFormReturnType {
  const params = useParams<
    "partner_group_id" | "partner_id" | "booking_id" | "booking_option_id"
  >();
  const scope = undefined;
  const service = getSeatingLayoutService(scope);

  const form = useForm<SeatingLayoutItem, SeatingLayoutOptions>({
    id: params.partner_group_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "SeatingLayout",
    rules: {
      name: Validator.rule.string().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: undefined,
  });

  return { ...form, params };
}
