import useSettings from "features/settings/useSettings";
import { size } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import {
  getStreetSalesData,
  groupByStartTime,
  StreetSalesData,
} from "./streetSalesApi";

export default function useStreetSalesList() {
  const [range, setRange] = useState<
    { start?: Moment; end?: Moment } | undefined
  >({
    start: moment().startOf("day"),
    end: moment().endOf("day"),
  });

  const [settings, setSettings] = useSettings({ key: "streetSales" });
  const [loading, setLoading] = useState<boolean>(true);
  const loaded = useRef(false);
  const [streetSalesData, setStreetSalesData] = useState<StreetSalesData>({});

  const handleFilter = async (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null
  ) => {
    setLoading(true);
    const { success, data } = await getStreetSalesData(
      startDate,
      endDate,
      boatIds,
      programIds
    );
    loaded.current = true;
    if (success) {
      setStreetSalesData(groupByStartTime(data) || {});
    }
    setLoading(false);
  };
  function load() {
    return handleFilter(
      range?.start?.format("YYYY-MM-DD"),
      range?.end?.format("YYYY-MM-DD"),
      settings.boatIds,
      settings.programIds
    );
  }
  const isEmpty = loaded.current && size(streetSalesData) === 0;

  useEffect(() => {
    load();
  }, [range, settings.boatIds, settings.programIds]);

  return {
    streetSalesData,
    loading,
    load,
    handleFilter,
    setRange,
    range,
    loaded,
    isEmpty,
    setSettings,
  };
}
