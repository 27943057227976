export const generateColorPalette = (
  numColors: number,
  type: string = "hex"
): string[] => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const color = `hsl(${(i * 360) / numColors}, 70%, 50%)`;
    if (type === "hex") {
      colors.push(hslStringToHex(color));
    } else {
      colors.push(color);
    }
  }
  return colors;
};
function hslStringToHex(hsl: string): string {
  // Updated regex to allow decimal values for the H (Hue) component
  const hslValues = hsl.match(/hsl\(([\d.]+),\s*(\d+)%?,\s*(\d+)%?\)/);

  if (!hslValues) {
    throw new Error(`Invalid HSL string format [${hsl}]`);
  }

  const h = parseFloat(hslValues[1]); // Handle possible decimal values for hue
  const s = parseInt(hslValues[2], 10);
  const l = parseInt(hslValues[3], 10);

  // Normalize the values and do the conversion
  return hslToHex(h, s, l);
}
export function hslToHex(h: number, s: number, l: number): string {
  // Normalize the hue to 0-360, saturation and lightness to 0-100
  h = h % 360;
  s = s > 100 ? 100 : s < 0 ? 0 : s;
  l = l > 100 ? 100 : l < 0 ? 0 : l;

  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
  } else if (h >= 120 && h < 180) {
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    b = c;
  } else if (h >= 300 && h < 360) {
    r = c;
    b = x;
  }

  // Adjusting to 0-255 scale and adding the 'm' component
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  // Convert to HEX
  const toHex = (value: number) => value.toString(16).padStart(2, "0");

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
