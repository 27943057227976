import { ReactNode } from "react";
import useAdvancedReport, {
  AdvancedReportContext,
  UseAdvancedReportProps,
} from "./useAdvancedReport";

export interface AdvancedReportProviderProps extends UseAdvancedReportProps {
  children?: ReactNode;
}

const AdvancedReportProvider: React.FC<AdvancedReportProviderProps> = ({
  children,
  ...props
}) => {
  const context = useAdvancedReport(props);

  return (
    <AdvancedReportContext.Provider value={context}>
      {children}
    </AdvancedReportContext.Provider>
  );
};
export default AdvancedReportProvider;
