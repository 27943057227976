import { Box } from "@mui/material";
import { Toolbar } from "common";
import DateRangeSlider from "components/DateRangeSelector/DateRangeSlider";
import ProgramCheckboxSelector from "components/ProgramCheckboxSelector/ProgramCheckboxSelector";
import { useUpgradeContext } from "./UpgradeProvider";

export default function UpgradesFilter() {
  const { setRange, range, setSettings, settings } = useUpgradeContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        flex: 1,
        maxWidth: "100%",
        //position: "sticky",
        //top: 64,
      }}
    >
      <Toolbar sx={{ flex: 1, alignSelf: "stretch", justifyContent: "center" }}>
        <DateRangeSlider
          value={range}
          onChange={range => {
            setRange(range);
          }}
        />
      </Toolbar>
      <ProgramCheckboxSelector
        wrapperProps={{
          sx: {
            flex: 1,
            maxWidth: "90%",
          },
        }}
        value={settings.programIds || []}
        onChange={evt =>
          setSettings({
            programIds: (evt.target?.value ? evt.target?.value : []) as any,
          })
        }
        showMore={settings.show_all_programs}
        setShowMore={show_all => setSettings({ show_all_programs: show_all })}
      />
    </Box>
  );
}
