import { darken, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Toolbar } from "common";
import { BoatItem } from "features/boats/boatApi";
import useDictionaries from "features/dictionaries/useDictionaries";
import { CalendarEventItem, EventLimit } from "features/events/eventApi";
import { useEventCalculator } from "features/events/useEventCalculator";
import { ProgramItem } from "features/programs/programApi";
import { useCallback, useState } from "react";

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

function StreetSalesListItem({ item }: { item: CalendarEventItem }) {
  const [open, setOpen] = useState(false);
  const { resolveOption } = useDictionaries();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [keyword, setKeyword] = useState<string>();
  const [loading, setLoading] = useState(false);

  const program = resolveOption<ProgramItem>("programs", item.program_id);
  const boat = resolveOption<BoatItem>("boats", item.boat_id);
  const { getEventData, getBoatData } = useEventCalculator();
  const boatData = getBoatData(item);
  const data = getEventData(item, boatData.boatLimit);

  const renderLimit = useCallback(
    (limitSummary: EventLimit, index: number = -1) => {
      const color =
        limitSummary?.remaining_places > 0
          ? program?.color
          : theme.palette.grey[700];
      return (
        <Toolbar
          key={`${index}${limitSummary.name}`}
          sx={{
            minWidth: 20,
            height: 20,
            borderRadius: "5px",
            backgroundColor: color,
            color: theme.palette.getContrastText(color),
            overflow: "hidden",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              flex: 1,
              backgroundColor: darken(color, 0.2),
              color: theme.palette.getContrastText(color),
              pl: 0.5,
              pr: 0.5,
              alignSelf: "stretch",
              display: "flex",
              alignItems: "center",
              textDecoration:
                limitSummary?.remaining_places > 0 ? undefined : "line-through",
            }}
          >
            {limitSummary?.name}
          </Typography>
          <Typography
            sx={{
              pl: 0.5,
              pr: 0.5,
              fontWeight: 700,
            }}
          >
            {limitSummary?.remaining_places}
          </Typography>
        </Toolbar>
      );
    },
    [program, theme]
  );

  return (
    <Toolbar sx={{ gap: 1, flexWrap: "wrap" }}>
      {renderLimit({ ...data.eventLimit, name: program?.name } as any)}
      {data.limits?.map(renderLimit)}
    </Toolbar>
  );
}

export default StreetSalesListItem;
