import AutorenewIcon from "@mui/icons-material/PostAdd";
import { Button, ButtonProps, globalMessageHandler } from "common";
import { BoatItem } from "features/boats/boatApi";
import useDictionaries from "features/dictionaries/useDictionaries";
import { OptionItem } from "features/options/optionApi";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { LIMIT_TYPES } from "features/schedules/scheduleConstants";
import { useMemo } from "react";

export interface EventDefaultsButtonProps extends ButtonProps {
  onUpdate: (values: any) => void;
  program_ids: number[];
  boat_id: number;
}
export function EventDefaultsButton({
  onUpdate,
  program_ids,
  boat_id,
  ...buttonProps
}: EventDefaultsButtonProps) {
  const { dictionaries, resolveOptions, filterOptions, resolveOption } =
    useDictionaries();
  const { options, programs, variants } = useMemo(() => {
    const programs = resolveOptions("programs", program_ids);
    const variants = filterOptions<ProgramVariantItem>("variants", item =>
      program_ids?.includes(item.program_id)
    );
    const options = filterOptions<OptionItem>("options", item =>
      program_ids?.includes(item.program_id)
    );
    return {
      programs,
      options,
      variants,
    };
  }, [program_ids]);

  function handleClick() {
    globalMessageHandler.confirm(
      {
        title: "Biztos felülírod az esemény adatait?",
        message:
          "A Programtípusok, Limitek, Opciók, Asztaltárképek felülírásra kerülnek.",
      },
      () => {
        const data = getData();
        console.log({ data });
        onUpdate(data);
      }
    );
  }
  function getVariants() {
    return variants.map(variant => ({
      variant_id: variant.id,
      adult_price: 1,
      kid_price: 0,
    }));
  }

  function getLimits() {
    const boat = resolveOption<BoatItem>("boats", boat_id);
    const boatCapacity = boat?.capacity || 100;
    const boatLimit: any = {
      type: LIMIT_TYPES.BOAT,
      variant_ids: variants.map(variant => variant.id),
      number_of_places: boatCapacity,
      number_of_online_places: boatCapacity - 10,
      places_tolerance: Math.ceil(boatCapacity * 0.1),
      name: "Hajó",
    };
    let programCapacity = Math.round(boatCapacity / (programs.length || 1));
    const limits = programs.map(program => ({
      type: LIMIT_TYPES.PROGRAM,
      program_id: program.id,
      variant_ids: variants
        .filter((variant: any) => variant.program_id === program.id)
        .map((variant: any) => variant.id),
      number_of_places: programCapacity,
      number_of_online_places: programCapacity - 10,
      places_tolerance: Math.ceil(programCapacity * 0.1),
    }));
    if (limits.length === 1) {
      return [boatLimit];
    }
    return [boatLimit, ...limits];
  }

  function getOptions() {
    return options?.map(o => ({ option_id: o.id }));
  }

  function getSeatingLayouts() {
    const mappedSeatingLayouts = program_ids.map(programId => {
      const program = dictionaries.programs.find(
        program => program.id === programId
      );
      return {
        program_id: programId,
        seating_layout_ids: program?.seating_layout_ids || [],
      };
    });

    return mappedSeatingLayouts.filter(
      l => l.seating_layout_ids && l.seating_layout_ids.length > 0
    );
  }

  function getData() {
    return {
      variants: getVariants(),
      limits: getLimits(),
      options: getOptions(),
      seating_layouts: getSeatingLayouts(),
    };
  }
  return (
    <Button
      startIcon={<AutorenewIcon />}
      title="Kitölt"
      onClick={() => handleClick()}
      {...buttonProps}
    />
  );
}
