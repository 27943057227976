import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { clone } from "lodash";
import { Moment } from "moment";
import {
  Fragment,
  FunctionComponent,
  MutableRefObject,
  ReactNode,
} from "react";

import {
  CalendarCellItem,
  CalendarDateRangeItem,
  CalendarEventItem,
  CalendarHeaderItem,
  NAVIGATE,
  VIEWS,
} from "./calendarApi";
import CalendarCell, { CalendarCellProps } from "./CalendarCell";
import CalendarCellHeader, {
  CalendarCellHeaderProps,
} from "./CalendarCellHeader";

import { ArrowDropUp, ArrowRight } from "@mui/icons-material";
import { getAllBoatForWeek } from "./calendarHelper";
import { calendarStyle } from "./calendarStyle";
import CalendarToolbar, { CalendarToolbarProps } from "./CalendarToolbar";
import useCalendar from "./useCalendar";

const Container = styled(Box)<BoxProps>`
  flex: 1;
  display: flex;
  gap: ${props => props.theme.spacing(1)};
  max-height: 100vh;
  position: relative;
`;

export interface CellContainerProps extends BoxProps {
  view?: VIEWS;
}

export interface CellBackgroundProps extends BoxProps {
  isHoliday?: boolean;
  isOtherMonth?: boolean;
  isCurrentDay?: boolean;
  isSelected?: boolean;
  view?: VIEWS;
}

const CellBackground = styled(Box, {
  shouldForwardProp: (prop: any) =>
    ![
      "isHoliday",
      "isOtherMonth",
      "isCurrentDay",
      "isSelected",
      "view",
    ].includes(prop),
})<CellBackgroundProps>`
  flex: 1;
  border-radius: 5px;
  margin: 1px;
  display: flex;
  flex-direction: column;
`;

export type CalendarRefType<
  EventType extends CalendarEventItem = CalendarEventItem
> = {
  getNextCell: (
    date?: string,
    hasEvent?: boolean
  ) => CalendarCellProps<EventType> | undefined;
  getPrevCell: (
    date?: string,
    hasEvent?: boolean
  ) => CalendarCellProps<EventType> | undefined;
  refresh: () => Promise<any> | undefined | void;
};
export interface CalendarHeaderCellProps extends BoxProps, CalendarHeaderItem {
  view: VIEWS;
}
export interface CalendarProps<EventType extends CalendarEventItem> {
  events?: EventType[];
  loadEvents?: (
    range: CalendarDateRangeItem
  ) => Promise<any> | undefined | void;
  onCellPress?: (cell: CalendarCellItem<EventType>, view: VIEWS) => void;
  onEventPress?: (
    item: EventType,
    cell: CalendarCellItem<EventType>,
    view: VIEWS
  ) => void;
  onAddPress?: (
    item: EventType,
    cell: CalendarCellItem<EventType>,
    view: VIEWS
  ) => void;
  date?: Moment;
  view?: VIEWS;
  views?: VIEWS[];
  /** Trigger cell press on specified views. Deafult trigger only MONTH view */
  triggerCellPressOnViews?: VIEWS[];
  buttons?: any[] | ReactNode;
  customToolbar?: any;
  headerContent?: ReactNode;
  calendarTop?: ReactNode;
  loading?: boolean;
  showHeader?: boolean;
  selectedDate?: Moment;
  selectedRange?: CalendarDateRangeItem;
  containerStyle?: BoxProps["sx"];
  swipeableStyle?: BoxProps["style"];
  toolbarStyle?: BoxProps["sx"];
  navigateDelay?: number;
  ToolbarComponent?: FunctionComponent<CalendarToolbarProps>;
  renderHeaderCell?: (props: CalendarHeaderCellProps) => ReactNode;
  CellHeaderComponent?: FunctionComponent<CalendarCellHeaderProps>;
  CellComponent?: FunctionComponent<CalendarCellProps<EventType>>;
  showRefreshButton?: boolean;
  emptyCellPressEnabled?: boolean;
  sortFunction?: (a: any, b: any) => number;
  dayNameShortFunc?: (date: Moment) => string;
  isSelectedFunc?: (item: EventType) => boolean;
  calRef?: MutableRefObject<CalendarRefType<EventType> | undefined>;
  autoLoad?: boolean;
}
export default function CalendarPro<EventType extends CalendarEventItem>({
  events,
  loadEvents,
  buttons,
  customToolbar,
  loading,
  onCellPress,
  showHeader = true,
  emptyCellPressEnabled = false,
  triggerCellPressOnViews = [VIEWS.MONTH, VIEWS.WEEK],
  containerStyle,
  selectedRange,
  ToolbarComponent = CalendarToolbar,
  renderHeaderCell,
  CellComponent = CalendarCell,
  CellHeaderComponent = CalendarCellHeader,
  showRefreshButton,
  swipeableStyle,
  toolbarStyle,
  headerContent,
  calRef,
  calendarTop,
  onEventPress,
  isSelectedFunc,
  onAddPress,
  ...calProps
}: CalendarProps<EventType>) {
  const {
    view,
    date,
    newDate,
    cal,
    newView,
    views,
    handleNavigate,
    handleView,
    refresh,
  } = useCalendar<EventType>({ events, loadEvents, ...calProps });

  const gridStyle = {
    gridTemplateColumns: `repeat(${cal.layout?.columns}, 1fr)`,
    //overflowX: "hidden",
    //rowGap: "8px",
    //gridTemplateRows: `auto repeat(${cal.layout.rows}, 1fr)`
  };

  //Ref
  if (calRef) {
    calRef.current = {
      getNextCell: (date, hasEvent = false) => {
        if (!date) {
          return;
        }
        let cell = cal.data?.find(
          d => date && d.date > date && (!hasEvent || d.events.length > 0)
        );
        if (!cell) {
          cell = cal.data?.find(d => !hasEvent || d.events.length > 0);
        }
        return cell;
      },
      getPrevCell: (date, hasEvent = false) => {
        if (!date) {
          return;
        }
        let cell = clone(cal.data)
          ?.reverse()
          .find(
            d => date && d.date < date && (!hasEvent || d.events.length > 0)
          );
        if (!cell) {
          cell = clone(cal.data)
            ?.reverse()
            .find(d => !hasEvent || d.events.length > 0);
        }
        return cell;
      },
      refresh,
    };
  }
  const getLabel = (d: Moment) => {
    if (!d) return "Select Date Range";
    if (view === VIEWS.DAY) return d.format("YYYY MMMM Do dddd");
    if (view === VIEWS.WEEK) {
      return `${d.format("Do dddd")}`;
    }
    if (view === VIEWS.AGENDA) {
      return `${d.format("YYYY MMMM Do dddd")}`;
    }
    if (view === VIEWS.MONTH) return `${d.format("Do dddd")}`;
  };
  return (
    <Container sx={containerStyle}>
      {showHeader && (
        <ToolbarComponent
          label={cal.getLabel(newDate)}
          date={newDate}
          view={newView}
          views={views}
          onView={handleView}
          onNavigate={handleNavigate}
          buttons={buttons}
          customToolbar={customToolbar}
          loading={loading}
          refresh={refresh}
          showRefreshButton={showRefreshButton}
          headerContent={headerContent}
          sx={toolbarStyle}
        />
      )}
      {calendarTop}
      <TableContainer sx={calendarStyle}>
        <Table padding="none" stickyHeader>
          {cal.data2.map((week, weekIndex) => {
            const allBoats = getAllBoatForWeek(week);
            return (
              <Fragment key={weekIndex}>
                <TableHead>
                  <TableRow>
                    <TableCell className="cal-TimeCell">
                      {view !== VIEWS.MONTH && (
                        <IconButton
                          size="small"
                          color="inherit"
                          onClick={() => {
                            if ([VIEWS.WEEK].includes(view)) {
                              handleView(VIEWS.MONTH);
                            }
                            if (view === VIEWS.DAY) {
                              handleView(VIEWS.WEEK);
                            }
                          }}
                        >
                          <ArrowDropUp />
                        </IconButton>
                      )}
                      {view === VIEWS.MONTH && (
                        <IconButton
                          size="small"
                          color="inherit"
                          onClick={() => {
                            handleNavigate(
                              NAVIGATE.DATE,
                              week.firstDayofWeek,
                              true,
                              VIEWS.WEEK
                            );
                          }}
                        >
                          <ArrowRight />
                        </IconButton>
                      )}
                    </TableCell>
                    {week.days.map((weekDay, weekDayIndex) => (
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          "&:hover": { textDecoration: "underline" },
                        }}
                        key={weekDayIndex}
                        onClick={() => {
                          handleNavigate(
                            NAVIGATE.DATE,
                            weekDay,
                            true,
                            VIEWS.DAY
                          );
                        }}
                      >
                        <span>{getLabel(weekDay)}</span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {[VIEWS.DAY, VIEWS.AGENDA].includes(view) && (
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="cal-TimeCell"
                        sx={{ position: "initial" }}
                      ></TableCell>
                      <TableCell sx={{ position: "initial" }}>
                        <Box
                          sx={{
                            flex: 1,
                            gap: 0.5,
                            display: "flex",
                            flexDirection: "row",
                            //alignItems: "flex-end",
                            //justifyContent: "space-between",
                            //backgroundColor: "green",
                          }}
                        >
                          {allBoats.map((boat, boatIndex) => (
                            <Box
                              key={boat.id}
                              sx={{
                                flex: 1,
                                gap: 0.5,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {boat.name}
                            </Box>
                          ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {week.startTimes.map((startTime, startTimeIdex) => {
                    return (
                      <TableRow key={`${weekIndex}-${startTimeIdex}`}>
                        <TableCell className="cal-TimeCell">
                          {startTime.time}
                        </TableCell>
                        {startTime.days.map((day, dayIndex) => {
                          const d = cal.getDayParams2(day.day);
                          let dayClassNames: string[] = ["day"];
                          if (d.isHoliday) {
                            dayClassNames.push("isHoliday");
                          }
                          if (d.isSelected) {
                            dayClassNames.push("isSelected");
                          }
                          if (d.isCurrentDay) {
                            dayClassNames.push("isCurrentDay");
                          }
                          if (d.isOtherMonth && view === VIEWS.MONTH) {
                            dayClassNames.push("isOtherMonth");
                          }
                          return (
                            <TableCell
                              key={`${weekIndex}-${startTimeIdex}-${dayIndex}`}
                              className={dayClassNames.join(" ")}
                            >
                              <Box className="background">
                                <CellComponent
                                  {...(d as CalendarCellItem<EventType>)}
                                  events={day.events as any}
                                  view={view}
                                  onPress={onEventPress}
                                  onAddPress={onAddPress}
                                  isSelectedFunc={isSelectedFunc}
                                  allBoats={allBoats}
                                />
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Fragment>
            );
          })}
        </Table>
      </TableContainer>
    </Container>
  );
}
