import api from "app/api";
import { globalMessageHandler } from "common";
import useSettings from "features/settings/useSettings";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";

export type AdvancedReportData = {
  amount: number;
  boat: string;
  canceled_amount: number;
  canceled_count: number;
  canceled_guests: number;
  count: number;
  email: string;
  guests: number;
  name: string;
  partner: string;
  partner_group: string;
  program: string;
  program_variant: string;
  t: string; // This represents a date, but in the example, it's a string
};

export type AdvancedReportDataFilterType = {
  from: string; //date string like "2024-01-01"
  to: string; //date string like "2024-01-01"
  boatIds?: number[];
  programIds?: number[];
  currency?: null | "EUR" | "HUF";
};

export type AdvancedReportDataContextType = {
  data: AdvancedReportData[];
  filter: AdvancedReportDataFilterType;
  setFilter: (filter: AdvancedReportDataFilterType) => void;
  load: () => Promise<void>;
  loading: boolean;
};

export const AdvancedReportDataContext =
  createContext<AdvancedReportDataContextType | null>(null);

export default function useAdvancedReportData() {
  const [filter, setFilter] = useState<AdvancedReportDataFilterType>({
    from: moment().startOf("week").format("YYYY-MM-DD"),
    to: moment().endOf("week").format("YYYY-MM-DD"),
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AdvancedReportData[]>([]);
  const [settings] = useSettings({ key: "advancedReport" });

  async function load() {
    //api is an axios instance
    setLoading(true);
    const { success, data, error } = await api.get("reports/advanced", {
      params: { ...filter, currency: settings.currency },
    });
    setLoading(false);
    if (success) {
      setData(data);
    } else {
      globalMessageHandler.alert({
        severity: "error",
        message: typeof error === "string" ? error : JSON.stringify(error),
      });
    }
  }

  useEffect(() => {
    load();
  }, [
    filter.programIds,
    filter.boatIds,
    filter.from,
    filter.to,
    settings.currency,
  ]);
  return { data, filter, setFilter, load, loading };
}

export function useAdvancedReportDataContext() {
  const context = useContext(AdvancedReportDataContext);
  if (!context) {
    throw Error(
      "useAdvancedReportDataContext cannot be used outside of AdvancedReportDataProvider."
    );
  }
  return context;
}
