import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ChevronLeft";
import ArrowForwardIcon from "@mui/icons-material/ChevronRight";
import { Box, IconButton, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import React, { useMemo } from "react";

interface DateRangePickerProps {
  value?: { start?: Moment; end?: Moment };
  onChange: (value?: { start?: Moment; end?: Moment }) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
}) => {
  const currentStartDate =
    value?.start?.startOf("day") || moment().startOf("day");

  const selectedRange = useMemo(() => {
    const start =
      value?.start?.clone().startOf("day") || moment().startOf("day");
    const end = value?.end?.clone().endOf("day") || moment().endOf("day");
    const diff = end.diff(start, "days");
    return diff;
  }, [value]);

  const getNext7Days = (startDate: Moment): Moment[] => {
    return Array.from({ length: 7 }, (_, i) =>
      startDate.clone().add(i, "days")
    );
  };

  const displayedDates = useMemo(() => {
    return getNext7Days(currentStartDate);
  }, [currentStartDate, value]);

  const handleScroll = (direction: "left" | "right" | "start") => {
    let newStartDate = currentStartDate
      .clone()
      .add(
        direction === "start"
          ? -1000
          : direction === "right"
          ? selectedRange + 1
          : -selectedRange - 1,
        "days"
      );

    // Ha az új kezdődátum a múltba esne, akkor legyen a mai nap a kezdőnap
    if (newStartDate.isBefore(moment().startOf("day"))) {
      newStartDate = moment().startOf("day");
    }

    updateSelection(newStartDate, selectedRange);
  };

  const handleDateClick = (index: number) => {
    const rangeSize = index;
    updateSelection(displayedDates[0], rangeSize); // Start from the first date
  };

  const updateSelection = (start: Moment, rangeSize: number) => {
    const end = start.clone().add(rangeSize, "days").endOf("day");

    onChange({ start, end });
  };

  const isDateSelected = (date: Moment) => {
    const { start, end } = value || {};
    return (
      start &&
      end &&
      date.isSameOrAfter(start, "day") &&
      date.isSameOrBefore(end, "day")
    );
  };

  const isWeekend = (date: Moment) => {
    return date.day() === 6 || date.day() === 0; // 6 = Saturday, 0 = Sunday
  };
  const isStart = value?.start?.isSame(moment(), "day");
  return (
    <Box display="flex" alignItems="center">
      <IconButton onClick={() => handleScroll("start")} disabled={isStart}>
        <KeyboardDoubleArrowLeft />
      </IconButton>
      <IconButton onClick={() => handleScroll("left")} disabled={isStart}>
        <ArrowBackIcon />
      </IconButton>
      <Box sx={{ display: "flex" }}>
        {displayedDates.map((date, index) => (
          <Box
            key={index}
            onClick={() => handleDateClick(index)}
            sx={{
              borderTopLeftRadius: index === 0 ? 8 : undefined,
              borderBottomLeftRadius: index === 0 ? 8 : undefined,
              borderTopRightRadius:
                index === displayedDates.length - 1 ? 8 : undefined,
              borderBottomRightRadius:
                index === displayedDates.length - 1 ? 8 : undefined,
              px: 1,
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: isDateSelected(date)
                ? "primary.main"
                : "transparent",
              pt: 0.5,
              border: theme => `solid 1px ${theme.palette.divider}`,
              borderLeftColor: "divider",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                p: 0,
                display: "block",
                lineHeight: "auto",
                fontWeight: isDateSelected(date) ? "bold" : "normal",
                color: isDateSelected(date)
                  ? "white"
                  : isWeekend(date)
                  ? "red"
                  : "text.primary",
              }}
            >
              {date.format("DD")}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                p: 0,
                mt: "-4px",
                display: "block",
                fontWeight: isDateSelected(date) ? "bold" : "normal",
                color: isDateSelected(date)
                  ? "white"
                  : isWeekend(date)
                  ? "red"
                  : "text.secondary",
              }}
            >
              {date.format("dd").slice(0, 3)}
            </Typography>
          </Box>
        ))}
      </Box>
      <IconButton onClick={() => handleScroll("right")}>
        <ArrowForwardIcon />
      </IconButton>
      <Box sx={{ width: "36px" }} />
    </Box>
  );
};

export default DateRangePicker;
