import {
  globalMessageHandler,
  GridModalFormProps,
  useForm,
  UseFormReturnType,
  Validator,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import I18n from "i18n";
import { Params } from "react-router-dom";
import { BoatItem, BoatOptions, getBoatService } from "./boatApi";

export interface UseBoatFormProps extends GridModalFormProps {}

type RouteParams = {
  boat_id?: string;
};

export interface UseBoatFormReturnType
  extends UseFormReturnType<BoatItem, BoatOptions> {
  params?: Readonly<
    Params<
      | "boat_id"
      | "schedule_id"
      | "event_id"
      | "booking_id"
      | "booking_option_id"
    >
  >;
}

export default function useBoatForm({
  id,
  afterSave,
  defaultValues,
}: UseBoatFormProps): UseBoatFormReturnType {
  const params = useParams<
    "boat_id" | "schedule_id" | "event_id" | "booking_id" | "booking_option_id"
  >();
  const scope = undefined;
  const service = getBoatService(scope);

  const form = useForm<BoatItem, BoatOptions>({
    id: params.boat_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "Boat",
    rules: {
      name: Validator.rule.string().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: undefined,
  });

  return { ...form, params };
}
