import { pick } from "lodash";
import { useState } from "react";
import { GridState } from "./types";

function getSessionStorage(id: string) {
  function getState(): any {
    const json = sessionStorage.getItem(`grid.${id}`);
    if (json) {
      return JSON.parse(json);
    }
    return;
  }

  function setState(item?: any) {
    if (item) {
      sessionStorage.setItem(`grid.${id}`, JSON.stringify(item));
    }
  }
  return {
    getState,
    setState,
  };
}
function getLocalStorage(id: string) {
  function getState(): any {
    const json = localStorage.getItem(`grid.${id}`);
    if (json) {
      return JSON.parse(json);
    }
    return;
  }

  function setState(item?: any) {
    if (item) {
      localStorage.setItem(`grid.${id}`, JSON.stringify(item));
    }
  }
  return {
    getState,
    setState,
  };
}

const defaultGridState: GridState = {
  data: [],
  current_page: 1,
  per_page: 10,
  from: 0,
  to: 0,
  total: 0,
  sorting: [],
  keyword: "",
  loading: false,
  selectedIds: [],
  hidden_columns: [],
  trash: false,
};
const sessionKeys = ["current_page", "per_page", "keyword", "sorting"];
const localKeys = ["hidden_columns"];
export type UseDataGridStateReturnType = [
  GridState,
  (state: Partial<GridState>) => void
];

export default function useDataGridState({
  id,
  defaultState,
}: {
  id: string;
  defaultState?: Partial<GridState>;
}): UseDataGridStateReturnType {
  const sessionStorage = getSessionStorage(id);
  const localStorage = getLocalStorage(id);

  const [state, _setState] = useState<GridState>({
    ...defaultGridState,
    ...defaultState,
    ...sessionStorage.getState(),
    ...localStorage.getState(),
  });
  function setState(_state: Partial<GridState>) {
    const newState = { ...state, ..._state };
    sessionStorage.setState(pick(newState, sessionKeys));
    localStorage.setState(pick(newState, localKeys));
    return _setState(newState);
  }

  return [state, setState];
}
