//import { useGrid } from "common";
import { globalMessageHandler } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import { useUrlQueryState } from "helpers/useUrlQueryState";
import { useMemo, useState } from "react";
import { cancelEventsByIds, getEventService } from "./eventApi";
import { EVENT_STATUSES } from "./eventConstants";
export type EventFilter = {
  programIds?: number[];
  boatIds?: number[];
  status?: EVENT_STATUSES[];
  types?: Array<"scheduled" | "planned">;
  range?: { start?: string; end?: string };
  scheduleId?: number;
};
export default function useEventList(id: string) {
  const params = useParams<"boat_id" | "program_id" | "schedule_id">();
  const [filter, onChange] = useUrlQueryState<EventFilter>({
    status: [EVENT_STATUSES.ACTIVE],
  });
  console.log({ filter });
  const [showMore, setShowMore] = useState<boolean>();
  const listId = `events_${id}`;
  //const [filter, onChange] = useFilter<EventFilter>();
  console.log({ listId });

  const scope = {
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
  };
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0 ? "events/" : ""
  }`;
  const service = getEventService({
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
  });

  const gridFilter = useMemo(() => {
    let f: any = [
      {
        columnField: "event.status",
        operatorValue: "in",
        value: filter?.status,
      },
    ];

    if (filter?.range?.start && filter?.range?.end) {
      f.push({
        columnField: "event.start_time",
        operatorValue: "between",
        value: [filter?.range?.start, `${filter?.range?.end} 23:59:59`],
      });
    }

    if (filter?.programIds && filter?.programIds.length > 0) {
      f.push({
        columnField: "event.program_id",
        operatorValue: "in",
        value: filter?.programIds,
      });
    }
    if (filter?.boatIds && filter?.boatIds.length > 0) {
      f.push({
        columnField: "event.boat_id",
        operatorValue: "in",
        value: filter?.boatIds,
      });
    }
    if (filter?.scheduleId) {
      f.push({
        columnField: "event.schedule_id",
        operatorValue: "is",
        value: filter?.scheduleId,
      });
    }
    return f;
  }, [filter]);

  async function cancelEventsByIdAsync(eventIds: any[], load: () => void) {
    let event_id: any = 1;
    // setGenerating(true);
    globalMessageHandler.confirm(
      {
        message: `Biztosan visszavonsz ${eventIds?.length || 0} eseményt?`,
      },
      async () => {
        const { success, data } = await cancelEventsByIds(eventIds);

        //setGenerating(false);
        if (success) {
          globalMessageHandler.snack({
            severity: "success",
            message: `Sikeresen visszavonva ${data.count} esemény`,
          });
          load();
        }
      }
    );
  }

  return {
    ...service,
    params,
    scope,
    editUrl,
    filter,
    gridFilter,
    onChange,
    showMore,
    setShowMore,
    cancelEventsByIdAsync,
  };
}
