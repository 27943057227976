import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  alpha,
  Avatar,
  Box,
  Button,
  ButtonProps,
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import { Toolbar } from "common";
import { VIEWS } from "common/components/CalendarPro/calendarApi";
import I18n from "i18n";
import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";

interface DateRangeSelectorProps
  extends Omit<ButtonProps, "onChange" | "value"> {
  value?: Moment;
  view: VIEWS;
  onChange: (
    startOf: Moment,
    period: VIEWS.DAY | VIEWS.WEEK | VIEWS.MONTH
  ) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  value,
  view,
  onChange,
  ...buttonProps
}) => {
  const [open, setOpen] = useState(false);
  const [displayDate, setDisplayDate] = useState<Moment>(moment());
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelect = (
    date: Moment,
    period: VIEWS.DAY | VIEWS.WEEK | VIEWS.MONTH
  ) => {
    const startOfPeriod =
      period === VIEWS.DAY
        ? date
        : period === VIEWS.WEEK
        ? date.clone().startOf("isoWeek")
        : date.clone().startOf("month");

    onChange(startOfPeriod, period);
    handleClose();
  };

  const getLabel = () => {
    if (!value) return I18n.t("Calendar.selectDateRange");
    if (view === VIEWS.DAY) return value.format("YYYY MMMM Do dddd");
    if ([VIEWS.WEEK, VIEWS.AGENDA].includes(view)) {
      const startOfWeek = value.clone().startOf("isoWeek");
      const endOfWeek = value.clone().endOf("isoWeek");
      return `${startOfWeek.format("YYYY MMMM D")} - ${endOfWeek.format("Do")}`;
    }
    if (view === VIEWS.MONTH) return value.format("YYYY MMMM");
  };

  const handlePrev = () => {
    setDisplayDate(prev => prev.clone().subtract(3, "months"));
  };

  const handleNext = () => {
    setDisplayDate(prev => prev.clone().add(3, "months"));
  };

  const renderDays = (monthDate: Moment, color?: ButtonProps["color"]) => {
    const startOfMonth = monthDate.clone().startOf("month");
    const endOfMonth = monthDate.clone().endOf("month");
    const startOfWeek = startOfMonth.clone().startOf("isoWeek");
    const endOfWeek = endOfMonth.clone().endOf("isoWeek");

    const days: Moment[] = [];
    for (
      let day = startOfWeek.clone();
      day.isBefore(endOfWeek);
      day.add(1, "day")
    ) {
      days.push(day.clone());
    }

    const weeks: Moment[][] = [];
    let currentWeek: Moment[] = [];

    days.forEach(day => {
      currentWeek.push(day.clone());
      if (currentWeek.length === 7) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });

    if (currentWeek.length) {
      weeks.push(currentWeek);
    }

    return (
      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(8, 1fr)" }}>
        {weeks.map((week, index) => (
          <React.Fragment key={index}>
            <Button
              color={color}
              variant="contained"
              size="small"
              onClick={() =>
                handleSelect(week[0].clone().startOf("isoWeek"), VIEWS.WEEK)
              }
              sx={{
                gridColumn: "span 1",
                minWidth: "28px",
                minHeight: "28px",
                padding: 0,
                borderRadius: "50%",
                fontSize: "0.70rem",
                margin: "2px",
              }}
            >
              {week[0].isoWeek()}
            </Button>
            {week.map(day => (
              <Box key={day.format("YYYY-MM-DD")} sx={{ textAlign: "center" }}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: theme =>
                      day.clone().startOf("day").isSame(moment().startOf("day"))
                        ? alpha(theme.palette.info.main, 0.2)
                        : "background.paper",
                    color: "text.primary",
                    cursor: "pointer",
                    margin: "2px",
                    fontSize: "0.8rem",
                    "&:hover": {
                      backgroundColor: theme => theme.palette.primary.main,
                      color: theme =>
                        theme.palette.getContrastText(
                          theme.palette.primary.main
                        ),
                    },
                  }}
                  onClick={() => handleSelect(day, VIEWS.DAY)}
                >
                  {day.date()}
                </Avatar>
              </Box>
            ))}
          </React.Fragment>
        ))}
      </Box>
    );
  };

  const renderMonth = (monthOffset: number, color?: ButtonProps["color"]) => {
    const monthDate = displayDate.clone().add(monthOffset, "months");
    return (
      <Box
        key={monthOffset}
        sx={{
          display: "flex",
          flexDirection: "column",
          m: 1,
        }}
      >
        <Toolbar>
          <Typography sx={{ fontWeight: 500, ml: 0.75 }}>
            {I18n.t("Calendar.week")}
          </Typography>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Button
              color={color}
              onClick={() =>
                handleSelect(monthDate.clone().startOf("month"), VIEWS.MONTH)
              }
              variant="text"
            >
              {monthDate.format("YYYY MMMM")}
            </Button>
          </Box>
        </Toolbar>
        {renderDays(monthDate, color)}
        <Box sx={{ flex: 1 }} />
      </Box>
    );
  };
  const theme = useTheme();
  return (
    <Box ref={anchorRef}>
      <Button
        variant="text"
        color={theme.palette.mode === "dark" ? "inherit" : undefined}
        onClick={handleOpen}
        {...buttonProps}
      >
        {getLabel()}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        sx={{ zIndex: 1500 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ p: 2 }}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: theme => `solid 1px ${theme.palette.divider}`,
                    pb: 1,
                  }}
                >
                  <IconButton onClick={handlePrev} color={buttonProps.color}>
                    <ArrowBack />
                  </IconButton>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontWeight: 500 }}>
                      {I18n.t("Calendar.selectDayWeekMonth")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {I18n.t("Calendar.calendarViewInfo")}
                    </Typography>
                  </Box>
                  <IconButton onClick={handleNext} color={buttonProps.color}>
                    <ArrowForward />
                  </IconButton>
                </Box>
                <Box display="flex" justifyContent="center">
                  {renderMonth(0, buttonProps.color)}
                  {renderMonth(1, buttonProps.color)}
                  {renderMonth(2, buttonProps.color)}
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

export default DateRangeSelector;
