import { lighten } from "@mui/material";
import { ChartData } from "chart.js";
import { generateColorPalette } from "helpers/colorUtils";
import moment from "moment";

export type AdvancedReportData = {
  amount: number; //summable
  boat: string;
  canceled_amount: number; //summable
  canceled_count: number; //summable
  canceled_guests: number; //summable
  count: number; //summable
  email: string;
  guests: number; //summable
  name: string;
  partner: string;
  partner_group: string;
  program: string;
  program_variant: string;
  color?: string;
  t: string; // This represents a date, but in the example, it's a string
};

export type GroupedReportData = {
  label: string;
  amount: number;
  canceled_amount: number;
  canceled_count: number;
  canceled_guests: number;
  count: number;
  guests: number;
  color: string;
};

type Dataset = {
  label: string;
  data: number[];
  dataCanceled: number[];
  total: number;
  canceledTotal: number;
  backgroundColor?: string;
  borderColor?: string;
  fill?: boolean;
};

export default function groupReportData(
  data: AdvancedReportData[],
  groupBy: keyof AdvancedReportData,
  startDate: string | null = null,
  endDate: string | null = null,
  value: keyof AdvancedReportData = "amount",
  show_canceled?: boolean
  //timeGrouping: "day" | "month" | "year" = "day"
): ChartData {
  const labels: string[] = [];
  const datasets: { [key: string]: Dataset } = {};
  const today = new Date();
  const diffInDays = moment(endDate).diff(moment(startDate), "days");
  console.log(diffInDays);
  let timeGrouping = "day";
  if (diffInDays > 400) {
    timeGrouping = "year";
  } else if (diffInDays > 40) {
    timeGrouping = "month";
  }
  // Generate all dates between start and end
  if (startDate && endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    let date = new Date(start);

    while (date <= end) {
      if (timeGrouping === "day") {
        labels.push(date.toISOString().split("T")[0]);
      } else if (timeGrouping === "month") {
        labels.push(date.toISOString().split("T")[0].slice(0, 7)); // YYYY-MM
      } else if (timeGrouping === "year") {
        labels.push(date.getFullYear().toString());
      }
      date.setDate(
        date.getDate() +
          (timeGrouping === "month" ? 30 : timeGrouping === "year" ? 365 : 1)
      );
    }
  } else {
    // Collect all unique dates for labels
    data.forEach(entry => {
      const label = entry.t;
      if (!labels.includes(label) && new Date(label) <= today) {
        labels.push(label);
      }
    });
  }

  // Sort the labels to ensure they are in chronological order
  labels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  // Create groups and initialize datasets
  const groups: any[] = [];
  const totals: { [key: string]: number } = {};
  const canceledTotals: { [key: string]: number } = {};
  const groupColorMap: { [key: string]: string } = {};
  const colorCount: { [key: string]: number } = {};

  //Sort
  if (groupBy === "program_variant" || groupBy === "program") {
    data.sort((a: AdvancedReportData, b: AdvancedReportData) => {
      return a.program > b.program ? 1 : a.program < b.program ? -1 : 0;
    });
  }

  data.forEach(entry => {
    const groupByValue: any = entry[groupBy];
    if (!groups.includes(groupByValue)) {
      groups.push(groupByValue);
      totals[groupByValue] = 0;
      canceledTotals[groupByValue] = 0;
      if (entry.color && !groupColorMap[groupByValue]) {
        if (!colorCount[entry.color]) {
          colorCount[entry.color] = 1;
        } else {
          colorCount[entry.color] += 1;
        }

        if (colorCount[entry.color] > 1) {
          groupColorMap[groupByValue] = lighten(
            entry.color,
            colorCount[entry.color] * 0.1
          );
        } else {
          groupColorMap[groupByValue] = entry.color;
        }
      }
    }
  });

  // Generate colors
  const colors = generateColorPalette(groups.length, "hex");
  const colorMap: { [key: string]: string } = {};

  groups.forEach((group, index) => {
    if (groupBy === "program_variant" && groupColorMap[group]) {
      colorMap[group] = groupColorMap[group];
    } else if (groupBy === "program" && groupColorMap[group]) {
      colorMap[group] = groupColorMap[group];
    } else {
      colorMap[group] = colors[index];
    }
  });

  // Initialize datasets
  groups.forEach(group => {
    datasets[group] = {
      label: group,
      data: Array(labels.length).fill(0),
      dataCanceled: Array(labels.length).fill(0),
      backgroundColor: colorMap[group] || "#CCCCCC",
      total: 0,
      canceledTotal: 0,
    };
  });

  // Fill datasets with data
  data.forEach(entry => {
    const groupByValue: any = entry[groupBy];
    const labelIndex = labels.indexOf(entry.t);

    if (labelIndex !== -1) {
      datasets[groupByValue].data[labelIndex] += entry[value] as number;
      totals[groupByValue] += entry[value] as number;

      //canceled
      if (show_canceled) {
        datasets[groupByValue].dataCanceled[labelIndex] += entry[
          `canceled_${value}` as keyof AdvancedReportData
        ] as number;
        canceledTotals[groupByValue] +=
          (entry[`canceled_${value}` as keyof AdvancedReportData] as number) ||
          0;
      }
    }
    //console.log({ canceledTotals, entry });
  });

  // Add total to datasets and finalize
  Object.keys(datasets).forEach(group => {
    datasets[group].total = totals[group];
    datasets[group].canceledTotal = canceledTotals[group];
  });

  return {
    labels: labels.map(l => l.replaceAll("-", ".")),
    datasets: Object.values(datasets),
  };
}

export function groupReportData2(
  data: AdvancedReportData[],
  groupBy: keyof AdvancedReportData
): GroupedReportData[] {
  const groupedData: { [key: string]: GroupedReportData } = {};

  // Group the data by the groupBy key and sum all numeric fields
  data.forEach(entry => {
    const groupKey = entry[groupBy] as string;
    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        label: groupKey,
        amount: 0,
        canceled_amount: 0,
        canceled_count: 0,
        canceled_guests: 0,
        count: 0,
        guests: 0,
        color: "", // Placeholder for color, will be added later
      };
    }

    groupedData[groupKey].amount += entry.amount;
    groupedData[groupKey].canceled_amount += entry.canceled_amount;
    groupedData[groupKey].canceled_count += entry.canceled_count;
    groupedData[groupKey].canceled_guests += entry.canceled_guests;
    groupedData[groupKey].count += entry.count;
    groupedData[groupKey].guests += entry.guests;
  });

  // Generate colors for each group
  const groupKeys = Object.keys(groupedData);
  const colors = generateColorPalette(groupKeys.length, "hex");

  // Assign colors to each group
  groupKeys.forEach((key, index) => {
    groupedData[key].color = colors[index];
  });

  // Convert the grouped data object to an array
  return Object.values(groupedData);
}
