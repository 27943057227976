import { CalendarDateRangeItem, CalendarRefType, useDialog } from "common";
import { BookingItem } from "features/bookings/bookingApi";
import {
  CalendarEventItem,
  EventItem,
  getEventCalendarService,
  prepareCalendarItems,
} from "features/events/eventApi";
import useSettings from "features/settings/useSettings";
import usePersistentState from "helpers/usePersistentState";
import { Moment } from "moment";
import QueryString from "qs";
import { useEffect, useRef, useState } from "react";
import { setCalendarLoading } from "./CalendarToolbar";

export function calendarAction(action: "refresh" | "setItem", item?: any) {
  let event = new CustomEvent("calendarAction", {
    detail: {
      action,
      item,
    },
  });
  document.dispatchEvent(event);
}
export interface UseEventCalendarProps {
  useEvents?: boolean;
  boatIds?: any[];
  programIds?: any[];
  defaultItems?: any[];
  date?: Moment;
  filterKey: string;
  filterStorageType?: "none" | "local" | "session";
}
export default function useEventCalendar({
  useEvents = true,
  boatIds: _boatIds,
  programIds: _programIds,
  date,
  defaultItems,
  filterKey,
  filterStorageType = "none",
}: UseEventCalendarProps) {
  const calService = getEventCalendarService({});
  const cal = useRef<CalendarRefType<CalendarEventItem> | undefined>();
  const [settings, setSettings] = useSettings({ key: "calendar" });
  const [boatIds, setBoatIds] = usePersistentState<any[] | undefined>(
    _boatIds,
    `calendar.${filterKey}.boatIds`,
    filterStorageType
  );
  const [programIds, setProgramIds] = usePersistentState<any[] | undefined>(
    _programIds,
    `calendar.${filterKey}.programIds`,
    filterStorageType
  );
  const [items, setItems] = useState<Array<CalendarEventItem>>(
    defaultItems || []
  );
  const init = useRef(false);
  useEffect(() => {
    if (init.current) {
      if (JSON.stringify(programIds) !== JSON.stringify(_programIds)) {
        setProgramIds(_programIds);
      }
    } else {
      init.current = true;
    }
  }, [_programIds]);

  const [event, setEvent, eventDialog] = useDialog<Partial<EventItem>>();
  const [booking, setBooking, bookingDialog] =
    useDialog<Partial<BookingItem>>();

  async function loadEvents(range: CalendarDateRangeItem) {
    setCalendarLoading(true);
    let params: any = {
      from: range.start.format("YYYY-MM-DD"),
      to: range.end.format("YYYY-MM-DD"),
    };
    if (boatIds) {
      params.boatIds = boatIds;
    }
    if (programIds) {
      params.programIds = programIds;
    }
    const queryParams = QueryString.stringify(params);
    const { success, data } = await calService.paginateItems(queryParams);
    setCalendarLoading(false);
    if (success) {
      return setItems(prepareCalendarItems(data || []));
    }
    return [];
  }
  const shouldRefresh = useRef(false);
  useEffect(() => {
    if (shouldRefresh.current) {
      cal.current?.refresh();
    } else {
      shouldRefresh.current = true;
    }
  }, [boatIds, programIds]);

  function handleCalendar({
    detail: { action, item },
  }: {
    detail: { action: "refresh" | "setItem"; item?: any };
  }) {
    if (action === "refresh") {
      cal.current?.refresh();
    }
    if (action === "setItem") {
      console.log({ item });
      let newItems = items.map(i => {
        return i.id === item.id ? prepareCalendarItems([item])[0] : i;
      });
      setItems(newItems);
    }
  }
  useEffect(() => {
    if (useEvents) {
      //@ts-ignore
      document.addEventListener("calendarAction", handleCalendar);
      return () =>
        //@ts-ignore
        document.removeEventListener("calendarAction", handleCalendar);
    }
  }, []);

  return {
    boatIds,
    setBoatIds,
    items,
    event,
    setEvent,
    eventDialog,
    booking,
    setBooking,
    bookingDialog,
    loadEvents,
    cal,
    programIds,
    setProgramIds,
    setItems,
    settings,
    setSettings,
  };
}
