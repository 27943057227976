import {
  Box,
  BoxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
  alpha,
} from "@mui/material";
import { Toolbar } from "common";
import { calendarStyle } from "common/components/CalendarPro/calendarStyle";
import HelpButton, { HelpButtonProps } from "components/HelpButton/HelpButton";
import I18n from "i18n";

function Cell(props: BoxProps) {
  return (
    <Box
      className="showIndicatiors"
      sx={{
        flex: 1,
      }}
      {...props}
    />
  );
}

function Boat({ className, sx, ...props }: BoxProps) {
  return (
    <Cell>
      <Box
        className={`calBoat ${className}`}
        sx={{
          flex: 1,
          display: "flex",
          gap: "1px",
          borderRadius: "3px",
          border: theme => `solid 1px ${alpha(theme.palette.info.main, 0.2)}`,
          "&:hover": {
            border: theme => `solid 1px ${alpha(theme.palette.info.main, 1)}`,
          },
          padding: "2px",
          ...sx,
        }}
        {...props}
      />
    </Cell>
  );
}
function Event({
  color,
  className,
  sx,
  children,
  ...props
}: Omit<BoxProps, "color"> & { color: string }) {
  return (
    <Cell>
      <Box
        className={`calEvent ${className}`}
        sx={{
          backgroundColor: color,
          color: theme => theme.palette.getContrastText(color),
          minWidth: "16px",
          paddingLeft: "2px",
          paddingRight: "2px",
          height: "16px",
          borderRadius: "3px",
          flex: 1,
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
          "&:hover": {
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
          ...sx,
        }}
        {...props}
      >
        <Toolbar sx={{ gap: "2px", paddingLeft: "2px", paddingRight: "2px" }}>
          {children}
        </Toolbar>
      </Box>
    </Cell>
  );
}
function Limit({ sx, className, ...props }: TypographyProps) {
  return (
    <Cell>
      <Typography
        className={`calLimit ${className}`}
        sx={{
          fontSize: "0.7rem",
          backgroundColor: "#FFFFFF",
          color: "#000000",
          lineHeight: "0.7rem",
          padding: "1px",
          borderRadius: "2px",
          fontWeight: 700,
          ...sx,
        }}
        {...props}
      />
    </Cell>
  );
}

export default function CalendarHelpButton(
  props: Omit<HelpButtonProps, "title" | "children">
) {
  return (
    <HelpButton title={I18n.t("Help.Calendar.title")} {...props}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {I18n.t("Help.Calendar.introduction")}
      </Typography>
      <TableContainer
        sx={{
          overflowY: "visible",
          "&.calBoat": {},
          "& td:nth-of-type(2)": theme => ({
            ...theme.typography.body1,
            pl: 1,
            fontSize: "0.85rem",
          }),
          ...calendarStyle,
          maxHeight: "unset",
        }}
      >
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell>{I18n.t("Help.Calendar.indicator")}</TableCell>
              <TableCell>{I18n.t("Help.Calendar.description")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Zinuru SS with plenty of space */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event className="" color={programs[7].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.plentyOfSpace", {
                  program: programs[7].name,
                })}
              </TableCell>
            </TableRow>
            {/* Three programs on one boat, all open */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event color={programs[2].color}></Event>
                  <Event color={programs[4].color}></Event>
                  <Event color={programs[5].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.threeProgramsOneBoat")}
              </TableCell>
            </TableRow>
            {/* Three programs on one boat, one approaching limit */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event color={programs[2].color}>5</Event>
                  <Event color={programs[4].color}></Event>
                  <Event color={programs[5].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.approachingLimit", {
                  program: programs[2].name,
                })}
              </TableCell>
            </TableRow>
            {/* Three programs on one boat, one over online limit */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event
                    className="eventOverOnlineLimit"
                    color={programs[2].color}
                  >
                    10
                  </Event>
                  <Event color={programs[4].color}></Event>
                  <Event color={programs[5].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.eventOverOnlineLimit", {
                  program: programs[2].name,
                })}
              </TableCell>
            </TableRow>
            {/* Three programs on one boat, one over limit */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event className="eventOverLimit" color={programs[2].color}>
                    0
                  </Event>
                  <Event color={programs[4].color}></Event>
                  <Event color={programs[5].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.eventOverLimit", {
                  program: programs[2].name,
                })}
              </TableCell>
            </TableRow>
            {/* Three programs on one boat, two over limit */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event className="eventOverLimit" color={programs[2].color}>
                    0
                  </Event>
                  <Event className="eventOverLimit" color={programs[4].color}>
                    0
                  </Event>
                  <Event color={programs[5].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.twoProgramsOverLimit", {
                  program1: programs[2].name,
                  program2: programs[4].name,
                })}
              </TableCell>
            </TableRow>
            {/* Boat over online limit */}
            <TableRow>
              <TableCell>
                <Boat className="boatOverOnlineLimit">
                  <Event color={programs[2].color}>5</Event>
                  <Event color={programs[4].color}></Event>
                  <Event color={programs[5].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.boatOverOnlineLimit")}
              </TableCell>
            </TableRow>
            {/* Boat over limit */}
            <TableRow>
              <TableCell>
                <Boat className="boatOverLimit">
                  <Event color={programs[2].color}>5</Event>
                  <Event color={programs[4].color}></Event>
                  <Event color={programs[5].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>{I18n.t("Help.Calendar.boatOverLimit")}</TableCell>
            </TableRow>
            {/* Multiple programs on one boat, one is cancelled */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event
                    className="eventCanceled"
                    color={programs[0].color}
                  ></Event>
                  <Event color={programs[1].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.multipleProgramsOneCanceled")}
              </TableCell>
            </TableRow>
            {/* Multiple programs on one boat, all are cancelled */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event
                    className="eventCanceled"
                    color={programs[0].color}
                  ></Event>
                  <Event
                    className="eventCanceled"
                    color={programs[1].color}
                  ></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.multipleProgramsAllCanceled")}
              </TableCell>
            </TableRow>
            {/* Multiple programs on one boat, one is closed */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event
                    className="eventClosed"
                    color={programs[0].color}
                  ></Event>
                  <Event color={programs[1].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.multipleProgramsOneClosed")}
              </TableCell>
            </TableRow>
            {/* Multiple programs on one boat, one is closed */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event
                    className="eventOnlineClosed"
                    color={programs[0].color}
                  ></Event>
                  <Event color={programs[1].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.multipleProgramsOneOnlineClosed")}
              </TableCell>
            </TableRow>
            {/* Boat closed */}
            <TableRow>
              <TableCell>
                <Boat className="boatClosed">
                  <Event color={programs[0].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>{I18n.t("Help.Calendar.boatClosed")}</TableCell>
            </TableRow>
            {/* Multiple programs on one boat, boat is closed */}
            <TableRow>
              <TableCell>
                <Boat className="boatClosed">
                  <Event color={programs[0].color}></Event>
                  <Event color={programs[1].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.multipleProgramsBoatClosed")}
              </TableCell>
            </TableRow>
            {/* Event with special limits, approaching limit */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event color={programs[0].color}>
                    <Limit>A:5</Limit>
                  </Event>
                  <Event color={programs[1].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.approachingLimit", {
                  program: programs[0].name,
                })}
              </TableCell>
            </TableRow>
            {/* Event with special limits, over online limit */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event color={programs[0].color}>
                    <Limit className="limitOverOnlineLimit">7:2</Limit>
                  </Event>
                  <Event
                    className="eventOverOnlineLimit"
                    color={programs[1].color}
                  >
                    9
                  </Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.overOnlineLimit", {
                  program: programs[0].name,
                })}
              </TableCell>
            </TableRow>
            {/* Event with special limits, over limit */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event color={programs[0].color}>
                    <Limit className="limitOverLimit">A:0</Limit>
                  </Event>
                  <Event color={programs[1].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.limitOverLimit", {
                  program: programs[0].name,
                })}
              </TableCell>
            </TableRow>
            {/* Event with special limits, limit closed */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event color={programs[0].color}>
                    <Limit className="limitClosed">A</Limit>
                  </Event>
                  <Event color={programs[1].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.specialLimitClosed", {
                  program: programs[0].name,
                })}
              </TableCell>
            </TableRow>
            {/* Event with special limits, limit closed */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event color={programs[0].color}>
                    <Limit className="limitClosed">7</Limit>
                    <Limit className="limitClosed">A</Limit>
                  </Event>
                  <Event color={programs[1].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>
                {I18n.t("Help.Calendar.multipleSpecialLimitClosed", {
                  program: programs[0].name,
                })}
              </TableCell>
            </TableRow>
            {/* Selected event */}
            <TableRow>
              <TableCell>
                <Boat>
                  <Event className="selected" color={programs[0].color}></Event>
                </Boat>
              </TableCell>
              <TableCell>{I18n.t("Help.Calendar.selectedEvent")}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </HelpButton>
  );
}

const programs = [
  {
    name: "Piano Vacsora",
    code: "D",
    color: "#9900ff",
    boat: 1,
  },
  {
    name: "Folklor Vacsora",
    code: "F",
    color: "#990000",
    boat: 1,
  },
  {
    name: "Pizza",
    code: "P",
    color: "#0000ff",
    boat: 3,
  },
  {
    name: "Sightseeing",
    code: "1",
    color: "#ffff00",
    boat: 2,
  },
  {
    name: "Booze",
    code: "B",
    color: "#00ffff",
    boat: 3,
  },
  {
    name: "Cocktail",
    code: "C",
    color: "#ff00ff",
    boat: 3,
  },
  {
    name: "Happy",
    code: "H",
    color: "#f9cb9c",
    boat: 2,
  },
  {
    name: "Zinuru SS",
    code: "Z",
    color: "#509ee7",
    boat: 4,
  },
];
