import { useAppSelector } from "app/hooks";
import { useMemo } from "react";
import { Dictionary } from "./dictionaryApi";
import {
  filterOptions,
  resolveOption,
  resolveOptions,
} from "./dictionaryHelper";
import { selectDictionary } from "./dictionarySlice";

export default function useDictionaries() {
  const dictionaries = useAppSelector(selectDictionary);
  return useMemo(() => {
    return {
      dictionaries,
      resolveOption: <T = any>(key: keyof Dictionary, id?: any) =>
        resolveOption(dictionaries, key, id) as T,
      resolveOptions: <T = any>(key: keyof Dictionary, ids?: any[]) =>
        resolveOptions(dictionaries, key, ids) as T[],
      filterOptions: <T = any>(
        key: keyof Dictionary,
        predictate: (item: any) => any
      ) => filterOptions(dictionaries, key, predictate) as T[],
    };
  }, [dictionaries]);
}
