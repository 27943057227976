import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  useTheme,
} from "@mui/material";
import { Button, Toolbar } from "common";
import BoatCheckboxSelector from "components/BoatCheckboxSelector/BoatCheckboxSelector";
import DateRangeSelector2 from "components/DateRangeSeletor/DateRangeSelector2";
import ProgramCheckboxSelector from "components/ProgramCheckboxSelector/ProgramCheckboxSelector";
import useSettings from "features/settings/useSettings";
import moment from "moment";
import { useAdvancedReportContext } from "../useAdvancedReport";

export interface AdvancedReportFilterProps {}

const AdvancedReportFilter: React.FC<AdvancedReportFilterProps> = ({}) => {
  const { filter, setFilter, params, setParams } = useAdvancedReportContext();
  const theme = useTheme();
  const [settings, setSettings] = useSettings({ key: "advancedReport" });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          flex: 1,
          alignSelf: "stretch",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "center",
            gap: 2,
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          <DateRangeSelector2
            numberOfCalendars={1}
            numberOfYears={1}
            value={{ start: moment(filter.from), end: moment(filter.to) }}
            onChange={(start, end) => {
              setFilter({
                ...filter,
                from: start.format("YYYY-MM-DD"),
                to: end.format("YYYY-MM-DD"),
              });
            }}
            dateButtonMinWidth={240}
          />
          <Toolbar sx={{ alignSelf: "stretch", gap: 2, flexWrap: "wrap" }}>
            <FormControl sx={{ flex: 1, minWidth: 200 }}>
              <InputLabel>Csoportosítás</InputLabel>
              <Select
                value={params.groupBy}
                label="Csoportosítás"
                onChange={e =>
                  setParams({
                    ...params,
                    groupBy: e.target.value as any,
                  })
                }
              >
                <MenuItem value="program">Program</MenuItem>
                <MenuItem value="program_variant">Programtípus</MenuItem>
                <MenuItem value="partner">Partner</MenuItem>
                <MenuItem value="partner_group">Partnercsoport</MenuItem>
                <MenuItem value="boat">Hajó</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: 200 }}>
              <InputLabel>Diagram</InputLabel>
              <Select
                value={params.chartType}
                label="Chart Type"
                onChange={e =>
                  setParams({
                    ...params,
                    chartType: e.target.value as "line" | "bar" | "pie",
                  })
                }
              >
                <MenuItem value="line">Vonal</MenuItem>
                <MenuItem value="bar">Oszlop</MenuItem>
                <MenuItem value="pie">Körcikk</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: 200 }}>
              <InputLabel>Érték</InputLabel>
              <Select
                value={params.value}
                label="Value"
                onChange={e =>
                  setParams({
                    ...params,
                    value: e.target.value as "amount" | "count" | "guests",
                  })
                }
              >
                <MenuItem value="amount">Összeg</MenuItem>
                <MenuItem value="guests">Létszám</MenuItem>
                <MenuItem value="count">Foglalások száma</MenuItem>
                <MenuItem value="canceled_amount">
                  Visszamondott Összeg
                </MenuItem>
                <MenuItem value="canceled_guests">
                  Visszamondott Létszám
                </MenuItem>
                <MenuItem value="canceled_count">
                  Visszamondott Foglalások száma
                </MenuItem>
              </Select>
            </FormControl>
          </Toolbar>
        </Box>
        <Button
          variant="text"
          size="small"
          endIcon={settings.show_filter ? <ExpandLess /> : <ExpandMore />}
          title={settings.show_filter ? "Szűrő elrejtése" : "Szűrő mutatása"}
          onClick={() => {
            setSettings({ show_filter: !settings.show_filter });
          }}
        />
        <Collapse in={settings.show_filter} sx={{ maxWidth: "100%" }}>
          <Box
            sx={{
              p: 1,
              flex: 1,
              alignSelf: "stretch",
              py: 1.5,
            }}
          >
            <BoatCheckboxSelector
              color={theme.palette.primary.main}
              value={filter.boatIds || []}
              onChange={evt =>
                setFilter({
                  ...filter,
                  boatIds: (evt.target?.value ? evt.target?.value : []) as any,
                })
              }
            />
          </Box>
          <Box
            sx={{
              p: 1,
              flex: 1,
              alignSelf: "stretch",
              pl: 3,
            }}
          >
            <ProgramCheckboxSelector
              value={filter.programIds || []}
              onChange={evt =>
                setFilter({
                  ...filter,
                  programIds: (evt.target?.value
                    ? evt.target?.value
                    : []) as any,
                })
              }
              showMore={settings.show_all_programs}
              setShowMore={show_all =>
                setSettings({ show_all_programs: show_all })
              }
            />
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};
export default AdvancedReportFilter;
