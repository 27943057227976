import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

import Search from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Spacer, TextField, Toolbar } from "common";
import { BookingItem, bookingSearch } from "features/bookings/bookingApi";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import I18n from "i18n";
import { size } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingListItem from "./BookingListItem";
import { CateringItem, getCateringData } from "./cateringApi";
import CateringBookingListSummary from "./CateringBookingListSummary";

type State = {
  item?: CateringItem;
};

function CateringBookingList() {
  const { start, boat_id } = useParams<"start" | "boat_id">();
  const location = useLocation();
  const _state = (location.state || {
    item: { bookings: [], program_ids: [], summary: [] },
  }) as State;

  const [state, setState] = useState<State>(_state);

  const { resolveOption } = useDictionaries();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [keyword, setKeyword] = useState<string>();
  const [bookings, setBookings] = useState<BookingItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedProgramIds, setSelectedProgramIds] = useState<number[]>([]);

  function toggleProgramSelection(programId?: number) {
    if (!programId) {
      return;
    }
    if (selectedProgramIds.includes(programId)) {
      setSelectedProgramIds(selectedProgramIds.filter(id => id !== programId));
    } else {
      setSelectedProgramIds([...selectedProgramIds, programId]);
    }
  }
  useEffect(() => {
    if (state.item?.program_ids) {
      if (size(selectedProgramIds) === 0) {
        setSelectedProgramIds(state.item?.program_ids);
      }
    }
  }, [state.item?.program_ids]);

  async function loadCateringData() {
    const { success, data } = await getCateringData(
      start,
      start,
      boat_id ? [boat_id as any] : null,
      state.item?.program_ids || null
    );
    if (success) {
      setState({ item: data[0] });
    }
  }
  async function loadOtherBookings() {
    if (!keyword) {
      return;
    }
    setLoading(true);
    const { data } = await bookingSearch({ keyword });
    setLoading(false);
    setBookings(data || []);
  }

  const localBookings = useMemo(() => {
    return state.item?.bookings.filter(
      booking =>
        selectedProgramIds.includes(booking.program_id) &&
        (!!keyword
          ? booking.name
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase()) ||
            booking.email
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          : true)
    );
  }, [state.item?.bookings, keyword, selectedProgramIds]);

  useEffect(() => {
    //if (!location.state) {
    loadCateringData();
    //}
  }, [location.state]);

  const loadTimer = useRef<any>();
  useEffect(() => {
    if (!!keyword && localBookings?.length === 0) {
      if (loadTimer.current) {
        clearTimeout(loadTimer.current);
      }
      loadTimer.current = setTimeout(() => {
        loadOtherBookings();
      }, 300);
    } else {
      setBookings([]);
    }
  }, [keyword, localBookings]);

  const renderPrograms = useCallback(() => {
    return (
      <>
        {state.item?.program_ids.map(program_id => {
          const program = resolveOption<ProgramItem>("programs", program_id);
          return (
            <Toolbar key={program_id} sx={{ gap: 1 }}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: "5px",
                  backgroundColor: program?.color,
                }}
              />
              <Typography>{program?.name}</Typography>
            </Toolbar>
          );
        })}
      </>
    );
  }, [state.item?.program_ids]);

  const summary = state.item?.summary;

  summary?.sort((a, b) => b.qty - a.qty);

  const reservedTables = useMemo(() => {
    return state.item?.bookings.reduce((accumulatedTables, booking) => {
      // Concatenate the current booking's table_numbers with the accumulated tables
      return accumulatedTables.concat(booking.table_numbers || []);
    }, [] as string[]); // Initial value is an empty array of strings
  }, [state.item?.bookings]);

  const navigate = useNavigate();
  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
      //open={open}
      open
      onClose={() => navigate("..")}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Typography variant="h6">{`${moment(state.item?.start_time).format(
            "L LT"
          )} - ${state.item?.boat_name}`}</Typography>
          {/*renderPrograms()*/}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ flex: 1 }} />
            <IconButton
              color="inherit"
              onClick={() => loadCateringData()}
              aria-label="refresh"
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => navigate("..")}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Spacer h={1} />
        <CateringBookingListSummary
          program_ids={state.item?.program_ids}
          summary={state.item?.guestSummary}
          toggleProgramSelection={toggleProgramSelection}
          selectedProgramIds={selectedProgramIds}
        />
        <Spacer h={2} />
        <TextField
          placeholder={I18n.t("Booking.searchPlaceholder")}
          fullWidth
          clearable
          onChange={evt => setKeyword(evt.target.value as any)}
          value={keyword}
          sx={{
            "& input": {
              fontSize: "1.1rem",
            },
          }}
          //onEnter={load}
          //onEscape={() => setAnchorEl(null)}
          useDelayedKeystrokes={false}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ minWidth: 22 }}>
                {loading ? <CircularProgress size={18} /> : <Search />}
              </InputAdornment>
            ),
          }}
        />
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <List disablePadding dense>
          {localBookings?.map(booking => (
            <BookingListItem
              key={booking.id}
              cateringItem={state.item}
              booking={
                {
                  ...booking,
                  event: { start_time: state.item?.start_time },
                } as any
              }
              reservedTables={
                reservedTables?.filter(
                  t =>
                    !booking.table_numbers?.find(bt => String(t) === String(bt))
                ) || []
              }
              afterChange={() => loadCateringData()}
            />
          ))}
          {bookings?.map(booking => (
            <BookingListItem
              cateringItem={state.item}
              key={booking.id}
              booking={booking as any}
              other
              reservedTables={[]}
              afterChange={() => loadCateringData()}
            />
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default CateringBookingList;
