// @ts-nocheck
import {
  GridModalFormProps,
  UseFormReturnType,
  Validator,
  enumToOptions,
  globalMessageHandler,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  BookingItem,
  BookingOptions,
  getBookingService,
} from "features/bookings/bookingApi";
import {
  BOOKING_PAY_LOCATIONS,
  BOOKING_STATUSES,
} from "features/bookings/bookingConstants";
import useDictionaries from "features/dictionaries/useDictionaries";
import { EventItem } from "features/events/eventApi";
import useProgramSummary from "features/events/useProgramSummary";
import { OptionItem } from "features/options/optionApi";
import I18n from "i18n";
import { omit } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Params } from "react-router-dom";
import BookingOptionValidator from "../../features/bookings/BookingOptionValidator";

export interface UseBookingFormProps extends GridModalFormProps {}

type RouteParams = {
  booking_id?: string;
};
type CapitalizeWords = { [key: string]: boolean };
export interface UseBookingFormReturnType
  extends UseFormReturnType<BookingItem, BookingOptions> {
  optionValidator: BookingOptionValidator;
  params?: Readonly<
    Params<
      | "booking_id"
      | "event_id"
      | "boat_id"
      | "program_id"
      | "schedule_id"
      | "program_variant_id"
      | "partner_id"
      | "partner_group_id"
      | "booking_option_id"
    >
  >;
  cancelBooking: () => Promise<boolean>;
  activateBooking: () => Promise<boolean>;
  statusChanging: boolean;
  capitalizeWords: CapitalizeWords;
  setCapitalizeWords: (capitalizeWords: CapitalizeWords) => void;
  event?: EventItem | null;
  priceMode: "normal" | "total";
  setPriceMode: (priceMode: "normal" | "total") => void;
}

export default function useBookingForm({
  id,
  afterSave,
  defaultValues,
  listItem,
}: UseBookingFormProps): UseBookingFormReturnType {
  const params = useParams<
    | "booking_id"
    | "event_id"
    | "boat_id"
    | "program_id"
    | "schedule_id"
    | "program_variant_id"
    | "partner_id"
    | "partner_group_id"
    | "booking_option_id"
  >();

  const scope = {
    events: params.event_id,
    boats: params.boat_id,
    programs: params.program_id,
    schedules: params.schedule_id,
    "program-variants": params.program_variant_id,
    partners: params.partner_id,
    "partner-groups": params.partner_group_id,
  };
  const service = getBookingService(scope);
  const { dictionaries, resolveOptions, filterOptions, resolveOption } =
    useDictionaries();

  const [priceMode, setPriceMode] = useState<"normal" | "total">(
    listItem?.event?.private || listItem?.planned_event?.private
      ? "total"
      : "normal"
  );
  const form = useForm<BookingItem, BookingOptions>({
    id: params.booking_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "Booking",
    rules: {
      program_variant_id: params.program_variant_id
        ? Validator.rule.optional()
        : Validator.rule.any().required(),
      status: Validator.rule.string().required(),
      name: Validator.rule.string().required(),
      //phone: Validator.rule.string().required(),
      number_of_adults: Validator.rule.any().required(),
      //sku: Validator.rule.string().required(),
      adult_price: Validator.rule.any().required(),
      kid_price: Validator.rule.any().required(),
    },
    afterSave,
    /*afterChange(newItem, id, option) {
      if (id === "program_variant_id") {
        loadData();
      }
    },*/
    defaultValues: { ...defaultValues, ...listItem, paid: true },
    transformChange: (item, id, option, oldItem) => {
      if (["partner_name", "name"].includes(id) && capitalizeWords[id]) {
        return { ...item, [id]: _capitalizeWords(item[id]) };
      } else if (id === "number_of_adults" && priceMode === "total") {
        const oldCount = oldItem?.number_of_adults || 1;
        const count = item[id];
        let adult_price = (item.adult_price * oldCount) / count;
        if (!!adult_price && adult_price > 0 && count > 0) {
          return { ...item, adult_price };
        } else {
          return { ...item, adult_price: 0 };
        }
      } else if (id === "number_of_kids" && priceMode === "total") {
        const oldCount = oldItem?.number_of_kids || 1;
        const count = item[id];
        let kid_price = (item.kid_price * oldCount) / count;
        if (!!kid_price && kid_price > 0 && count > 0) {
          return { ...item, kid_price };
        } else {
          return { ...item, kid_price: 0 };
        }
      }

      return item;
    },
    afterChange: (newItem, id, option) => {
      if (id === "program_variant_id") {
        const prices = getPrices(newItem[id]);
        const variant = resolveOption("variants", newItem[id]);
        form.setAttributes({ ...prices, sku: variant?.sku });
      }
    },
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    /*afterLoadOptions(newOptions) {
      const program_variant_id: any[] | undefined = (options as any)
        .program_variant_id;
      if (program_variant_id && program_variant_id.length === 1) {
        if (!form.item.program_variant_id) {
          form.setAttributes(
            {
              program_variant_id: program_variant_id[0].value,
            },
            true
          );
        }
      }
    },*/
    ...service,
    loadOptions: undefined,
    customValidation(newItem) {
      const errors: any = optionValidator.validateOptions();
      console.log(errors);
      if (errors) {
        return {
          booking_options: errors,
        };
      }
      return null;
    },
  });
  const event = getEvent();

  function getPrices(variant_id: number) {
    const v = event.variants.find(variant => variant.variant_id == variant_id);
    return {
      adult_price: parseFloat(v?.adult_price) || 0,
      kid_price: parseFloat(v?.kid_price) || 0,
    };
  }
  const {
    variants,
    options: _options,
    summary,
  } = useProgramSummary({ item: event });

  const { options } = useMemo(() => {
    return {
      options: {
        ...dictionaries,
        partner_id: dictionaries.partners,
        program_variant_id: variants,
        booking_options: prepareOptions(),
        status: enumToOptions(BOOKING_STATUSES, "Booking.options.status"),
        pay_location: enumToOptions(
          BOOKING_PAY_LOCATIONS,
          "Booking.options.pay_location"
        ),
      } as any as Dictionary,
    };
  }, [variants, _options]);

  const [statusChanging, setStatusChanging] = useState(false);
  const [capitalizeWords, setCapitalizeWords] = useState<CapitalizeWords>({
    name: true,
    partner_name: true,
  });

  function getEvent(): EventItem | null | undefined {
    if (form.item?.planned) {
      if (form.item?.planned_event) {
        return form.item.planned_event;
      }
    }
    if (form.item?.event) {
      return form.item.event;
    }
    if (defaultValues?.event) {
      return defaultValues.event;
    }
    return null;
  }

  function prepareOptions() {
    let groups: any = {};
    _options?.forEach(o => {
      const d = resolveOption<OptionItem>("options", o.option_id);
      if (d.program_id === event.program_id) {
        const id = d?.option_group?.id || -1;
        if (!groups[id]) {
          groups[id] = {
            ...d?.option_group,
            items: [],
          };
        }
        groups[id].items.push({
          ...omit(d, "option_group"),
          price: o?.price ? parseFloat(o?.price) : 0,
        });
      }
    });
    //console.log({ groups: Object.values(groups) });

    const options = Object.values(groups);
    // Sort options by sort_by
    options.sort((a, b) => {
      const sortByA = a?.sort_by ?? 0;
      const sortByB = b?.sort_by ?? 0;
      return sortByA - sortByB;
    });

    // Sort items inside each option by name
    options.forEach(option => {
      option.items?.sort((a, b) => {
        const nameA = a?.name?.toLowerCase() ?? "";
        const nameB = b?.name?.toLowerCase() ?? "";
        return nameA.localeCompare(nameB);
      });
    });
    return options;
  }

  const rules: any = {};
  if (form.item.custom_partner) {
    rules.partner_name = Validator.rule.any().required();
  } else {
    rules.partner_id = Validator.rule.any().required();
  }
  if (!!form.item.email) {
    rules.email = Validator.rule.string().email({ tlds: { allow: false } });
  }
  if (!form.item.planned) {
    rules.event_id = Validator.rule.any().required();
  }
  if (form.item.planned) {
    rules.planned_datetime = Validator.rule.any().required();
  }
  form.addRules(rules);

  const optionValidator = new BookingOptionValidator({
    booking: form.item,
    //@ts-ignore
    options: options.booking_options,
  });

  async function setStatus(status: BOOKING_STATUSES) {
    setStatusChanging(true);
    const { success, data } = await service.updateItem(form.item.id, {
      status,
    });
    setStatusChanging(false);
    if (success) {
      form.setAttributes({ status }, true);
      afterSave && afterSave({ ...form.item, status }, false);
    }
    return success;
  }

  async function cancelBooking() {
    const success = await setStatus(BOOKING_STATUSES.CANCELED);
    if (success) {
      globalMessageHandler.snack({
        message: "Sikeresen visszavontad a foglalást",
        severity: "success",
      });
    }
    return !!success;
  }
  async function activateBooking() {
    const success = await setStatus(BOOKING_STATUSES.ACTIVE);
    if (success) {
      globalMessageHandler.snack({
        message: "Sikeresen visszavontad a foglalást",
        severity: "success",
      });
    }
    return !!success;
  }

  function _capitalizeWords(value: any) {
    if (!!value) {
      let words = value.split(" ");
      let capitalizedWords: any[] = [];
      words?.forEach((word: any) => {
        capitalizedWords.push(word.charAt(0).toUpperCase() + word.slice(1));
      });
      return capitalizedWords.join(" ");
    } else {
      return value;
    }
  }

  function setDefaultProgramVariant() {
    if (options.program_variant_id && options.program_variant_id.length === 1) {
      if (!form.item.program_variant_id) {
        const program_variant_id = options.program_variant_id[0].value;
        form.setAttributes(
          {
            sku: options.program_variant_id[0].sku,
            program_variant_id,
            ...getPrices(program_variant_id),
          },
          true
        );
      }
    }
  }

  useEffect(() => {
    setDefaultProgramVariant();
  }, [options]);

  return {
    ...form,
    options,
    params,
    optionValidator,
    activateBooking,
    cancelBooking,
    statusChanging,
    capitalizeWords,
    setCapitalizeWords,
    event,
    priceMode,
    setPriceMode,
  };
}
