import { Box, useMediaQuery, useTheme } from "@mui/material";
import AdvancedReportDataProvider from "./AdvancedReportDataProvider";
import AdvancedReportHeader from "./AdvancedReportHeader";
import AdvancedReportProvider from "./AdvancedReportProvider";
import AdvancedReportChart from "./components/AdvancedReportChart";
import AdvancedReportFilter from "./components/AdvancedReportFilter";
import AdvancedReportPivotTable from "./components/AdvancedReportPivotTable";
import AdvancedReportToolbar from "./components/AdvancedReportToolbar";

export interface AdvancedReportProps {}

const AdvancedReport: React.FC<AdvancedReportProps> = ({}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <AdvancedReportDataProvider>
      <AdvancedReportHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          ml: isMobile ? -3 : 0,
          mr: isMobile ? -3 : 0,
          "& .MuiPaper-root": {
            borderRadius: isMobile ? 0 : undefined,
          },
        }}
      >
        <AdvancedReportProvider>
          <AdvancedReportFilter />
          <AdvancedReportToolbar />
          <AdvancedReportChart />
          <AdvancedReportPivotTable />
        </AdvancedReportProvider>
      </Box>
    </AdvancedReportDataProvider>
  );
};
export default AdvancedReport;
