import api from "app/api";
import { CalendarEventItem } from "features/events/eventApi";
import moment from "moment";

export type StreetSalesData = {
  [day: string]: {
    [time: string]: CalendarEventItem[];
  };
};

export function groupByStartTime(events: CalendarEventItem[]): StreetSalesData {
  return events.reduce((result: StreetSalesData, event: CalendarEventItem) => {
    const day = moment(event.start_time).format("YYYY-MM-DD");
    const time = moment(event.start_time).format("HH:mm");

    if (!result[day]) {
      result[day] = {};
    }

    if (!result[day][time]) {
      result[day][time] = [];
    }

    result[day][time].push(event);

    return result;
  }, {});
}

export function getStreetSalesData(
  startDate: string | undefined,
  endDate: string | undefined,
  boatIds: number[] | null,
  programIds: number[] | null
) {
  return api.get<CalendarEventItem[]>("/street-sales", {
    params: {
      from: startDate,
      to: endDate,
      boatIds,
      programIds,
    },
  });
}
