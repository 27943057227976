import { AxiosRequestConfig } from "axios";
import I18n from "i18n";
import { get, pick } from "lodash";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Options } from "../../modules/helpers";
import Validator, { Rules, ValidationErrors } from "../../modules/validator";
import { globalMessageHandler } from "./../GlobalMessaging";

export interface UseFormReturnType<TItem, TOptions = Options, TId = any> {
  id?: TId;
  isCreate: boolean;
  isUpdate: boolean;
  item: TItem;
  originalValues: TItem;
  options: TOptions;
  errors: ValidationErrors;
  onChange: (newItem: TItem, id?: string, option?: any) => void;
  save: (options?: FormSaveOptions<Partial<TItem>>) => Promise<void>;
  loading: boolean;
  saving: boolean;
  validate: (fields?: string[]) => boolean;
  clear: () => void;
  addRules: (conditionalRules: Rules) => void;
  setSaving: (saving: boolean) => void;
  setErrors: (errors: ValidationErrors) => void;
  setItem: (item: TItem) => void;
  //Merge given object to the item state and ignore
  setAttributes: (attributes: Partial<TItem>, ignoreDirty?: boolean) => void;
  load: (silent?: boolean) => Promise<void>;
  deleteItem: (silent?: boolean) => Promise<SaveFormProps<TItem> | void>;
  isDirty: boolean;
  getIsDirty: (id?: string) => boolean;
  loadOptions?: (
    requestConfig?: AxiosRequestConfig,
    component?: string
  ) => Promise<TOptions>;
  isView: boolean;
  requiredFields: string[];
  getRequiredFields: () => string[];
  toggleViewMode: Function;
}

export type FormRefType<
  TItem,
  TOptions = Options,
  TId = number
> = MutableRefObject<UseFormReturnType<TItem, TOptions, TId> | undefined>;

export interface UseFormProps<TItem, TOptions = Options, TId = any> {
  /** The primary key of item. If there is an id update will be fired on save anyway create */
  id?: TId;
  /** Default values of the form */
  defaultValues?: Partial<TItem>;
  /** Load function to get data from server */
  loadItem?: (id: TId) => Promise<TItem>;
  /** Load function to get data from server */
  loadOptions?: (
    requestConfig?: AxiosRequestConfig,
    component?: string
  ) => Promise<TOptions>;
  /** Update function to update data from server */
  updateItem?: (id: TId, item: TItem) => Promise<SaveFormProps<TItem>>;
  /** Create function to insert data from server */
  createItem?: (item: TItem) => Promise<SaveFormProps<TItem>>;
  deleteItem?: (
    id: any,
    onlyTrashed?: boolean
  ) => Promise<SaveFormProps<TItem>>;
  /** Joi rules object to validate form data on client side before save. */
  rules?: Rules;
  /** Using I18n-js Category.attributes to use as validation labels for fields. label name will get this ways: I18n.t("translationCategory.attributes") */
  translationCategory?: string;
  /** Function fired after succes saved if client and server validation is passed */
  afterSave?: (newItem: TItem, isCreate: boolean) => void;
  afterDelete?: (deletedItem: TItem) => void;
  afterSaveMessage?: (isCreate?: boolean) => string | null | undefined;
  /** Function fired after succes load */
  afterLoad?: (newItem: TItem) => void;
  /** Function fired after succes load */
  afterLoadOptions?: (newOptions: TOptions) => void;
  /** Function fired after form data changed (triggered by onChange) */
  afterChange?: (newItem: TItem, id?: string, option?: any) => void;
  transformChange?: (
    newItem: TItem,
    id?: string,
    option?: any,
    oldItem?: TItem
  ) => any;
  /** Function fired after form data is invalid */
  onValidationFailed?: (newItem: TItem, error?: any) => void;
  customValidation?: (newItem: TItem) => any;
  formRef?: FormRefType<TItem, TOptions, TId>;
  /** Show leave confirm modal if form is dirty */
  showLeaveConfirm?: boolean;
  /** turn isView to true on start */
  viewByDefault?: boolean;
  /** Dont replace last url part to id. Example replace users/create to users/12 after create */
  ignoreNavigateAfterCreate?: boolean;
  /** Dont replace last url part to id. Example replace users/create to users/12 after create */
  ignoreSetItemAfterSave?: boolean;
  /** Dont show success notification after create and save */
  ignoreSuccessNotification?: boolean;
  defaultValuesMakeDirty?: boolean;
  confirmAttribute?: string;
}

export type FormSaveOptions<T = any> = {
  /** Values to merge with item */
  withValues?: T;
  disableLoader?: boolean;
  addNewAfterCreate?: boolean;
};

export interface SaveFormProps<TItem> {
  success?: boolean;
  data?: TItem;
  error?: any;
}

export default function useForm<TItem, TOptions = Options, TId = any>({
  id: _id,
  defaultValues,
  loadItem,
  updateItem,
  createItem,
  deleteItem,
  loadOptions,
  rules: permanentRules,
  translationCategory,
  afterSave,
  afterDelete,
  afterLoad,
  afterLoadOptions,
  afterChange,
  onValidationFailed,
  afterSaveMessage,
  formRef,
  showLeaveConfirm,
  ignoreSetItemAfterSave,
  viewByDefault,
  customValidation,
  ignoreNavigateAfterCreate = false,
  ignoreSuccessNotification = false,
  defaultValuesMakeDirty,
  confirmAttribute,
  transformChange,
}: UseFormProps<TItem, TOptions, TId>) {
  const id: any = _id && String(_id) !== "create" ? _id : undefined;

  const itemRef = useRef<TItem>((defaultValues as TItem) || ({} as TItem));
  const [item, _setItem] = useState<TItem>(
    (defaultValues as TItem) || ({} as TItem)
  );
  function setItem(i: TItem) {
    itemRef.current = i;
    _setItem(itemRef.current);
  }
  //const [initialized.current, setInitialized] = useState<boolean>(!!loadItem);
  const initialized = useRef<boolean>(
    loadOptions || (loadItem && id) ? false : true
  );
  const originalValues = useRef(
    defaultValuesMakeDirty ? ({} as TItem) : item || ({} as TItem)
  );
  const [options, setOptions] = useState<TOptions>({} as TOptions);
  const [isView, setIsView] = useState<boolean>(viewByDefault || false);
  const afterChangeRequest = useRef<{ id?: string; option?: any }>();
  const afterCreateRequest = useRef<boolean>(false);
  const afterUpdateRequest = useRef<boolean>(false);
  const afterLoadRequest = useRef<boolean>(false);
  const afterLoadOptionsRequest = useRef<boolean>(false);
  const rules = useRef<Rules | undefined>(permanentRules);
  const useNav = !process.env.NEXT_PUBLIC_VERSION
    ? useNavigate
    : () => (a: string, b: any) => {};
  const navigate = useNav();
  rules.current = permanentRules;

  const [errors, setErrors] = useState<ValidationErrors>({});
  const [loading, setLoading] = useState<boolean>(
    loadItem && id ? true : false
  );
  const [saving, setSaving] = useState<boolean>(false);

  function onChange(newItem: TItem, id?: string, option?: any) {
    if (transformChange) {
      setItem({ ...item, ...transformChange(newItem, id, option, item) });
    } else {
      setItem({ ...item, ...newItem });
    }

    afterChangeRequest.current = { id, option };
  }

  function clear() {
    setItem({} as TItem);
    setErrors({});
  }

  function addRules(conditionalRules: Rules) {
    if (permanentRules) {
      rules.current = { ...permanentRules, ...conditionalRules };
    } else {
      rules.current = conditionalRules;
    }
  }

  function validate(fields?: string[]) {
    if (rules.current || customValidation) {
      let values: any = {};
      if (item) {
        Object.keys(item).forEach(element => {
          let i: any = item;
          if (i[element] !== undefined && i[element] !== null) {
            values[element] = i[element];
          }
        });
      }
      let errors: any = {};

      if (customValidation) {
        errors = { ...customValidation(values) };
      }

      if (rules.current) {
        const result = Validator.validate(
          fields ? pick(rules.current, fields) : rules.current,
          values,
          translationCategory || ""
        );
        if (!result.success) {
          errors = { ...errors, ...result.errors };
        }
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return false;
      }
    }
    return true;
  }

  async function update(options: FormSaveOptions<Partial<TItem>> = {}) {
    if (updateItem && id) {
      let _item = options.withValues
        ? { ...item, ...options.withValues }
        : item;
      setSaving(true);
      const { success, data, error } = await updateItem(id, _item);
      setSaving(false);
      if (success) {
        if (data) {
          const newItem = { ...item, ...data };
          //const newItem = { ..._item, ...data };
          originalValues.current = newItem;
          //afterUpdateRequest.current = true;
          if (ignoreSetItemAfterSave) {
            originalValues.current = item;
            setItem(item);
            afterSave && afterSave(item, false);
          } else {
            afterUpdateRequest.current = true;
            setItem(newItem);
          }
          return newItem;
        } else {
          originalValues.current = item as any;
          afterSave && afterSave(item, false);
          return item;
        }
      } else {
        if (error && Object.keys(error).length > 0) {
          //setErrors(transformFormErrors(error));
          setErrors(error);
          onValidationFailed && onValidationFailed(item, error);
        }
      }
    }
  }

  async function create(options: FormSaveOptions<Partial<TItem>> = {}) {
    if (createItem) {
      let _item = options.withValues
        ? { ...item, ...options.withValues }
        : item;
      setSaving(true);
      const { success, data, error } = await createItem(_item);
      setSaving(false);
      if (success) {
        if (data) {
          const newItem = { ...item, ...data };
          //const newItem = { ..._item, ...data };
          originalValues.current = newItem;
          //afterCreateRequest.current = true;
          if (ignoreSetItemAfterSave) {
            originalValues.current = item;
            setItem(item);
            afterSave && afterSave(item, true);
          } else {
            afterCreateRequest.current = true;
            setItem(newItem);
          }
          return newItem;
        } else {
          originalValues.current = item as any;
          afterSave && afterSave(item, true);
          return item;
        }
      } else {
        if (error && Object.keys(error).length > 0) {
          //setErrors(transformFormErrors(error));
          setErrors(error);
          onValidationFailed && onValidationFailed(item, error);
        }
      }
    }
  }

  async function save(options: FormSaveOptions<Partial<TItem>> = {}) {
    if (!validate()) {
      onValidationFailed && onValidationFailed(item);
      return;
    }
    let data: any;
    if (!options.disableLoader) {
      //loader.show();
    }

    if (id) {
      data = await update(options);
    } else {
      data = await create(options);
    }
    if (!options.disableLoader) {
      //loader.hide();
    }

    if (data) {
      if (!ignoreSuccessNotification) {
        if (afterSaveMessage) {
          const message = afterSaveMessage(!id);
          if (message) {
            globalMessageHandler.snack({
              severity: "success",
              message,
            });
          }
        } else {
          globalMessageHandler.snack({
            severity: "success",
            message: I18n.t(id ? "App.successSaved" : "App.successCreated"),
          });
        }
      }

      if (options?.addNewAfterCreate && !id) {
        setItem({} as TItem);
      } else if (!ignoreNavigateAfterCreate && !id && data.id) {
        //Replace last part of url with id. Example replace users/create to users/12 after create
        navigate(`../${String(data.id)}`, { replace: true });
      } else if (!ignoreNavigateAfterCreate && !id) {
        //Replace last part of url with id. Example replace users/create to users/12 after create
        navigate("..", { replace: true });
      }

      //afterSave && afterSave(data, !id);
    }
  }

  async function load(silent?: boolean) {
    //Load options and item paralel
    if (loadOptions && loadItem && id) {
      if (silent !== true) {
        setLoading(true);
      }
      const [options, data] = await Promise.all([loadOptions(), loadItem(id)]);
      initialized.current = true;
      if (silent !== true) {
        setLoading(false);
      }
      afterLoadOptionsRequest.current = true;
      setOptions(options);
      if (data) {
        //afterLoad && afterLoad(data);
        originalValues.current = data;
        afterLoadRequest.current = true;
        setItem(data);
        setErrors({});
      } else {
        afterLoad && afterLoad(data);
      }
      return;
    }

    if (loadOptions) {
      if (silent !== true) {
        setLoading(true);
      }
      const options: TOptions = await loadOptions();
      initialized.current = true;
      afterLoadOptionsRequest.current = true;
      setOptions(options);
      if (!loadItem || !id) {
        if (silent !== true) {
          setLoading(false);
        }
      }
    }
    if (loadItem && id) {
      if (silent !== true) {
        setLoading(true);
      }
      const data = await loadItem(id);
      initialized.current = true;
      if (silent !== true) {
        setLoading(false);
      }
      if (data) {
        if (afterLoad) {
          afterLoad(data);
        }
        originalValues.current = data;
        afterLoadRequest.current = true;
        setItem(data);
        setErrors({});
      }
    }
  }
  async function _delete(
    silent?: boolean
  ): Promise<SaveFormProps<TItem> | void> {
    if (deleteItem) {
      let attributeValue = (item as any).name || (item as any).id;
      if (confirmAttribute) {
        attributeValue = (item as any)[confirmAttribute];
      }

      globalMessageHandler.confirm(
        {
          message: I18n.t("App.deleteItemConfirm", {
            item: `${attributeValue}`,
          }),
        },
        async () => {
          if (!silent) {
            setLoading(true);
          }
          const response = await deleteItem(id);
          if (!silent) {
            setLoading(false);
          }
          if (response.success) {
            afterDelete && afterDelete(item);
            globalMessageHandler.snack({
              severity: "success",
              message: I18n.t("App.successDeleted"),
            });
          }
        }
      );
    }
  }

  function normalizeEmptyValue(value: any) {
    if (value === "") {
      return null;
    }
    if (value === undefined) {
      return null;
    }
    if (value === null) {
      return null;
    }
    if (value === 0) {
      return 0;
    }
    if (
      typeof value === "string" &&
      !isNaN(Number(value)) &&
      value === String(Number(value))
    ) {
      return Number(value);
    }
    return value;
  }

  function getIsDirty(id?: string): boolean {
    if (id) {
      let oldValue: any = normalizeEmptyValue(get(originalValues.current, id));
      let newValue: any = normalizeEmptyValue(get(item, id));
      return JSON.stringify(oldValue) !== JSON.stringify(newValue);
    }
    let hasDirty = false;
    Object.keys(item as any).forEach((key: string) => {
      let oldValue: any = normalizeEmptyValue(get(originalValues.current, key));
      let newValue: any = normalizeEmptyValue((item as any)[key]);

      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        hasDirty = true;
      }
    });

    return hasDirty;
  }

  async function _loadOptions(
    requestConfig?: AxiosRequestConfig,
    component?: string
  ): Promise<TOptions> {
    if (loadOptions) {
      const options: TOptions = await loadOptions(requestConfig, component);
      setOptions(options);
      return options;
    }
    return {} as TOptions;
  }

  function setAttributes(attributes: Partial<TItem>, ignoreDirty?: boolean) {
    if (ignoreDirty) {
      originalValues.current = { ...originalValues.current, ...attributes };
    }
    setItem({ ...itemRef.current, ...attributes });
  }

  useEffect(() => {
    if (formRef) {
      formRef.current = form;
    }

    if (_id && (loadItem || loadOptions)) {
      load();
    }
  }, [_id]);

  //Handle after Events
  useEffect(() => {
    if (afterChangeRequest.current) {
      const { id, option } = afterChangeRequest.current;

      afterChangeRequest.current = undefined;
      if (id && errors && errors[id]) {
        form.validate();
      }
      afterChange && afterChange(item, id, option);
    }
    if (afterUpdateRequest.current) {
      afterUpdateRequest.current = false;
      afterSave && afterSave(item, false);
    }
    if (afterCreateRequest.current) {
      afterCreateRequest.current = false;
      afterSave && afterSave(item, true);
    }
    if (afterLoadRequest.current) {
      afterLoadRequest.current = false;
      afterLoad && afterLoad(item);
    }
  }, [item]);
  //Handle after Events
  useEffect(() => {
    if (afterLoadOptionsRequest.current) {
      afterLoadOptionsRequest.current = false;
      afterLoadOptions && afterLoadOptions(options);
    }
  }, [options]);

  const form: UseFormReturnType<TItem, TOptions, TId> = {
    id,
    isCreate: !id,
    isUpdate: !!id,
    item,
    options,
    errors,
    onChange,
    save,
    loading: !initialized.current || loading,
    saving,
    validate,
    clear,
    addRules,
    setSaving,
    setErrors,
    setItem,
    setAttributes,
    originalValues: originalValues.current,
    loadOptions: _loadOptions,
    load,
    isDirty: getIsDirty(),
    getIsDirty,
    isView,
    requiredFields: rules.current ? Object.keys(rules.current) : [],
    getRequiredFields: () => (rules.current ? Object.keys(rules.current) : []),
    toggleViewMode: () => setIsView(!isView),
    deleteItem: _delete,
  };

  return form;
}
