import api from "app/api";
import {
  globalMessageHandler,
  useForm,
  UseFormReturnType,
  Validator,
} from "common";
import {
  BookingItem,
  BookingOptions,
  getBookingService,
  getUpgradeVariants,
  UpgradeItem,
} from "features/bookings/bookingApi";
import BookingOptionValidator from "features/bookings/BookingOptionValidator";
import useDictionaries from "features/dictionaries/useDictionaries";
import { EventItem } from "features/events/eventApi";
import { OptionItem } from "features/options/optionApi";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import I18n from "i18n";
import { omit } from "lodash";
import moment, { Moment } from "moment";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { UpgradesData } from "./upgradesApi";
import useUpgradesList from "./useUpgradesList";

export interface UseUpgradeProps {
  afterUpgrade: (item: UpgradeItem) => void;
  booking: BookingItem;
}

function parseItems(items: EventItem[]) {
  let _items: any[] = [];
  items.forEach(row => {
    _items.push({
      ...row,
      title: row?.program?.name,
      start: moment(row?.start_time),
      end: moment(row?.start_time).add(1, "hour"),
    });
  });

  return _items;
}

export type UpgradeContextType = {
  upgradesData: UpgradesData;
  listLoading: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleOpen: () => void;
  handleClose: () => void;
  load: () => void;
  handleFilter: (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null
  ) => Promise<void>;
  setRange: (range: { start?: Moment; end?: Moment } | undefined) => void;
  range: { start?: Moment; end?: Moment } | undefined;
  loaded: MutableRefObject<boolean>;
  isEmpty: boolean;
  setSettings: (settings: any) => void;
  settings: any;
  optionGroups: any[];
  programVariants: ProgramVariantItem[];
  optionValidator: BookingOptionValidator;
  selectedProgramVariant?: ProgramVariantItem;
  currentProgramVariant?: ProgramVariantItem;
  bookingNumberOfGuests: number;
  bookingPrice: number;
  booking: BookingItem;
  handleProgramVariantClick: (
    program_variant_id: number,
    upgrade_price: number,
    event: EventItem
  ) => void;
} & UseFormReturnType<BookingItem, BookingOptions>;

export default function useUpgrade({
  afterUpgrade,
  booking: _booking,
}: UseUpgradeProps): UpgradeContextType {
  const [open, setOpen] = useState(false);

  const {
    upgradesData,
    loading: listLoading,
    load,
    handleFilter,
    setRange,
    range,
    isEmpty,
    setSettings,
    settings,
  } = useUpgradesList(open, {
    start: moment(_booking.event?.start_time).startOf("day"),
    end: moment(_booking.event?.start_time).endOf("day"),
  });

  const [booking, setBooking] = useState<BookingItem>(_booking);

  async function loadBooking() {
    const bookingService = getBookingService();
    const item = await bookingService.loadItem(_booking.id);
    if (item) {
      setBooking(item);
    }
  }

  const [programVariants, setProgramVariants] = useState<ProgramVariantItem[]>(
    []
  );

  const loaded = useRef(false);

  const { dictionaries, resolveOption } = useDictionaries();

  async function loadProgramVariants() {
    const { success, data, error } = await getUpgradeVariants(booking.id);
    if (success) {
      setProgramVariants(data);
    }
  }

  const currentEventVariant = booking.event?.variants?.find(
    v => v.variant_id === booking.program_variant_id
  );

  const form = useForm<BookingItem, BookingOptions>({
    id: "create",
    ignoreNavigateAfterCreate: true,
    translationCategory: "Booking",
    rules: {
      program_variant_id: Validator.rule.any().required(),
      upgrade_price: Validator.rule.number().required().min(0),
      voucher: Validator.rule.string().required(),
    },
    defaultValues: {
      //program_variant_id: booking?.program_variant_id,
      description: _booking.description,
    },

    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    afterSave: (newItem, isCreate) => {
      if (afterUpgrade) {
        afterUpgrade(newItem);
        setBooking(newItem);
        form.setItem({ description: newItem.description } as any);
        setOpen(false);
      }
    },
    createItem: async item => {
      const response = await api.post<BookingItem>(
        `/bookings/${booking.id}/upgrade`,
        item
      );
      return response;
    },
    afterSaveMessage: () => {
      return I18n.t("Booking.successUpgraded");
    },
    customValidation(newItem) {
      const errors: any = optionValidator.validateOptions();
      console.log(errors);
      if (errors) {
        return {
          booking_options: errors,
        };
      }
      return null;
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    form.clear();
  };

  const selectedProgramVariant = resolveOption<ProgramVariantItem>(
    "variants",
    form.item?.program_variant_id
  );
  const currentProgramVariant = resolveOption<ProgramVariantItem>(
    "variants",
    booking.program_variant_id
  );

  function prepareOptions() {
    let groups: any = {};
    form.item.event?.options?.forEach(o => {
      const d = resolveOption<OptionItem>("options", o.option_id);
      if (d.program_id === selectedProgramVariant?.program_id) {
        const id = d?.option_group?.id || -1;
        if (!groups[id]) {
          groups[id] = {
            ...d?.option_group,
            items: [],
          };
        }
        groups[id].items.push({
          ...omit(d, "option_group"),
          price: o?.price ? parseFloat(o?.price as any) : 0,
        });
      }
    });
    return Object.values(groups);
  }
  const optionGroups: any = prepareOptions() || [];
  const optionValidator = new BookingOptionValidator({
    booking: {
      ...booking,
      program_variant: selectedProgramVariant,
      ...form.item,
    },
    options: optionGroups,
  });

  const bookingPrice =
    booking.adult_price * booking.number_of_adults +
    booking.kid_price * booking.number_of_kids;
  const bookingNumberOfGuests =
    booking.number_of_adults +
    booking.number_of_kids +
    booking.number_of_babies;

  function handleProgramVariantClick(
    program_variant_id: number,
    upgrade_price: number,
    event: EventItem
  ) {
    console.log({ program_variant_id, upgrade_price });
    form.setAttributes({
      program_variant_id,
      upgrade_price,
      event_id: event?.id,
      event: event,
    });
  }

  useEffect(() => {
    if (open && _booking) {
      loadProgramVariants();
      loadBooking();
    }
  }, [open, _booking]);

  return {
    ...form,
    optionGroups,
    open,
    setOpen,
    programVariants,
    handleOpen,
    handleClose,
    optionValidator,
    upgradesData,
    handleFilter,
    setRange,
    range,
    isEmpty,
    setSettings,
    settings,
    selectedProgramVariant,
    currentProgramVariant,
    loaded,
    listLoading,
    bookingPrice,
    bookingNumberOfGuests,
    booking,
    handleProgramVariantClick,
  };
}
