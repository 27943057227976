import qs from "qs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type QueryParams<T> = {
  [K in keyof T]: T[K];
};

// Utility function to parse strings as numbers when possible
const parseValue = (value: any): any => {
  if (Array.isArray(value)) {
    return value.map(parseValue);
  }

  // Check if the value is a string and contains only numeric characters (digits and a single decimal point)
  if (
    typeof value === "string" &&
    /^[0-9.]+$/.test(value) &&
    !isNaN(Number(value))
  ) {
    return Number(value);
  }

  return value;
};

export function useUrlQueryState<T extends Record<string, any>>(
  defaultValues?: QueryParams<T>
) {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse the query string from the URL, converting numbers to actual numbers
  const getUrlParams = (): QueryParams<T> => {
    const queryString = location.search;
    const parsed = qs.parse(queryString, {
      ignoreQueryPrefix: true,
      decoder: parseValue, // Custom decoder to parse numbers
    });
    console.log(queryString);
    console.log(parsed);

    // Combine URL params with default values (if URL doesn't contain a param, use default)
    return { ...defaultValues, ...parsed } as QueryParams<T>;
  };

  const [params, setParams] = useState<QueryParams<T>>(getUrlParams);

  // Update URL with the new params
  const setUrlParams = (newParams: Partial<QueryParams<T>>) => {
    const updatedParams = { ...params, ...newParams };
    const queryString = qs.stringify(updatedParams, {
      addQueryPrefix: true,
      encode: false, // Disable URL encoding
    });
    navigate({ search: queryString }, { replace: true });
    setParams(updatedParams);
  };

  // Sync state with URL whenever the location changes (useful if you navigate between pages)
  useEffect(() => {
    setParams(getUrlParams());
  }, [location.search]);

  return [params, setUrlParams] as const;
}
