import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import calendarApi, {
  CalendarDateRangeItem,
  CalendarEventItem,
  NAVIGATE,
  VIEWS,
} from "./calendarApi";

let navTimeout: any;

export interface useCalendarProps<
  EventType extends CalendarEventItem = CalendarEventItem
> {
  events?: EventType[];
  loadEvents?: (
    range: CalendarDateRangeItem
  ) => Promise<any> | undefined | void;
  date?: Moment;
  view?: VIEWS;
  views?: VIEWS[];
  selectedDate?: Moment;
  navigateDelay?: number;
  autoLoad?: boolean;
  sortFunction?: (a: any, b: any) => number;
  dayNameShortFunc?: (date: Moment) => string;
}

type CalendarState = {
  date: Moment;
  newDate: Moment;
  view: VIEWS;
  newView: VIEWS;
};

export default function useCalendar<
  EventType extends CalendarEventItem = CalendarEventItem
>({
  events,
  selectedDate,
  loadEvents,
  navigateDelay = 0,
  sortFunction,
  dayNameShortFunc,
  autoLoad,
  ...calProps
}: useCalendarProps<EventType>) {
  const [state, setState] = useState<CalendarState>({
    date: calProps.date || moment(),
    newDate: calProps.date || moment(),
    view: calProps.view || VIEWS.MONTH,
    newView: calProps.view || VIEWS.MONTH,
  });

  useEffect(() => {
    if (calProps.date && !state.date.isSame(calProps.date, "day")) {
      console.log("CAHNGE DATE", {
        old: calProps.date?.format("YYYY-MM-DD HH:mm:ss"),
        new: state.date?.format("YYYY-MM-DD HH:mm:ss"),
      });
      setState({ ...state, date: calProps.date, newDate: calProps.date });
    }
  }, [calProps.date]);

  const { view, newView, date, newDate } = state;

  const cal = calendarApi<EventType>(
    date,
    view,
    events,
    selectedDate,
    sortFunction,
    dayNameShortFunc
  );

  const handleNavigate = async (
    type: NAVIGATE,
    d?: Moment,
    noDelay?: boolean,
    _view?: VIEWS
  ) => {
    const _date = cal.navigate(type, d || newDate, _view);
    console.log(
      "NAVIGATE",
      d?.format("YYYY-MM-DD"),
      _date?.format("YYYY-MM-DD")
    );
    if (navigateDelay === 0) {
      console.log("NAVIGATE 2", newDate);
      const newRange = cal.getRange(_date, _view || view);
      loadEvents && (await loadEvents(newRange));
      if (_view) {
        setState({
          ...state,
          newDate: _date,
          date: _date,
          view: _view,
          newView: _view,
        });
      } else {
        setState({ ...state, newDate: _date, date: _date });
      }

      return;
    }

    setState({ ...state, newDate: _date });
    if (navTimeout) {
      clearTimeout(navTimeout);
    }

    navTimeout = setTimeout(
      async () => {
        const newRange = cal.getRange(_date, _view || view);
        if (noDelay) {
          if (_view) {
            setState({
              ...state,
              newDate: _date,
              date: _date,
              view: _view,
              newView: _view,
            });
          } else {
            setState({ ...state, newDate: _date, date: _date });
          }
        } else {
          if (_view) {
            setState({ ...state, newDate: _date, view: view, newView: _view });
          } else {
            setState({ ...state, newDate: _date });
          }
        }

        loadEvents && (await loadEvents(newRange));
        if (_view) {
          setState({
            ...state,
            newDate: _date,
            date: _date,
            view: _view,
            newView: _view,
          });
        } else {
          setState({ ...state, newDate: _date, date: _date });
        }
      },
      noDelay ? 0 : navigateDelay
    );
  };

  const handleView = (_view: any) => {
    setState({ ...state, newView: _view });
    const newRange = cal.getRange(date, _view);
    const _onChange = async () => {
      loadEvents && (await loadEvents(newRange));
      setState({ ...state, view: _view, newView: _view });
    };

    _onChange();
  };

  useEffect(() => {
    /*const _onChange = async () => {
      setState({ ...state, loading: true });
      onChange && (await onChange(cal.getRange(date, view)));
      setState({ ...state, loading: false });
    };*/
    //_onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (autoLoad && loadEvents) {
      const newRange = cal.getRange(date, view);
      loadEvents(newRange);
    }
  }, []);

  const views: VIEWS[] = calProps.views || [
    VIEWS.MONTH,
    VIEWS.WEEK,
    VIEWS.DAY,
    VIEWS.AGENDA,
  ];

  function refresh() {
    const range = cal.getRange(date, view);
    return loadEvents && loadEvents(range);
  }

  return {
    view,
    views,
    newView,
    date,
    newDate,
    cal,
    handleNavigate,
    handleView,
    refresh,
  };
}
