import { ReactNode } from "react";
import useAdvancedReportData, {
  AdvancedReportDataContext,
} from "./useAdvancedReportData";

export interface AdvancedReportDataProviderProps {
  children?: ReactNode;
}

const AdvancedReportDataProvider: React.FC<AdvancedReportDataProviderProps> = ({
  children,
}) => {
  const context = useAdvancedReportData();

  return (
    <AdvancedReportDataContext.Provider value={context}>
      {children}
    </AdvancedReportDataContext.Provider>
  );
};
export default AdvancedReportDataProvider;
