import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import MuiButtonGroup, {
  ButtonGroupProps as MuiButtonGroupProps,
} from "@mui/material/ButtonGroup";
import { ReactNode } from "react";

export interface ButtonGroupProps
  extends Omit<MuiButtonGroupProps, "children" | "onChange"> {
  error?: string;
  label?: string;
  helperText?: string;
  isDirty?: boolean;
  required?: boolean;
  editable?: boolean;
  onChange?: Function;
  selectedStartIcon?: ReactNode;
  selectedEndIcon?: ReactNode;
  clearable?: boolean;
  value?: any;
  options?: any[];
}

export default function ButtonGroup({
  error,
  options,
  label: _label,
  helperText,
  onChange,
  isDirty,
  editable,
  value,
  required,
  clearable,
  selectedStartIcon,
  selectedEndIcon,
  ...rest
}: ButtonGroupProps) {
  const label = required ? (
    <span>
      {_label} <span style={{ color: "red" }}>*</span>
    </span>
  ) : (
    _label
  );

  const handleChange = (evt: any, option: any, index: number) => {
    let v: Array<string | number | null> | string | number | null = null;
    v = option ? option.value : null;
    if (clearable && v === value) {
      onChange && onChange({ target: { value: null } });
    } else {
      onChange && onChange({ target: { value: v } });
    }
  };

  return (
    <FormControl>
      <FormLabel htmlFor={rest.id}>{label ? label : ""}</FormLabel>
      <MuiButtonGroup {...rest}>
        {options?.map((option: any, index: number) => {
          const selected = String(value) === String(option.value);
          return (
            <Button
              color="primary"
              startIcon={selected && selected ? selectedStartIcon : undefined}
              endIcon={selected && selected ? selectedEndIcon : undefined}
              variant={selected ? "contained" : "outlined"}
              onClick={evt => {
                handleChange(evt, option, index);
              }}
              key={option.value || "undefined"}
            >
              {option.label}
            </Button>
          );
        })}
      </MuiButtonGroup>
      {(error || helperText) && (
        <FormHelperText error={error ? true : false}>
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
