import InterestsIcon from "@mui/icons-material/Interests";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {
  Alert,
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  InputBase,
  LinearProgress,
} from "@mui/material";
import {
  Autocomplete,
  Checkbox,
  CreatedUpdatedBy,
  DateTimePicker,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  TextField,
  Toolbar,
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import List from "common/components/Inputs/List/List";
import EditSaveButton from "components/EditSaveButton/EditSaveButton";
import PriceInputBase from "components/PriceInputBase";
import { EVENT_STATUSES } from "features/events/eventConstants";
import HistoryListDialog from "features/histories/HistoryListDialog";
import useAccess from "features/profile/useAccess";
import {
  ScheduleLimit,
  ScheduleOption,
  ScheduleSeatingLayout,
  ScheduleVariant,
} from "features/schedules/scheduleApi";
import { LIMIT_TYPES } from "features/schedules/scheduleConstants";
import I18n from "i18n";
import moment from "moment";
import { createContext, useState } from "react";
import EventSummary from "./EventSummary";
import useEventCalendarForm, {
  UseEventCalendarFormProps,
  UseEventCalendarFormReturnType,
} from "./useEventCalendarForm";
export const EventCalendarContext = createContext<
  UseEventCalendarFormReturnType | undefined
>(undefined);
export interface EventCalendarFormProps extends UseEventCalendarFormProps {}

export default function EventCalendarForm({
  id,
  afterSave,
  onClose,
  defaultValues,
}: EventCalendarFormProps) {
  const { hasPermissions, canEditItem } = useAccess();
  const form = useEventCalendarForm({
    id,
    afterSave,
    defaultValues,
  });
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    load,
    saving,
    program,
    programs,
    type,
  } = form;
  const title = isCreate
    ? `${item.program?.name} ${moment(item.start_time).format("L LT")}`
    : `${item.program?.name} ${moment(item.start_time).format("L LT")}`;
  const subtitle = isCreate ? `${item.boat?.name}` : `${item.boat?.name}`;
  const [showBookings, setShowBookings] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <ParamsContext.Provider
      value={{
        event: item,
        event_id: id,
        boat_id: item.boat_id,
        program_id: item.program_id,
        schedule_id: item.schedule_id,
      }}
    >
      <EventCalendarContext.Provider value={form}>
        <PageHeader
          sx={{
            minHeight: "80px!important",
            backgroundColor:
              item.status === EVENT_STATUSES.CANCELED
                ? theme => theme.palette.background.default
                : undefined,
          }}
          avatar={
            <Avatar
              sx={{
                backgroundColor: item.program?.color,
                color: theme =>
                  item.program?.color
                    ? theme.palette.getContrastText(item?.program?.color)
                    : undefined,
              }}
            >
              {item.program?.code}
            </Avatar>
          }
          title={title}
          subtitle={
            <Toolbar sx={{ gap: 1 }}>
              {subtitle}
              {item.private && (
                <Box
                  sx={{
                    fontWeight: 500,
                    color: theme => theme.palette.info.main,
                  }}
                >
                  {I18n.t("Event.private")}
                </Box>
              )}
              {item.custom && (
                <Box
                  sx={{
                    fontWeight: 500,
                    color: theme => theme.palette.warning.main,
                  }}
                >
                  {I18n.t("Event.custom")}
                </Box>
              )}
            </Toolbar>
          }
          onClose={onClose}
        >
          <EditSaveButton
            onEditModeChange={setIsEditMode}
            variant="text"
            isEditMode={isEditMode}
            onSave={() => save()}
            loading={saving}
            saveButtonProps={{
              disabled: !isDirty,
            }}
          />
        </PageHeader>
        {item?.status === EVENT_STATUSES.CANCELED && (
          <Alert sx={{ borderRadius: 0 }} variant="filled" severity="error">
            {I18n.t("Event.canceledEvent")}
          </Alert>
        )}
        {!!item?.limits?.find(
          l => l.closed && l.program_id === item.program_id
        ) && (
          <Alert sx={{ borderRadius: 0 }} variant="filled" severity="warning">
            {I18n.t("Event.closedEvent")}
          </Alert>
        )}
        {loading && (
          <LinearProgress
            sx={{ position: "absolute", left: 0, top: 0, right: 0 }}
          />
        )}
        {isEditMode && (
          <DialogContent>
            <Form
              loading={loading}
              item={item}
              options={options}
              errors={errors}
              onChange={onChange}
              translationCategory="Event.attributes"
              translationPlaceholdersCategory="Event.placeholders"
              translationHelpersCategory="Event.helpers"
              isDirty={getIsDirty}
              requiredFields={getRequiredFields()}
              disabled={!canEditItem("event", item)}
            >
              <FieldsContainer>
                <Field>
                  <DateTimePicker id="start_time" is24Hours />
                </Field>
                <Field>
                  <Autocomplete id="boat_id" />
                </Field>
                <Field>
                  <Autocomplete id="program_id" />
                </Field>
                <Field>
                  <TextField id="duration" />
                </Field>
                <Field>
                  <Autocomplete id="status" />
                </Field>
                <Field>
                  <Checkbox id="custom" />
                </Field>
                <Field>
                  <Checkbox id="private" />
                </Field>
                <Field fullWidth>
                  <List<ScheduleVariant>
                    id="variants"
                    icon={<InterestsIcon />}
                    columns={[
                      {
                        id: "variant_id",
                        title: I18n.t("Price.attributes.variant_id"),
                        inputComponent: Autocomplete,
                        inputProps: {
                          options: options.variants,
                          colorAttribute: "program.color",
                        },
                        headerProps: { width: "50%" },
                      },
                      {
                        id: "adult_price",
                        title: I18n.t("Price.attributes.adult_price"),
                        inputComponent: PriceInputBase,
                        inputProps: { type: "number", switchDisabled: true },
                      },
                      {
                        id: "kid_price",
                        title: I18n.t("Price.attributes.kid_price"),
                        inputComponent: PriceInputBase,
                        inputProps: { type: "number" },
                      },
                    ]}
                  />
                </Field>
                <Field fullWidth>
                  <List<ScheduleLimit>
                    id="limits"
                    icon={<ProductionQuantityLimitsIcon />}
                    onRowCahnge={(item, index, field, value) => {
                      if (field === "type") {
                        if (value === LIMIT_TYPES.PROGRAM) {
                          const variant_ids = options.variants
                            .filter(v => v.program_id === item.program_id)
                            .map(v => v.id);
                          return { ...item, variant_ids, name: undefined };
                        }
                        if (value === LIMIT_TYPES.BOAT) {
                          const variant_ids = options.variants.map(v => v.id);
                          return {
                            ...item,
                            variant_ids,
                            name: I18n.t("Boat.titleSingular"),
                            program_id: undefined,
                          };
                        }
                        return { ...item, program_id: undefined };
                      }
                      if (field === "program_id") {
                        const variant_ids = options.variants
                          .filter(v => v.program_id === value)
                          .map(v => v.id);
                        return { ...item, variant_ids, name: undefined };
                      }
                      return item;
                    }}
                    columns={[
                      {
                        id: "type",
                        title: I18n.t("Limit.attributes.type"),
                        inputComponent: Autocomplete,
                        inputProps: {
                          options: type,
                        },
                        headerProps: { width: "140px" },
                      },
                      (item, index) => {
                        if (item?.type === LIMIT_TYPES.PROGRAM) {
                          return {
                            id: "program_id",
                            title: I18n.t("Limit.attributes.name"),
                            inputComponent: Autocomplete,
                            inputProps: {
                              options: programs,
                              colorAttribute: "color",
                            },
                            headerProps: { width: "50%" },
                          };
                        }
                        return {
                          id: "name",
                          title: I18n.t("Limit.attributes.name"),
                          inputComponent: InputBase,
                          inputProps: {
                            disabled: item?.type === LIMIT_TYPES.BOAT,
                          },
                          headerProps: { width: "140px" },
                        };
                      },
                      {
                        id: "variant_ids",
                        title: I18n.t("Limit.attributes.variant_ids"),
                        inputComponent: Autocomplete,
                        inputProps: (item?: ScheduleLimit) => ({
                          disabled:
                            item?.type === LIMIT_TYPES.BOAT ||
                            item?.type === LIMIT_TYPES.PROGRAM,
                          multiple: true,
                          options: options.variants,
                          colorAttribute: "program.color",
                        }),
                        headerProps: { width: "50%" },
                      },
                      {
                        id: "number_of_places",
                        title: I18n.t("Limit.attributes.number_of_places"),
                        inputComponent: InputBase,
                        inputProps: { type: "number" },
                      },
                      {
                        id: "number_of_online_places",
                        title: I18n.t(
                          "Limit.attributes.number_of_online_places"
                        ),
                        inputComponent: InputBase,
                        inputProps: { type: "number" },
                      },
                      {
                        id: "places_tolerance",
                        title: I18n.t("Limit.attributes.places_tolerance"),
                        inputComponent: InputBase,
                        inputProps: { type: "number" },
                      },
                    ]}
                  />
                </Field>
                {program?.has_options && (
                  <Field fullWidth>
                    <List<ScheduleOption>
                      id="options"
                      icon={<WorkspacePremiumIcon />}
                      columns={[
                        {
                          id: "option_id",
                          title: I18n.t("Option.attributes.option_id"),
                          inputComponent: Autocomplete,
                          inputProps: {
                            options: options.options,
                            colorAttribute: "program.color",
                          },
                          headerProps: { width: "50%" },
                        },
                        {
                          id: "price",
                          title: I18n.t("Price.attributes.price"),
                          inputComponent: PriceInputBase,
                          inputProps: { type: "number", switchDisabled: true },
                        },
                        {
                          id: "limit",
                          title: I18n.t("Price.attributes.limit"),
                          inputComponent: InputBase,
                          inputProps: { type: "number" },
                        },
                      ]}
                    />
                  </Field>
                )}
                <Field fullWidth>
                  <List<ScheduleSeatingLayout>
                    id="seating_layouts"
                    icon={<InterestsIcon />}
                    columns={[
                      {
                        id: "program_id",
                        title: I18n.t("Program.titleSingular"),
                        inputComponent: Autocomplete,
                        inputProps: {
                          options: programs,
                          colorAttribute: "color",
                        },
                        headerProps: { width: "50%" },
                      },
                      {
                        id: "seating_layout_ids",
                        inputComponent: Autocomplete,
                        // headerProps: { width: "50%" },
                        inputProps: {
                          multiple: true,
                          options: options.layouts,
                        },
                      },
                    ]}
                  />
                </Field>
              </FieldsContainer>
            </Form>
          </DialogContent>
        )}
        {!isEditMode && <EventSummary />}
        <DialogActions>
          <CreatedUpdatedBy
            item={item}
            sx={{
              p: 1,
              paddingTop: 0,
              paddingBottom: 0.5,
              marginRight: "auto",
            }}
            renderDialog={
              hasPermissions(["history.viewany", "history.viewown"])
                ? props => <HistoryListDialog {...props} subtitle={title} />
                : undefined
            }
          />
        </DialogActions>
      </EventCalendarContext.Provider>
    </ParamsContext.Provider>
  );
}
