import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useContext } from "react";
import { ScheduleContext } from "../ScheduleTabs";

export default function CloneButton() {
  const form = useContext(ScheduleContext);

  return (
    <Tooltip title="Másolás">
      <Box>
        <IconButton
          disabled={form?.isDirty || form?.loading || form?.isCreate}
          color="primary"
          onClick={() => form?.clone && form?.clone()}
        >
          <ContentCopyIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
