import { Chart, ChartData, ChartDataset, Plugin } from "chart.js";
import { formatNumber, formatPrice } from "./priceHelper";

interface DatasetWithTotal extends ChartDataset<"bar"> {
  total?: number;
}

interface ChartDataWithTotal extends ChartData<"bar"> {
  datasets: DatasetWithTotal[];
}

export const totalPlugin: Plugin<"bar" | "line"> = {
  id: "totalPlugin",
  afterDraw: function (chart: Chart<"bar" | "line" | "pie">) {
    const customConfig = (chart as any).options.customConfig;
    const ctx = chart.ctx;
    const datasets = chart.data.datasets as DatasetWithTotal[];
    const { top, left, right } = chart.chartArea;
    const chartWidth = right - left;
    const legendHeight = 20; // Height of each legend item
    const legendTopMargin = 10; // Margin between the top of the chart and the legends
    const padding = 10; // Padding between legend items

    // Calculate the total legend height and rows needed
    let totalLegendWidth = 0;
    const legendRows: any[] = [];
    let currentRow: any[] = [];
    datasets.forEach((dataset: DatasetWithTotal, i: number) => {
      if (!dataset.label?.includes("Canceled")) {
        const meta = chart.getDatasetMeta(i);
        if (!meta.hidden) {
          // console.log(dataset);
          const label =
            customConfig?.value === "amount" ||
            customConfig?.value === "canceled_amount"
              ? `${dataset.label} (${formatPrice(
                  dataset.total,
                  customConfig?.currency,
                  customConfig?.short_prices
                )}${
                  !!(dataset as any)?.canceledTotal &&
                  customConfig?.show_canceled
                    ? ` / ${formatPrice(
                        (dataset as any)?.canceledTotal,
                        customConfig?.currency,
                        customConfig?.short_prices
                      )}`
                    : ""
                })`
              : `${dataset.label} (${formatNumber(dataset.total)}${
                  !!(dataset as any).canceledTotal &&
                  customConfig?.show_canceled
                    ? ` / ${formatNumber((dataset as any)?.canceledTotal)}`
                    : ""
                })`;
          const labelWidth = ctx.measureText(label).width + 30; // Add some space for color box
          totalLegendWidth += labelWidth + padding;
          if (totalLegendWidth > chartWidth) {
            legendRows.push(currentRow);
            currentRow = [];
            totalLegendWidth = labelWidth + padding;
          }
          currentRow.push({
            label,
            backgroundColor: dataset.backgroundColor,
            width: labelWidth,
          });
        }
      }
    });
    if (currentRow.length > 0) {
      legendRows.push(currentRow);
    }

    // Calculate total height needed for legends
    const totalLegendHeight = legendRows.length * legendHeight;

    // Draw legends row by row
    ctx.save();
    ctx.textAlign = "left";
    ctx.textBaseline = "middle";
    ctx.font = "12px Arial";
    let y = legendHeight / 2;
    /*  -65 +
      totalLegendHeight +
      top -
      legendTopMargin -
      totalLegendHeight +
      legendHeight / 2;*/
    legendRows.forEach(row => {
      let x =
        -padding +
        left +
        (chartWidth -
          row.reduce((sum: any, item: any) => sum + item.width, 0)) /
          2;
      row.forEach((item: any) => {
        // Draw the colored rectangle
        ctx.fillStyle = item.backgroundColor as string;
        ctx.fillRect(x, y - 5, 10, 10); // Adjust rectangle size and position

        // Draw the legend text
        ctx.fillStyle = "grey";
        ctx.fillText(item.label, x + 15, y); // Adjust text position

        x += item.width + padding;
      });
      y += legendHeight;
    });
    ctx.restore();
  },
};

export const totalPiePlugin: Plugin<"pie"> = {
  id: "totalPiePlugin",
  afterDraw: function (chart: Chart<"pie">) {
    const customConfig = (chart as any).options.customConfig;
    const ctx = chart.ctx;
    const datasets = chart.data.datasets[0] as any; // Pie chart has only one dataset
    const { top, left, right } = chart.chartArea;
    const chartWidth = right - left;
    const legendHeight = 20; // Height of each legend item
    const legendTopMargin = 10; // Margin between the top of the chart and the legends
    const padding = 20; // Padding between legend items

    // Calculate the total legend width and rows needed
    let totalLegendWidth = 0;
    const legendRows: any[] = [];
    let currentRow: any[] = [];
    chart.data.labels?.forEach((label, index) => {
      const value = datasets.data[index];
      const backgroundColor = datasets.backgroundColor[index];
      const labelText = `${label} (${formatPrice(
        value,
        customConfig?.currency,
        customConfig?.short_prices
      )})`;
      const labelWidth = ctx.measureText(labelText).width + 30; // Add some space for color box
      totalLegendWidth += labelWidth + padding;
      if (totalLegendWidth > chartWidth) {
        legendRows.push(currentRow);
        currentRow = [];
        totalLegendWidth = labelWidth + padding;
      }
      currentRow.push({
        label: labelText,
        backgroundColor,
        width: labelWidth,
      });
    });
    if (currentRow.length > 0) {
      legendRows.push(currentRow);
    }

    // Calculate total height needed for legends
    const totalLegendHeight = legendRows.length * legendHeight;

    // Draw legends row by row
    ctx.save();
    ctx.textAlign = "left";
    ctx.textBaseline = "middle";
    ctx.font = "12px Arial";
    let y = legendHeight / 2; //top - legendTopMargin - totalLegendHeight + legendHeight / 2;
    legendRows.forEach(row => {
      let x =
        -padding +
        left +
        (chartWidth -
          row.reduce((sum: any, item: any) => sum + item.width, 0)) /
          2;
      row.forEach((item: any) => {
        // Draw the colored rectangle
        ctx.fillStyle = item.backgroundColor as string;
        ctx.fillRect(x, y - 5, 10, 10); // Adjust rectangle size and position

        // Draw the legend text
        ctx.fillStyle = "grey";
        ctx.fillText(item.label, x + 15, y); // Adjust text position

        x += item.width + padding;
      });
      y += legendHeight;
    });
    ctx.restore();
  },
  /*beforeDatasetsDraw: function (chart: Chart<"pie">) {
    const ctx = chart.ctx;

    // Save the current context state
    ctx.save();

    // Translate (shift) the pie chart by modifying its drawing position
    ctx.translate(0, 20); // Shift down by 50px (adjust as needed)
    // Adjust the canvas height by adding 50 pixels
    //ctx.canvas.height = 800;

    // Let Chart.js continue with the default drawing of datasets (without recursion)
  },
  afterDatasetsDraw: function (chart: Chart<"pie">) {
    const ctx = chart.ctx;

    // Restore the context to its original state to prevent affecting other drawings
    ctx.restore();
  },*/
};

export const barTotalPlugin: Plugin<"bar"> = {
  id: "barTotalPlugin",
  afterDatasetsDraw: chart => {
    const customConfig = (chart as any).options.customConfig;
    const ctx = chart.ctx;
    const datasets = chart.data.datasets as DatasetWithTotal[];
    const meta = chart.getDatasetMeta(datasets.length - 1);
    const textHeight = 12;
    if (meta) {
      meta.data.forEach((bar, index) => {
        let positiveTotal = 0;
        let negativeTotal = 0;
        let highestBar: any = null;
        let lowestBar: any = null;

        // Calculate positive and negative totals and find the highest and lowest bars
        datasets.forEach(dataset => {
          const value = (dataset.data[index] as number) || 0;
          const currentBar = chart.getDatasetMeta(datasets.indexOf(dataset))
            .data[index];

          if (value >= 0) {
            positiveTotal += value;
            if (!highestBar || currentBar.y < highestBar.y) {
              highestBar = currentBar;
            }
          } else {
            negativeTotal += value;
            if (!lowestBar || currentBar.y > lowestBar.y) {
              lowestBar = currentBar;
            }
          }
        });

        // Display positive total above the highest bar
        if (positiveTotal && highestBar) {
          const positiveTotalText =
            customConfig?.value === "amount"
              ? formatPrice(
                  positiveTotal,
                  customConfig?.currency,
                  customConfig?.short_prices
                )
              : formatNumber(positiveTotal);

          ctx.font = "bold 12px Arial";

          ctx.fillStyle = "green";
          ctx.textAlign = "center";

          // Check if text needs to be rotated
          if (
            (highestBar as any).width < ctx.measureText(positiveTotalText).width
          ) {
            ctx.save();
            ctx.translate(
              highestBar.tooltipPosition(false).x + textHeight / 2,
              highestBar.y - 10 - ctx.measureText(positiveTotalText).width / 2
            );
            ctx.rotate(-Math.PI / 2);
            ctx.fillText(positiveTotalText, 0, 0);
            ctx.restore();
          } else {
            ctx.fillText(
              positiveTotalText,
              highestBar.tooltipPosition(false).x,
              highestBar.y - 10
            );
          }
        }

        // Display negative total below the lowest bar
        if (negativeTotal && lowestBar) {
          const negativeTotalText =
            customConfig?.value === "amount"
              ? formatPrice(
                  negativeTotal,
                  customConfig?.currency,
                  customConfig?.short_prices
                )
              : formatNumber(negativeTotal);

          ctx.font = "12px Arial";
          ctx.fillStyle = "#FF4C4C";
          ctx.textAlign = "center";

          // Check if text needs to be rotated
          if (
            (lowestBar as any).width < ctx.measureText(negativeTotalText).width
          ) {
            ctx.save();
            ctx.translate(
              lowestBar.tooltipPosition(false).x + textHeight / 2,
              lowestBar.y + 10 + ctx.measureText(negativeTotalText).width / 2
            );
            ctx.rotate(-Math.PI / 2);
            ctx.fillText(negativeTotalText, 0, 0);
            ctx.restore();
          } else {
            ctx.fillText(
              negativeTotalText,
              lowestBar.tooltipPosition(false).x,
              lowestBar.y + 20
            );
          }
        }
      });
    }
  },
};
