import { alpha, Box, List, Typography } from "@mui/material";
import { useMemo } from "react";
import { CateringItem, Summary } from "./cateringApi";
import CateringListItem from "./CateringListItem";
import OrderItem from "./OrderItem";

interface CateringListProps {
  cateringData: CateringItem[];
}

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

export default function CateringList({ cateringData }: CateringListProps) {
  // Group and sum the summaries by name
  const groupedSummaries = cateringData
    .flatMap(item => item.summary)
    .reduce((acc, summary) => {
      const existing = acc.find(s => s.name === summary.name);
      if (existing) {
        existing.qty += summary.qty;
      } else {
        acc.push({ ...summary });
      }
      return acc;
    }, [] as Summary[]);
  groupedSummaries.sort((a, b) => b.qty - a.qty);

  const goupedGuestSummary = useMemo(() => {
    let sum = {
      number_of_guests: 0,
      number_of_adults: 0,
      number_of_kids: 0,
      number_of_babies: 0,
      guests_arrived: 0,
      bookings_arrived: 0,
      number_of_bookings: 0,
      tables_reserved: 0,
      tables_left: 0,
      bookings_unpaid: 0,
    };
    cateringData.forEach(item => {
      sum.number_of_guests += item.guestSummary.total.number_of_guests || 0;
      sum.number_of_adults += item.guestSummary.total.number_of_adults || 0;
      sum.number_of_kids += item.guestSummary.total.number_of_kids || 0;
      sum.number_of_babies += item.guestSummary.total.number_of_babies || 0;
      sum.guests_arrived += item.guestSummary.total.guests_arrived || 0;
      sum.bookings_arrived += item.guestSummary.total.bookings_arrived || 0;
      sum.number_of_bookings += item.guestSummary.total.number_of_bookings || 0;
      sum.tables_reserved += item.guestSummary.total.tables_reserved || 0;
      sum.tables_left += item.guestSummary.total.tables_left || 0;
      sum.bookings_unpaid += item.guestSummary.total.bookings_unpaid || 0;
    });
    return sum;
  }, [cateringData]);
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {cateringData.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 1,
            flexWrap: "wrap",
            p: 1,
            borderRadius: "6px",
            border: theme => `solid 1px ${alpha(theme.palette.info.main, 0.4)}`,
            backgroundColor: theme => alpha(theme.palette.info.main, 0.1),
          }}
        >
          <Typography variant="body1" align="center">
            Összesítés
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            <OrderItem
              name="Vendégek"
              qty={goupedGuestSummary.number_of_guests}
              type="option"
            />
            <OrderItem
              name="Foglalások"
              qty={goupedGuestSummary.number_of_bookings}
              type="option"
            />
          </Box>
        </Box>
      )}
      <List disablePadding dense>
        {cateringData.map(item => (
          <CateringListItem
            key={`${item.boat_id}-${item.start_time}`}
            item={item}
          />
        ))}
      </List>
    </Box>
  );
}
