import { useAppDispatch, useAppSelector } from "app/hooks";
import { LayoutRouter } from "common";
import {
  loadDictionaryAsync,
  selectDictionary,
} from "features/dictionaries/dictionarySlice";
import { selectAccessToken } from "features/identity/identitySlice";
import { selectProfile } from "features/profile/profileSlice";
import { useEffect } from "react";
import AdminRouter from "routers/AdminRouter";
import PublicRouter from "routers/PublicRouter";

export default function App() {
  const access_token = useAppSelector(selectAccessToken);
  const profile = useAppSelector(selectProfile);
  const dispatch = useAppDispatch();
  //useDocTitle(getMenu(true));
  const dictionaries = useAppSelector(selectDictionary);
  function getLayoutIndex() {
    if (access_token && profile.roles) {
      return 0;
    }
    return 1;
  }
  useEffect(() => {
    //if (Object.keys(dictionaries).length === 0) {
    if (access_token) {
      dispatch(loadDictionaryAsync());
    }
    //}
  }, [access_token]);
  //TODO: Load profile on every page load for testing
  /*useEffect(() => {
    console.log("TWICE");
    dispatch(loadProfileAsync());
  }, []);*/
  return (
    <LayoutRouter getLayoutIndex={getLayoutIndex}>
      <AdminRouter />
      <PublicRouter />
    </LayoutRouter>
  );
}
