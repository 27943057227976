import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import useSettings from "features/settings/useSettings";
import { formatPrice } from "helpers/priceHelper";
import {
  barTotalPlugin,
  totalPiePlugin,
  totalPlugin,
} from "helpers/totalPiePlugin";
import { Bar, Line, Pie } from "react-chartjs-2";
import { useAdvancedReportContext } from "../useAdvancedReport";
export interface AdvancedReportChartProps {}
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
const AdvancedReportChart: React.FC<AdvancedReportChartProps> = ({}) => {
  const { params, data } = useAdvancedReportContext();
  const [settings, setSettings] = useSettings({ key: "advancedReport" });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md")) && !isMobile;

  return (
    <Paper sx={{ p: 2, position: "relative", width: "100%" }}>
      {/* Aspect Ratio Container */}
      {params.chartType === "pie" && (
        <Box
          sx={{
            position: "relative",
            //paddingBottom: "50.25%",
            paddingBottom: isMobile ? "200%" : isTablet ? "150%" : "70%",
            width: "100%",
            maxWidth: "600px",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <Pie
              data={data as any}
              options={
                {
                  maintainAspectRatio: true, // Ensure the aspect ratio is maintained
                  aspectRatio: isMobile ? 0.5 : isTablet ? 0.7 : 1, // Custom aspect ratio (width/height = 1.5)
                  layout: {
                    padding: {
                      top: isMobile ? 250 : isTablet ? 180 : 130,
                    },
                  },
                  customConfig: {
                    value: params.value,
                    currency: settings.currency,
                    short_prices: settings.short_prices,
                  },
                  plugins: {
                    legend: {
                      display: false,
                      position: "top",
                    },
                  },
                  //radius: "50%", // This reduces the size of the pie within the chart area
                  //cutout: "50%",
                } as any
              }
              plugins={[totalPiePlugin]}
            />
          </Box>
        </Box>
      )}
      {params.chartType !== "pie" && (
        <Box
          sx={{
            position: "relative",
            //paddingBottom: "50.25%",
            paddingBottom: isMobile ? "200%" : isTablet ? "125%" : "50.25%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            {params.chartType === "bar" && (
              <Bar
                data={data as any}
                options={
                  {
                    aspectRatio: isMobile ? 0.5 : isTablet ? 0.7 : 100 / 50.25,
                    //indexAxis: "y", // This makes the bars horizontal
                    responsive: true,
                    //maintainAspectRatio: false, // This allows the chart to resize freely without maintaining its original aspect ratio
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,

                        grid: {
                          // Set lineWidth to control the thickness of grid lines
                          lineWidth: (context: any) =>
                            context.tick.value === 0 ? 2 : 1, // Thicker for zero line
                          color: (context: any) =>
                            context.tick.value === 0
                              ? "rgba(255, 0, 0, 0.8)"
                              : "rgba(0, 0, 0, 0.1)", // Red for zero line
                        },
                        ticks11: {
                          callback: function (
                            value: any,
                            index: any,
                            ticks: any
                          ) {
                            // Add additional tick at top and bottom of the scale
                            const maxValue = ticks[ticks.length - 1].value;
                            const minValue = ticks[0].value;

                            if (index === 0 || index === ticks.length - 1) {
                              return value;
                            }
                            return value; // Regular tick behavior
                          },
                        },
                        ticks: {
                          callback: function (
                            value: any,
                            index: any,
                            values: any
                          ) {
                            // Custom format for y-axis labels
                            return formatPrice(
                              value,
                              settings.currency,
                              settings.short_prices
                            ); // Example: append ' units' to each label
                          },
                        },
                        afterBuildTicks: settings.show_canceled
                          ? function (axis: any) {
                              // Get current ticks
                              const ticks = axis.ticks;

                              // Calculate the increment (difference between two ticks)
                              const tickIncrement =
                                ticks[1].value - ticks[0].value;

                              // Add extra tick at top
                              const maxTick = ticks[ticks.length - 1].value;
                              ticks.push({
                                value: maxTick + tickIncrement,
                              });

                              // Add extra tick at bottom
                              const minTick = ticks[0].value;
                              ticks.unshift({
                                value: minTick - tickIncrement,
                              });
                              // Update the min and max for the axis to match the new ticks
                              axis.min = ticks[0].value;
                              axis.max = ticks[ticks.length - 1].value;
                            }
                          : undefined,
                        afterBuildTicks11: function (axis: any) {
                          // Ensure ticks array exists and has enough ticks to calculate a step
                          let ticks = axis.ticks;

                          if (ticks.length < 2) {
                            return; // Exit if there aren't enough ticks to calculate a step
                          }

                          // Add one tick at the top (just above the current max tick)
                          const maxTick = ticks[ticks.length - 1].value;
                          const minTick = ticks[0].value;

                          // Calculate the step size between ticks and ensure it's a valid number
                          const step = ticks[1].value - ticks[0].value;

                          if (!isNaN(step)) {
                            ticks.push({ value: maxTick + step }); // Add the new tick at the top
                            ticks.unshift({ value: minTick - step }); // Add the new tick at the bottom
                          }

                          // Set the updated ticks array back into the axis
                          axis.ticks = ticks;
                        },
                        min22: function ({ chart }: any) {
                          return chart.scales.y.ticks[
                            chart.scales.y.ticks.length - 1
                          ].value;
                        },
                        minasd: function ({ chart }: any) {
                          // Define the desired padding in pixels
                          const paddingPixels = 50; // You can adjust this value as needed

                          // Calculate the data range (difference between max and min data points)
                          const allData = data.datasets.map(
                            dataset => dataset.data
                          );
                          const dataLength = allData[0]?.length;

                          // For each index (each bar), sum the negative values from all datasets
                          const minValues = [];
                          for (let i = 0; i < dataLength; i++) {
                            let sumNegative = 0;
                            allData.forEach(dataSet => {
                              const value = dataSet[i];
                              if (value < 0) {
                                sumNegative += value;
                              }
                            });
                            minValues.push(sumNegative);
                          }
                          const minStackedValue = Math.min(...minValues);

                          // Get the chart's height in pixels
                          const chartHeight = chart.height;

                          // Get the difference between the Y-axis max and min (in data units)
                          const yAxisRange =
                            chart?.scales?.y?.max - chart?.scales?.y?.min;

                          // Calculate how many data units correspond to the desired padding in pixels
                          const pixelToDataUnitRatio = yAxisRange / chartHeight;
                          const paddingInDataUnits =
                            pixelToDataUnitRatio * paddingPixels;

                          // Set the minimum value for the Y-axis, adding the extra padding in data units
                          /*console.log({
                          max: chart?.scales?.y?.max,
                          yAxisRange,
                          minStackedValue,
                          paddingInDataUnits,
                          pixelToDataUnitRatio,
                        });*/
                          return minStackedValue < 0
                            ? minStackedValue - paddingInDataUnits
                            : 0;
                        },
                      },
                    },
                    layout: {
                      padding: {
                        top: isMobile ? 250 : isTablet ? 180 : 120,
                      },
                    },
                    customConfig: {
                      value: params.value,
                      show_canceled: settings.show_canceled,
                      currency: settings.currency,
                      short_prices: settings.short_prices,
                    },
                    plugins: {
                      legend: {
                        display: false,
                        position: "top",
                      },
                    },
                  } as any
                }
                plugins={[totalPlugin, barTotalPlugin]}
              />
            )}
            {params.chartType === "line" && (
              <Line
                data={data as any}
                options={
                  {
                    aspectRatio: isMobile ? 0.5 : isTablet ? 0.7 : 100 / 50.25,
                    layout: {
                      padding: {
                        top: isMobile ? 250 : isTablet ? 180 : 120,
                      },
                    },
                    customConfig: {
                      value: params.value,
                      currency: settings.currency,
                      short_prices: settings.short_prices,
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: function (
                            value: any,
                            index: any,
                            values: any
                          ) {
                            // Custom format for y-axis labels
                            return formatPrice(
                              value,
                              settings.currency,
                              settings.short_prices
                            ); // Example: append ' units' to each label
                          },
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                        position: "top",
                      },
                    },
                  } as any
                }
                plugins={[totalPlugin]}
              />
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};
export default AdvancedReportChart;
