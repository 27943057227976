import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InterestsIcon from "@mui/icons-material/Interests";
import KeyIcon from "@mui/icons-material/Key";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { DialogActions, DialogContent, InputBase } from "@mui/material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CreatedUpdatedBy,
  DateTimePicker,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  Spacer,
  TextField,
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import List from "common/components/Inputs/List/List";
import FilterProvider from "components/FilterProvider";
import PriceInputBase from "components/PriceInputBase";
import BookingList from "features/bookings/BookingList";
import HistoryListDialog from "features/histories/HistoryListDialog";
import useAccess from "features/profile/useAccess";
import {
  ScheduleLimit,
  ScheduleOption,
  ScheduleSeatingLayout,
  ScheduleVariant,
} from "features/schedules/scheduleApi";
import { LIMIT_TYPES } from "features/schedules/scheduleConstants";
import I18n from "i18n";
import { createContext } from "react";
import { EventDefaultsButton } from "./EventDefaultsButton";
import useEventForm, {
  UseEventFormProps,
  UseEventFormReturnType,
} from "./useEventForm";
export const EventContext = createContext<UseEventFormReturnType | undefined>(
  undefined
);
export interface EventFormProps extends UseEventFormProps {}

export default function EventForm({
  id,
  afterSave,
  onClose,
  defaultValues,
}: EventFormProps) {
  const { hasPermissions, canEditItem } = useAccess();
  const form = useEventForm({
    id,
    afterSave,
    defaultValues,
  });
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
    program,
    programs,
    type,
  } = form;
  const title = isCreate
    ? I18n.t("Event.addTitle")
    : `${item?.start_time} ${item?.program?.name}`;
  const subtitle = isCreate ? undefined : `${item?.boat?.name}`;
  return (
    <ParamsContext.Provider
      value={{
        event_id: id,
        boat_id: item.boat_id,
        program_id: item.program_id,
        schedule_id: item.schedule_id,
      }}
    >
      <EventContext.Provider value={form}>
        <PageHeader
          avatar={<CalendarMonthIcon />}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          loading={loading}
        >
          <EventDefaultsButton
            disabled={!item.boat_id || !item.program_id}
            variant="outlined"
            onUpdate={values => {
              form.setAttributes(values);
            }}
            program_ids={item.schedule?.program_ids || [item.program_id]}
            boat_id={item.boat_id}
          />
        </PageHeader>
        <DialogContent>
          <Form
            loading={loading}
            item={item}
            options={options}
            errors={errors}
            onChange={onChange}
            translationCategory="Event.attributes"
            translationPlaceholdersCategory="Event.placeholders"
            translationHelpersCategory="Event.helpers"
            isDirty={getIsDirty}
            requiredFields={getRequiredFields()}
            disabled={!canEditItem("event", item)}
          >
            <FieldsContainer>
              <Field>
                <DateTimePicker id="start_time" is24Hours />
              </Field>
              <Field>
                <Autocomplete id="boat_id" />
              </Field>
              <Field>
                <Autocomplete id="program_id" />
              </Field>
              <Field>
                <TextField id="duration" />
              </Field>
              <Field>
                <Autocomplete id="status" />
              </Field>
              <Field fullWidth>
                <Checkbox id="private" />
                {!!item.schedule_id && <Checkbox id="custom" />}
              </Field>
              <Field fullWidth>
                <List<ScheduleVariant>
                  id="variants"
                  icon={<InterestsIcon />}
                  columns={[
                    {
                      id: "variant_id",
                      title: I18n.t("Price.attributes.variant_id"),
                      inputComponent: Autocomplete,
                      inputProps: {
                        options: options.variants,
                        colorAttribute: "program.color",
                      },
                      headerProps: { width: "50%" },
                    },
                    {
                      id: "adult_price",
                      title: I18n.t("Price.attributes.adult_price"),
                      inputComponent: PriceInputBase,
                      inputProps: { type: "number", switchDisabled: true },
                    },
                    {
                      id: "kid_price",
                      title: I18n.t("Price.attributes.kid_price"),
                      inputComponent: PriceInputBase,
                      inputProps: { type: "number" },
                    },
                  ]}
                />
              </Field>
              <Field fullWidth>
                <List<ScheduleLimit>
                  id="limits"
                  icon={<ProductionQuantityLimitsIcon />}
                  onRowCahnge={(item, index, field, value) => {
                    if (field === "type") {
                      if (value === LIMIT_TYPES.PROGRAM) {
                        const variant_ids = options.variants
                          .filter(v => v.program_id === item.program_id)
                          .map(v => v.id);
                        return { ...item, variant_ids, name: undefined };
                      }
                      if (value === LIMIT_TYPES.BOAT) {
                        const variant_ids = options.variants.map(v => v.id);
                        return {
                          ...item,
                          variant_ids,
                          name: "Hajó",
                          program_id: undefined,
                        };
                      }
                      return { ...item, program_id: undefined };
                    }
                    if (field === "program_id") {
                      const variant_ids = options.variants
                        .filter(v => v.program_id === value)
                        .map(v => v.id);
                      return { ...item, variant_ids, name: undefined };
                    }
                    return item;
                  }}
                  columns={[
                    {
                      id: "type",
                      title: I18n.t("Limit.attributes.type"),
                      inputComponent: Autocomplete,
                      inputProps: {
                        options: type,
                      },
                      headerProps: { width: "140px" },
                    },
                    (item, index) => {
                      if (item?.type === LIMIT_TYPES.PROGRAM) {
                        return {
                          id: "program_id",
                          title: I18n.t("Limit.attributes.name"),
                          inputComponent: Autocomplete,
                          inputProps: {
                            options: programs,
                            colorAttribute: "color",
                          },
                          headerProps: { width: "50%" },
                        };
                      }
                      return {
                        id: "name",
                        title: I18n.t("Limit.attributes.name"),
                        inputComponent: InputBase,
                        inputProps: {
                          disabled: item?.type === LIMIT_TYPES.BOAT,
                        },
                        headerProps: { width: "140px" },
                      };
                    },
                    {
                      id: "variant_ids",
                      title: I18n.t("Limit.attributes.variant_ids"),
                      inputComponent: Autocomplete,
                      inputProps: (item?: ScheduleLimit) => ({
                        disabled:
                          item?.type === LIMIT_TYPES.BOAT ||
                          item?.type === LIMIT_TYPES.PROGRAM,
                        multiple: true,
                        options: options.variants,
                        colorAttribute: "program.color",
                      }),
                      headerProps: { width: "50%" },
                    },
                    {
                      id: "number_of_places",
                      title: I18n.t("Limit.attributes.number_of_places"),
                      inputComponent: InputBase,
                      inputProps: { type: "number" },
                    },
                    {
                      id: "number_of_online_places",
                      title: I18n.t("Limit.attributes.number_of_online_places"),
                      inputComponent: InputBase,
                      inputProps: { type: "number" },
                    },
                    {
                      id: "places_tolerance",
                      title: I18n.t("Limit.attributes.places_tolerance"),
                      inputComponent: InputBase,
                      inputProps: { type: "number" },
                    },
                  ]}
                />
              </Field>
              {program?.has_options && (
                <Field fullWidth>
                  <List<ScheduleOption>
                    id="options"
                    icon={<WorkspacePremiumIcon />}
                    columns={[
                      {
                        id: "option_id",
                        title: "Option ID",
                        inputComponent: Autocomplete,
                        inputProps: {
                          options: options.options,
                          colorAttribute: "program.color",
                        },
                        headerProps: { width: "50%" },
                      },
                      {
                        id: "price",
                        title: I18n.t("Price.attributes.price"),
                        inputComponent: PriceInputBase,
                        inputProps: { type: "number", switchDisabled: true },
                      },
                      {
                        id: "limit",
                        title: I18n.t("Price.attributes.limit"),
                        inputComponent: InputBase,
                        inputProps: { type: "number" },
                      },
                    ]}
                  />
                </Field>
              )}
              <Field fullWidth>
                <List<ScheduleSeatingLayout>
                  id="seating_layouts"
                  icon={<InterestsIcon />}
                  columns={[
                    {
                      id: "program_id",
                      title: I18n.t("Program.titleSingular"),
                      inputComponent: Autocomplete,
                      inputProps: {
                        options: programs,
                        colorAttribute: "color",
                      },
                      headerProps: { width: "50%" },
                    },
                    {
                      id: "seating_layout_ids",
                      inputComponent: Autocomplete,
                      // headerProps: { width: "50%" },
                      inputProps: {
                        multiple: true,
                        options: options.layouts,
                      },
                    },
                  ]}
                />
              </Field>
            </FieldsContainer>
          </Form>
          {isUpdate && (
            <>
              <Spacer h={3} />
              {hasPermissions(["booking.viewany", "booking.viewown"]) && (
                <FilterProvider id="event.bookings" storageType="session">
                  <BookingList
                    id="event"
                    icon={<KeyIcon />}
                    title={I18n.t("Booking.title")}
                    showHeader={false}
                    modalProps={{
                      maxWidth: "lg",
                    }} /*showHeader={false} toolbarItems={["title", "refresh", "create"]}  showFooter={false}*/
                  />
                </FilterProvider>
              )}
            </>
          )}{" "}
        </DialogContent>
        <DialogActions>
          {isUpdate && (
            <CreatedUpdatedBy
              item={item}
              sx={{
                p: 1,
                paddingTop: 0,
                paddingBottom: 0.5,
                marginRight: "auto",
              }}
              renderDialog={
                hasPermissions(["history.viewany", "history.viewown"])
                  ? props => <HistoryListDialog {...props} subtitle={title} />
                  : undefined
              }
            />
          )}
          <Button
            title={I18n.t("App.save")}
            disabled={!isDirty}
            onClick={() => save()}
            loading={saving}
          />
        </DialogActions>
      </EventContext.Provider>
    </ParamsContext.Provider>
  );
}
