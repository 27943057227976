import { createContext, ReactNode, useContext } from "react";
import useUpgrade, { UpgradeContextType, UseUpgradeProps } from "./useUpgrade";

export interface UpgradeProviderProps extends UseUpgradeProps {
  children?: ReactNode;
}
export const UpgradeContext = createContext<UpgradeContextType | null>(null);
export default function UpgradeProvider({
  children,
  ...props
}: UpgradeProviderProps) {
  const context = useUpgrade(props);

  return (
    <UpgradeContext.Provider value={context}>
      {children}
    </UpgradeContext.Provider>
  );
}

export function useUpgradeContext() {
  const context = useContext(UpgradeContext);
  if (!context) {
    throw new Error("Must be inside UpgradeProvider");
  }
  return context;
}
