import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  IconButtonProps,
  Tooltip,
} from "@mui/material";
import { useAppSelector } from "app/hooks";
import { Button, ButtonProps, DialogTitle, VIEWS } from "common";
import CalendarPro from "common/components/CalendarPro";
import { BookingItem } from "features/bookings/bookingApi";
import { selectDictionary } from "features/dictionaries/dictionarySlice";
import CalendarToolbar from "features/events/Calendar/CalendarToolbar";
import CalendarCellHeaderSmall from "features/events/Calendar/cells/CalendarCellHeaderSmall";
import StartBoatCell from "features/events/Calendar/cells/StartBoatCell";
import useEventCalendar from "features/events/Calendar/useEventCalendar";
import {
  EventItem,
  getClosestEvents,
  prepareCalendarItems,
} from "features/events/eventApi";
import I18n from "i18n";
import moment from "moment";
import { useEffect, useState } from "react";

type AttachBookingButtonBaseProps = {
  onSelect: (event: EventItem | null) => void;
  booking?: BookingItem;
};

type BookingIconButtonProps = {
  buttonType: "icon";
} & AttachBookingButtonBaseProps &
  Omit<IconButtonProps, "onSelect">;

type AttachBookingButtonButtonProps = {
  buttonType: "button";
} & AttachBookingButtonBaseProps &
  Omit<ButtonProps, "onSelect">;

type AttachBookingButtonProps =
  | BookingIconButtonProps
  | AttachBookingButtonButtonProps;

function parseItems(items: EventItem[]) {
  let _items: any[] = [];
  items.forEach(row => {
    _items.push({
      ...row,
      title: row?.program?.name,
      start: moment(row?.start_time),
      end: moment(row?.start_time).add(1, "hour"),
    });
  });

  return _items;
}

const AttachBookingButton: React.FC<AttachBookingButtonProps> = ({
  onSelect,
  buttonType,
  booking,
  ...iconButtonProps
}) => {
  const program_id = booking?.program_variant?.program_id;
  const [open, setOpen] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState<EventItem | null>(null);

  const { items, loadEvents, setItems, cal } = useEventCalendar({
    programIds: [program_id],
    filterKey: "attachbooking",
  });

  const dictionaries = useAppSelector(selectDictionary);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedEvent(null);
  };

  const handleAttach = () => {
    if (selectedEvent) {
      onSelect(selectedEvent);
      handleClose();
    }
  };
  const [loading, setLoading] = useState(false);
  const loadInitialData = async () => {
    if (!program_id) {
      return;
    }
    setLoading(true);
    try {
      const fetchedEvents = await getClosestEvents(
        program_id,
        booking?.planned_datetime || booking?.event?.start_time || ""
      );
      setItems(prepareCalendarItems(fetchedEvents));
      if (fetchedEvents.length > 0) {
        setSelectedEvent(fetchedEvents[0]);
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log({ booking });
    if (program_id && open) {
      loadInitialData();
    }
  }, [open]);

  return (
    <>
      {buttonType === "icon" && (
        <Tooltip title={I18n.t("Booking.addTitle")}>
          <IconButton onClick={handleOpen} {...iconButtonProps} />
        </Tooltip>
      )}
      {buttonType === "button" && (
        <Button onClick={handleOpen} {...(iconButtonProps as ButtonProps)} />
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle onClose={handleClose}>
          {I18n.t("Booking.addTitle")}
        </DialogTitle>
        <DialogContent>
          <CalendarPro
            //calRef={cal}
            //key={filter?.from}
            navigateDelay={300}
            loadEvents={loadEvents}
            ToolbarComponent={CalendarToolbar}
            /*toolbarStyle={{
          position: "sticky",
          zIndex: 1000,
          top: 16,
          marginTop: "-8px",
        }}*/

            view={VIEWS.WEEK}
            events={items}
            date={
              selectedEvent?.start_time
                ? moment(selectedEvent?.start_time)
                : moment()
            }
            containerStyle={{
              flexDirection: "column",
            }}
            CellComponent={StartBoatCell}
            CellHeaderComponent={CalendarCellHeaderSmall}
            isSelectedFunc={cell => cell.id === selectedEvent?.id}
            onEventPress={cell => {
              setSelectedEvent(cell);
            }}
          />

          <DialogActions sx={{}}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAttach}
              disabled={!selectedEvent}
              fullWidth
            >
              {I18n.t("Booking.eventAttachBookingButton", {
                datetime: moment(selectedEvent?.start_time).format("L LT"),
              })}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AttachBookingButton;
