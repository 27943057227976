import { darken, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Toolbar } from "common";
import { BoatItem } from "features/boats/boatApi";
import useDictionaries from "features/dictionaries/useDictionaries";
import { CalendarEventItem, EventVariant } from "features/events/eventApi";
import { useEventCalculator } from "features/events/useEventCalculator";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { ProgramItem } from "features/programs/programApi";
import { isVariantClosed } from "helpers/bookingHelper";
import { getPrice } from "helpers/priceHelper";
import I18n from "i18n";
import { useCallback } from "react";
import { useUpgradeContext } from "./UpgradeProvider";

function UpgradesListItem({ item }: { item: CalendarEventItem }) {
  const { resolveOption } = useDictionaries();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const program = resolveOption<ProgramItem>("programs", item.program_id);
  const boat = resolveOption<BoatItem>("boats", item.boat_id);
  const { getEventData, getBoatData } = useEventCalculator();
  const boatData = getBoatData(item);
  const data = getEventData(item, boatData.boatLimit);

  //NEW
  const { booking, handleProgramVariantClick } = useUpgradeContext();

  const renderVariant = useCallback(
    (eventVariant: EventVariant, index: number = -1) => {
      const variantIsClosed = isVariantClosed(eventVariant.variant_id, item);
      const numberOfAdults = booking?.number_of_adults || 0;
      const numberOfKids = booking?.number_of_kids || 0;
      const hasEnoughPlaces =
        eventVariant?.remaining_places > numberOfAdults + numberOfKids;
      const color = hasEnoughPlaces ? program?.color : theme.palette.grey[700];
      const variant = resolveOption("variants", eventVariant.variant_id);

      //Prices

      const bookingAmount = getPrice(booking).sum;
      const variantAmount =
        (eventVariant?.adult_price || 0) * numberOfAdults +
        (eventVariant?.kid_price || 0) * numberOfKids;
      const additionalAmount =
        Math.round((variantAmount - bookingAmount) * 100) / 100;
      const current =
        booking.event_id === item.id &&
        booking.program_variant_id === eventVariant.variant_id;

      const clickable = current || !hasEnoughPlaces ? false : true;

      return (
        <Toolbar
          key={`${index}${eventVariant.variant_id}`}
          sx={{
            minWidth: 20,
            height: 30,
            borderRadius: "5px",
            backgroundColor: color,
            color: theme.palette.getContrastText(color),
            overflow: "hidden",
            cursor: clickable ? "pointer" : undefined,
            border: current
              ? `dashed 2px ${theme.palette.info.main}`
              : undefined,
            transition: theme.transitions.create(
              ["background-color", "box-shadow"],
              {
                duration: theme.transitions.duration.shortest,
              }
            ),
            "&:hover": {
              backgroundColor: darken(color, 0.2),
              boxShadow: theme.shadows[2], // Subtle elevation change on hover
              /*"& .remaining_places": {
                backgroundColor: darken(darken(color, 0.2), 0.2),
              },*/
            },
          }}
          onClick={
            clickable
              ? () =>
                  handleProgramVariantClick(
                    eventVariant.variant_id,
                    additionalAmount,
                    item
                  )
              : undefined
          }
        >
          <Typography
            variant="body2"
            className="remaining_places"
            sx={{
              flex: 1,
              backgroundColor: darken(color, 0.2),
              color: theme.palette.getContrastText(color),
              pl: 0.5,
              pr: 0.5,
              alignSelf: "stretch",
              display: "flex",
              alignItems: "center",
              textDecoration: hasEnoughPlaces ? undefined : "line-through",
            }}
          >
            {variant.name}
          </Typography>
          <Typography
            sx={{
              pl: 0.5,
              pr: 0.5,
              fontWeight: 700,
            }}
          >
            {eventVariant?.remaining_places}
          </Typography>
          <Typography
            sx={{
              pl: 0.5,
              pr: 0.5,
              fontWeight: !current ? 700 : undefined,
              fontSize: current ? "0.75rem" : undefined,
            }}
          >
            {current
              ? "jelenlegi"
              : `${I18n.numberToCurrency(additionalAmount, {
                  precision: 2,
                  unit: "€",
                  format: "%u %n",
                  separator: ",",
                  delimiter: " ",
                  stripInsignificantZeros: true,
                })}`}
          </Typography>
        </Toolbar>
      );
    },
    [program, theme, booking]
  );

  return (
    <Toolbar sx={{ gap: 1, flexWrap: "wrap" }}>
      {item.variants
        ?.filter(v => {
          const vv = resolveOption<ProgramVariantItem>(
            "variants",
            v.variant_id
          );
          return vv?.program_id === program?.id;
        })
        ?.map(renderVariant)}
    </Toolbar>
  );
}

export default UpgradesListItem;
