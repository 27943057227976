import InterestsIcon from "@mui/icons-material/Interests";
import { DialogActions, DialogContent } from "@mui/material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CreatedUpdatedBy,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  TextField,
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import HistoryListDialog from "features/histories/HistoryListDialog";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { createContext } from "react";
import useProgramVariantForm, {
  UseProgramVariantFormProps,
  UseProgramVariantFormReturnType,
} from "./useProgramVariantForm";
export const ProgramVariantContext = createContext<
  UseProgramVariantFormReturnType | undefined
>(undefined);
export interface ProgramVariantFormProps extends UseProgramVariantFormProps {}

export default function ProgramVariantForm({
  id,
  afterSave,
  onClose,
  defaultValues,
}: ProgramVariantFormProps) {
  const { hasPermissions, canEditItem } = useAccess();
  const form = useProgramVariantForm({
    id,
    afterSave,
    defaultValues,
  });
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
  } = form;
  const title = isCreate ? I18n.t("ProgramVariant.addTitle") : item.name;
  const subtitle = isCreate ? undefined : undefined;
  return (
    <ParamsContext.Provider
      value={{ program_variant_id: id, program_id: item.program_id }}
    >
      <ProgramVariantContext.Provider value={form}>
        <PageHeader
          avatar={<InterestsIcon />}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          loading={loading}
        />
        <DialogContent>
          <Form
            loading={loading}
            item={item}
            options={options}
            errors={errors}
            onChange={onChange}
            translationCategory="ProgramVariant.attributes"
            translationPlaceholdersCategory="ProgramVariant.placeholders"
            translationHelpersCategory="ProgramVariant.helpers"
            isDirty={getIsDirty}
            requiredFields={getRequiredFields()}
            disabled={!canEditItem("program_variant", item)}
          >
            <FieldsContainer>
              <Field fullWidth>
                <TextField id="name" />
              </Field>
              {!params?.program_id && (
                <Field fullWidth>
                  <Autocomplete id="program_id" />
                </Field>
              )}
              <Field fullWidth>
                <TextField id="code" />
              </Field>
              <Field fullWidth>
                <TextField id="sku" />
              </Field>
              <Field fullWidth>
                <TextField id="kitchen_group" />
              </Field>
              <Field fullWidth>
                <TextField id="min_unit" mask={Number} />
              </Field>
              <Field fullWidth>
                <Checkbox id="upgradable" />
                <Checkbox id="active" />
              </Field>
            </FieldsContainer>
          </Form>
        </DialogContent>
        <DialogActions>
          {isUpdate && (
            <CreatedUpdatedBy
              item={item}
              sx={{
                p: 1,
                paddingTop: 0,
                paddingBottom: 0.5,
                marginRight: "auto",
              }}
              renderDialog={
                hasPermissions(["history.viewany", "history.viewown"])
                  ? props => <HistoryListDialog {...props} subtitle={title} />
                  : undefined
              }
            />
          )}
          <Button
            title={I18n.t("App.save")}
            disabled={!isDirty}
            onClick={() => save()}
            loading={saving}
          />
        </DialogActions>
      </ProgramVariantContext.Provider>
    </ParamsContext.Provider>
  );
}
