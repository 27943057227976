import { alpha, Box, BoxProps, useTheme } from "@mui/material";

export type SkeletonAnimationTypes =
  | "shimmer"
  | "pulse"
  | "wave"
  | "glow"
  | "flash"
  | "bounce"
  | "weave"
  | "ripple"
  | "twistFade"
  | "glitchPulse";

/**
 * Props for the SkeletonAnimation component.
 *
 * @typedef {Object} SkeletonAnimationProps
 * @property {ReactNode} children - The content to be displayed within the skeleton animation.
 * @property {string} [animationType='shimmer'] - The type of skeleton animation. "shimmer" | "pulse" | "wave" | "glow" | "flash" | "bounce" | "ripple" | "twistFade" | "glitchPulse"
 * @property {string} [animationDuration='1.5s'] - Duration of the skeleton animation.
 * @property {string} [skeletonClass='field'] - The class name to apply the skeleton animation to.
 * @property {string} [backgroundColor='#f0f0f0'] - Background color of the skeleton element.
 * @property {boolean} [loading=true] - Flag to turn on/off the skeleton animation.
 */

/**
 * SkeletonAnimation component applies a trendy skeleton animation to its children.
 *
 * @param {SkeletonAnimationProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const SkeletonAnimation = ({
  children,
  animationType = "shimmer",
  animationDuration = "1.5s",
  skeletonClass = "field",
  backgroundColor: _backgroundColor,
  loading,
  ...rest
}: BoxProps & {
  animationType?: SkeletonAnimationTypes;
  animationDuration?: string;
  skeletonClass?: string;
  backgroundColor?: string;
  loading?: boolean;
}): JSX.Element => {
  const theme = useTheme();
  let backgroundColor = _backgroundColor || theme.palette.background.default;

  const animationStyles: any = {
    shimmer: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: `linear-gradient(
          90deg,
          ${alpha(backgroundColor, 0)} 0%,
          ${alpha(backgroundColor, 0.5)} 50%,
          ${alpha(backgroundColor, 0)} 100%
        )`,
        animation: `skeleton-shimmer ${animationDuration} infinite`,
      },
    },
    pulse: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: alpha(backgroundColor, 0.6),
        animation: `skeleton-pulse ${animationDuration} infinite`,
      },
    },
    wave: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: `linear-gradient(
          270deg,
          ${alpha(backgroundColor, 0)} 0%,
          ${alpha(backgroundColor, 0.5)} 50%,
          ${alpha(backgroundColor, 0)} 100%
        )`,
        animation: `skeleton-wave ${animationDuration} infinite`,
      },
    },
    glow: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: alpha(backgroundColor, 0.8),
        animation: `skeleton-glow ${animationDuration} infinite`,
      },
    },
    flash: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: alpha(backgroundColor, 0.4),
        animation: `skeleton-flash ${animationDuration} infinite`,
      },
    },
    bounce: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: alpha(backgroundColor, 0.5),
        animation: `skeleton-bounce ${animationDuration} infinite`,
      },
    },
    weave: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "200%",
        height: "100%",
        background: `linear-gradient(
          45deg,
          ${alpha(backgroundColor, 0.25)} 25%,
          ${alpha(backgroundColor, 0.75)} 50%,
          ${alpha(backgroundColor, 0.25)} 75%
        )`,
        animation: `skeleton-weave ${animationDuration} infinite`,
      },
    },
    ripple: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "200%",
        height: "200%",
        background: `radial-gradient(circle, ${alpha(
          backgroundColor,
          0.1
        )} 20%, ${alpha(backgroundColor, 0.5)} 50%, ${alpha(
          backgroundColor,
          0.1
        )} 80%)`,
        borderRadius: "50%",
        transform: "translate(-50%, -50%) scale(0)",
        animation: `skeleton-ripple ${animationDuration} infinite`,
      },
    },
    twistFade: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: backgroundColor,
        animation: `skeleton-twist-fade ${animationDuration} infinite`,
      },
    },
    glitchPulse: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: backgroundColor,
        animation: `skeleton-glitch-pulse ${animationDuration} infinite`,
      },
    },
  };

  return (
    <Box
      {...rest}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        [`& .${skeletonClass} div:has(> input)`]: {
          position: "relative",
          overflow: "hidden",
          backgroundColor: loading ? backgroundColor : undefined,
          borderRadius: "4px",
          ...(loading ? animationStyles[animationType] : {}),
        },
        ...rest.sx,
      }}
    >
      {children}
    </Box>
  );
};

export default SkeletonAnimation;
