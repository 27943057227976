import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { alpha } from "@mui/material";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import EventCell from "features/schedules/columns/EventCell";
import LimitsCell from "features/schedules/columns/LimitsCell";
import OptionPricesCell from "features/schedules/columns/OptionPricesCell";
import PricesCell from "features/schedules/columns/PricesCell";
import ProgramCell from "features/schedules/columns/ProgramCell";
import I18n from "i18n";
import { ScheduleItem } from "./scheduleApi";
import useScheduleList from "./useScheduleList";
export interface ScheduleListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function ScheduleList({
  showHeader = true,
  ...props
}: ScheduleListProps) {
  const { params, scope, editUrl, ...service } = useScheduleList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<ScheduleItem> = [
    {
      field: "name",
      headerName: I18n.t("Schedule.attributes.name"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
      flex: 1,
    },
    {
      hide: !!params.boat_id,
      hideable: !params.boat_id,
      field: "boat.name",
      headerName: I18n.t("Schedule.attributes.boat_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/boats/${params.row?.boat_id}`}
          params={params}
        />
      ),
    },
    {
      field: "duration",
      headerName: I18n.t("Schedule.attributes.duration"),
      type: "number",
    },
    {
      field: "date_from",
      headerName: I18n.t("Schedule.attributes.date_from"),
      type: "date",
    },
    {
      field: "date_to",
      headerName: I18n.t("Schedule.attributes.date_to"),
      type: "date",
    },
    {
      field: "program_ids",
      headerName: I18n.t("Schedule.attributes.program_ids"),
      type: "json",
      renderCell: params => <ProgramCell params={params} />,
    },
    {
      field: "events",
      headerName: I18n.t("Schedule.attributes.events"),
      type: "json",
      renderCell: params => <EventCell params={params} />,
    },
    {
      field: "limits",
      headerName: I18n.t("Schedule.attributes.limits"),
      type: "json",
      renderCell: params => <LimitsCell params={params} />,
    },
    {
      field: "variants",
      headerName: I18n.t("Schedule.attributes.variants"),
      type: "json",
      renderCell: params => <PricesCell params={params} />,
    },
    {
      field: "options",
      headerName: I18n.t("Schedule.attributes.options"),
      type: "json",
      renderCell: params => <OptionPricesCell params={params} />,
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<EditCalendarIcon />}
          title={I18n.t("Schedule.title")}
        />
      )}
      <DataGrid
        defaultState={{
          hidden_columns: [
            "variants",
            "options",
            "limits",
            "duration",
            "events",
          ],
        }}
        {...service}
        columns={columns}
        id="schedules"
        {...props}
        //getHeaderName={field => I18n.t(`Schedule.attributes.${field.replaceAll(".", "_")}`)}
        editUrl={`${editUrl}:id`}
        canCreate={hasPermissions("schedule.create")}
        canDelete={hasPermissions(["schedule.editany", "schedule.editown"])}
        canEdit={hasPermissions(["schedule.editany", "schedule.editown"])}
        canDeleteRow={params => canEditItem("schedule", params.row)}
        canEditRow={params => canEditItem("schedule", params.row)}
        canBatchActions={hasPermissions([
          "schedule.editany",
          "schedule.editown",
        ])}
        canHandleTrash={hasPermissions([
          "schedule.editany",
          "schedule.editown",
        ])}
        rowSx={(item: ScheduleItem) => {
          if (item.is_modified) {
            return {
              backgroundColor: theme => alpha(theme.palette.warning.main, 0.15),
            };
          }
          return {};
        }}
      />
    </>
  );
}
