import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Toolbar, ToolbarProps } from "common";
import CheckboxGroup, {
  CheckboxGroupProps,
} from "common/components/CheckboxGroup/CheckboxGroup";
import useDictionaries from "features/dictionaries/useDictionaries";
import I18n from "i18n";
import { useMemo } from "react";

export interface ProgramCheckboxSelectorProps
  extends Omit<CheckboxGroupProps, "options"> {
  showMore?: boolean;
  setShowMore?: (showMore?: boolean) => void;
  wrapperProps?: ToolbarProps;
}

export default function ProgramCheckboxSelector({
  showMore,
  setShowMore,
  sx,
  wrapperProps,
  ...props
}: ProgramCheckboxSelectorProps) {
  const { dictionaries } = useDictionaries();
  const options = useMemo(() => {
    if (showMore) {
      return dictionaries.programs;
    }
    return dictionaries.programs?.filter(p => p.always_visible || showMore);
  }, [showMore, dictionaries]);
  return (
    <Toolbar
      {...wrapperProps}
      sx={{ justifyContent: "center", ...wrapperProps?.sx }}
    >
      <CheckboxGroup
        options={options}
        {...props}
        sx={{
          //minWidth: 0,
          flex: 0,
          ...sx,
        }}
      />
      <Tooltip
        title={showMore ? I18n.t("App.showLess") : I18n.t("App.showMore")}
      >
        <IconButton
          size="small"
          onClick={() => setShowMore && setShowMore(!showMore)}
        >
          {showMore ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}
