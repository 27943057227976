import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  SnackbarProps,
  Typography,
} from "@mui/material";
import I18n from "i18n";
import React, { Fragment } from "react";
import Button, { ButtonProps } from "../Button";
type GlobalMessageFunction = (
  params: GlobalMessagingParams,
  yesAction?: ActionParam,
  noAction?: ActionParam
) => void;

type GlobalMessageEventDetail = GlobalMessagingParams & {
  yesAction?: ActionParam;
  cancelAction?: ActionParam;
};

export const globalMessageHandler: {
  snack: GlobalMessageFunction;
  alert: GlobalMessageFunction;
  confirm: GlobalMessageFunction;
} = {
  snack: (
    params: GlobalMessagingParams,
    yesAction?: ActionParam,
    cancelAction?: ActionParam
  ) => {
    let event = new CustomEvent<GlobalMessageEventDetail>("onGobalMessage", {
      detail: {
        ...params,
        yesAction,
        cancelAction,
      },
    });
    document.dispatchEvent(event);
  },
  alert: (
    params: GlobalMessagingParams,
    yesAction?: ActionParam,
    cancelAction?: ActionParam
  ) => {
    let event = new CustomEvent<
      GlobalMessagingParams & {
        yesAction?: ActionParam;
        cancelAction?: ActionParam;
      }
    >("onGobalModalMessage", {
      detail: {
        ...params,
        yesAction,
        cancelAction,
      },
    });
    document.dispatchEvent(event);
  },
  confirm: (
    params: GlobalMessagingParams,
    yesAction?: ActionParam,
    cancelAction?: ActionParam
  ) => {
    let event = new CustomEvent<
      GlobalMessagingParams & {
        yesAction?: ActionParam;
        cancelAction?: ActionParam;
      }
    >("onGobalModalMessage", {
      detail: {
        ...params,
        yesAction,
        cancelAction,
        variant: "confirm",
      },
    });
    document.dispatchEvent(event);
  },
};

type GlobalMessagingParams = {
  snackBarProps?: SnackbarProps;
  title?: string;
  message: string;
  buttonsProps?: { yes?: ButtonProps; cancel?: ButtonProps };
  actionFirst?: boolean;
  severity?: "success" | "info" | "warning" | "error";
  variant?: "defualt" | "confirm";
  autoHideDuration?: number;
};

type ActionParam = () => any | Promise<any>;

type GlobalMessagingState = {
  yesAction?: ActionParam;
  cancelAction?: ActionParam;
  open: boolean;
  openModal: boolean;
  errors?: any | null;
  loading: boolean;
} & GlobalMessagingParams;

export default class GlobalMessaging extends React.Component {
  state: GlobalMessagingState = {
    open: false,
    openModal: false,
    message: "This is a Message!",
    errors: null,
    severity: "info",
    variant: "defualt",
    yesAction: undefined,
    cancelAction: undefined,
    autoHideDuration: 3000,
    loading: false,
    buttonsProps: {
      yes: { title: I18n.t("App.yes") },
      cancel: { title: I18n.t("App.cancel") },
    },
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleAccept = () => {
    this.state.yesAction && this.state.yesAction();
    this.setState({ openModal: false, yesAction: null });
  };

  componentDidMount() {
    document.addEventListener("onGobalMessage", this.onGobalMessage as any);
    document.addEventListener(
      "onGobalModalMessage",
      this.onGobalModalMessage as any
    );
  }

  componentWillUnmount() {
    document.removeEventListener("onGobalMessage", this.onGobalMessage as any);
    document.removeEventListener(
      "onGobalModalMessage",
      this.onGobalModalMessage as any
    );
  }

  onGobalMessage = (evt: CustomEvent<GlobalMessageEventDetail>) => {
    const { detail } = evt;
    this.setState({
      open: true,
      title: undefined,
      severity: undefined,
      ...detail,
      errors: null,
    });
  };

  onGobalModalMessage = (evt: CustomEvent<GlobalMessageEventDetail>) => {
    const { detail } = evt;

    this.setState({
      openModal: true,
      title: undefined,
      severity: undefined,
      //yesAction: undefined,
      //cancelAction: undefined,
      variant: "defualt",
      ...detail,
      //errors: detail.errors ? detail.errors : null,
      errors: null,
      buttonsProps: {
        yes: {
          title: detail.buttonsProps?.yes?.title || I18n.t("App.yes"),
          ...detail.buttonsProps?.yes,
        },
        cancel: {
          title:
            detail.buttonsProps?.cancel?.title ||
            I18n.t(
              detail.variant === "confirm" && detail.yesAction
                ? "App.cancel"
                : "App.close"
            ),
          ...detail.buttonsProps?.cancel,
        },
      },
    } as GlobalMessagingState);
  };

  getValidationErrors = () => {
    const { errors, message } = this.state;
    let errorList: Array<any> = [];
    Object.keys(errors).forEach(key => {
      const itemErrors = errors[key];
      if (Array.isArray(itemErrors)) {
        itemErrors.forEach(err => {
          if (typeof err === "object") {
            Object.keys(err).forEach(field => {
              let element = err[field];
              if (Array.isArray(element)) {
                element = element.join(", ");
              }
              errorList.push(`${key}. sor ${element}`);
            });
          } else {
            errorList.push(err);
          }
        });
      } else if (typeof itemErrors === "string") {
        errorList.push(itemErrors);
      } else {
        errorList.push(JSON.stringify(itemErrors));
      }
    });

    return (
      <div>
        {message && message !== "" && (
          <Typography variant="body1" color="red">
            {message}
          </Typography>
        )}
        {errorList.length > 0 && (
          <ul>
            {errorList.map((error, index) => {
              return (
                <li key={index}>
                  <Typography variant="body2" color="red">
                    {error}
                  </Typography>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  };

  render() {
    const {
      open,
      openModal,
      message,
      variant,
      autoHideDuration,
      errors,
      yesAction,
      loading,
      cancelAction,
      snackBarProps,
      title,
      buttonsProps,
      severity,
      actionFirst,
    } = this.state;

    let msg = null;
    if (typeof message === "object") {
      if ((message as any).props) {
        msg = message;
      } else {
        msg = JSON.stringify(message);
      }
    } else if (errors) {
      msg = this.getValidationErrors();
    } else {
      msg = message;
    }

    let yesTextColor: string | undefined = undefined;
    let yesButtonColor: string = "primary";
    let cancelTextColor: string | undefined = "text.primary";
    let cancelButtonColor: string = "inherit";

    if (severity === "error") {
      yesTextColor = "error.main";
      yesButtonColor = "error.light";
    }
    if (severity === "warning") {
      //yesTextColor = "warning.main";
      yesButtonColor = "warning";
    }

    return (
      <Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={this.handleClose}
          onClick={yesAction ? () => yesAction() : undefined}
          {...snackBarProps}
        >
          <Alert onClose={this.handleClose} severity={severity}>
            {!!title && <AlertTitle>{title}</AlertTitle>}
            {typeof message === "string" ? message : ""}
          </Alert>
        </Snackbar>
        <Dialog
          open={openModal}
          onClose={this.handleCloseModal}
          /*sx={{
            "& .MuiDialogContent-root, .MuiDialogActions-root": {
              backgroundColor: theme =>
                severity && ["error", "warning"].includes(severity)
                  ? alpha(theme.palette[severity].main, 0.1)
                  : undefined,
            },
          }}*/
        >
          {!!title && (
            <DialogTitle
              align="center"
              sx={{
                color: theme =>
                  severity && ["error", "warning"].includes(severity)
                    ? theme.palette[severity].main
                    : undefined,
              }}
            >
              {title}
            </DialogTitle>
          )}
          <DialogContent
            sx={{
              minWidth: 400,
              textAlign: "center",
              overflowY: "auto",
              color: theme =>
                !title && severity && ["error", "warning"].includes(severity)
                  ? theme.palette[severity].main
                  : undefined,
            }}
          >
            {msg}
          </DialogContent>
          <DialogActions
            sx={{
              flexDirection: actionFirst ? "row-reverse" : "row",
              gap: 1,
              justifyContent: "center",
              "& .MuiButton-root": {
                minWidth: 180,
              },
            }}
          >
            {variant !== "confirm" && (
              <Button
                onClick={this.handleCloseModal}
                {...buttonsProps?.cancel}
                color={cancelButtonColor as any}
                sx={{
                  color: cancelTextColor,
                  borderRadius: 1,
                  ...buttonsProps?.cancel?.sx,
                }}
              />
            )}
            {variant === "confirm" && yesAction && (
              <>
                <Button
                  onClick={this.handleCloseModal}
                  {...buttonsProps?.cancel}
                  color={cancelButtonColor as any}
                  sx={{
                    color: cancelTextColor,
                    borderRadius: 1,
                    ...buttonsProps?.cancel?.sx,
                  }}
                />
                <Button
                  loading={loading}
                  onClick={() => {
                    if (yesAction) {
                      let promise = yesAction();
                      if (!!promise && typeof promise.then === "function") {
                        this.setState({ loading: true });
                        promise
                          .then(() => {
                            this.setState({ loading: false });
                            this.handleCloseModal();
                          })
                          .catch((errors: any) => {
                            this.setState({ loading: false, errors: errors });
                          });
                      } else {
                        this.handleCloseModal();
                      }
                    } else {
                      this.handleCloseModal();
                    }
                  }}
                  {...buttonsProps?.yes}
                  color={yesButtonColor as any}
                  sx={{
                    color: yesTextColor,
                    borderRadius: 1,
                    ...buttonsProps?.yes?.sx,
                  }}
                />
              </>
            )}
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}
